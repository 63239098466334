import PropTypes from 'prop-types';

import { MotionType } from 'consts/motionType';
import { CreativeType, CreativeStatus } from 'components/common/types/Creative.types';

export const CodeNameModel = PropTypes.shape({ code: PropTypes.string, name: PropTypes.string });
const AdvertiserModel = PropTypes.shape({
  code: PropTypes.string,
  name: PropTypes.string,
  brands: PropTypes.arrayOf(CodeNameModel),
});

export const MotionTypeModel = PropTypes.oneOf(Object.values(MotionType));

export const PanelCreativeModel = PropTypes.shape({
  id: PropTypes.string,
  thumbnailUrl: PropTypes.string,
  previewUrl: PropTypes.string,
  status: PropTypes.oneOf(Object.values(CreativeStatus)),
  type: PropTypes.oneOf([...Object.values(CreativeType), '']),
});

export const PanelModel = PropTypes.shape({
  id: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  channels: PropTypes.arrayOf(PropTypes.string),
  cities: PropTypes.arrayOf(PropTypes.string),
  motionType: MotionTypeModel,
  count: PropTypes.number,
  creative: PanelCreativeModel,
});

export const PanelsModel = PropTypes.arrayOf(PanelModel);

export const AssignCreativeModalFilters = PropTypes.shape({
  advertiser: AdvertiserModel,
  brand: CodeNameModel,
  dsp: CodeNameModel,
  type: PropTypes.oneOf([...Object.values(CreativeType), '']),
});
