import { getCurrency } from 'modules/I18N';
import InputNumber, { InputNumberProps } from 'lib/InputNumber';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

const DealCpm: React.FC<InputNumberProps> = ({
  label,
  value,
  onChange,
  onBlur,
  isDisabled,
  size,
  theme,
  id,
  isRequired = true,
  isEmpty,
  errorMessage,
  name,
}) => {
  const { currencyCode, localeCode } = useSelector((state: RootState) => state.publisher.configuration);
  return (
    <InputNumber
      isEmpty={isEmpty}
      dataTestId={id}
      isDisabled={isDisabled}
      isRequired={isRequired}
      label={label}
      onBlur={onBlur}
      onChange={onChange}
      size={size}
      textSymbol={getCurrency(localeCode, currencyCode)}
      theme={theme}
      value={value}
      errorMessage={errorMessage}
      name={name}
    />
  );
};

export default DealCpm;
