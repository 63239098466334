export enum TargetObjectiveId {
  BUDGET = 1,
  FRAMES = 2,
  IMPRESSIONS = 3,
  SOT = 4,
  LOCATION = 5,
  FORMAT = 6,
  POI = 7,
  TAGS = 8,
  FRAME_LIST = 9,
  NETWORK = 10,
}

export const DEFAULT_CRITERIA = {
  greenTolerance: 5,
  orangeTolerance: 10,
  priority: { code: '1', name: '1' },
};

export const DEFAULT_REQUEST_FORMAT_CRITERIA = {
  greenTolerance: 0.05,
  orangeTolerance: 0.1,
  priority: 1,
};
