import { useDispatch, useSelector } from 'react-redux';
import Input from 'lib/Input';
import { Label } from 'lib/Label';
import { DealType } from 'components/common/types/Deal.types';
import { InputShape, InputSize, InputTheme } from 'components/common/types/Input.types';
import { Store } from 'components/common/types/Store.types';
import { changeDealInfo } from 'store/dealManagement/actions';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import { FeatureFlags } from 'components/common/types/Features.types';

const OverrideDealId: React.FC = () => {
  const dispatch = useDispatch();

  const isNewDeal = useSelector((state: Store) => state.dealManagement.isNewDeal);
  const dealType = useSelector((state: Store) => state.dealManagement.commonDeal.dealType);
  const overriddenCampaignId = useSelector((state: Store) => state.dealManagement.commonDeal.overriddenCampaignId);

  const isOverriddenCampaignIdVisible =
    useHasFeatureAccess(FeatureFlags.OVERRIDE_DEAL_ID) && isNewDeal && dealType === DealType.GUARANTEED;

  const handleChange = (value: string): Promise<void> => dispatch(changeDealInfo({ overriddenCampaignId: value }));

  if (!isOverriddenCampaignIdVisible) return null;

  return (
    <>
      <Label id="override-campaign-id" theme={InputTheme.FLAT_GRAY} label="Override deal ID" />
      <Input
        name="Override deal ID"
        theme={InputTheme.FLAT_GRAY}
        size={InputSize.EXTRA_SMALL}
        shape={InputShape.ROUNDED}
        dataTestId="override-deal-id-input"
        debounceTime={1000}
        onChange={handleChange}
        value={overriddenCampaignId}
      />
    </>
  );
};

export default OverrideDealId;
