import { IconSize, InputShape, InputSize, InputTheme } from 'components/common/types/Input.types';
import Input from 'lib/Input';
import { useDispatch } from 'react-redux';
import { changeDealData } from 'store/dealManagement/reducer';
import editSvg from 'assets/icons/edit.svg';

const PLACEHOLDER_CAMPAIGN_NAME = 'New Campaign';

type CampaignNameProps = {
  campaignName?: string;
};

const CampaignName: React.FC<CampaignNameProps> = ({ campaignName }) => {
  const dispatch = useDispatch();

  const handleCampaignNameChange = (name: string): void => {
    dispatch(changeDealData({ dealName: name }));
  };

  return (
    <Input
      theme={InputTheme.ITALIC_WHITE}
      placeholder={PLACEHOLDER_CAMPAIGN_NAME}
      value={campaignName}
      onChange={handleCampaignNameChange}
      endIconSvg={editSvg}
      iconSize={IconSize.EXTRA_SMALL}
      size={InputSize.EXTRA_SMALL}
      shape={InputShape.ROUNDED_MEDIUM}
      className="py-1"
      name="Campaign name"
    />
  );
};

export default CampaignName;
