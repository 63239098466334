import { eachDayOfInterval, eachWeekOfInterval, getDay, isAfter, isBefore, isSameDay, lastDayOfWeek } from 'date-fns';
import { formatDateCustom } from 'modules/I18N';
import { ColumnGroupOpenedEvent, ColumnPinnedType, SortChangedEvent } from 'ag-grid-community';
import { DAYS_OF_THE_WEEK } from '../consts';
import { ColumnHeaders } from './Table.types';

const defaultDayFormat = '2-digit';
const defaultMonthFormat = '2-digit';
const defaultYearFormat = 'numeric';

export const generateTimeBreakdownColumnHeaders = (
  startDate: Date,
  endDate: Date,
  localeCode: string,
): ColumnHeaders => {
  const weeks = eachWeekOfInterval(
    {
      start: startDate,
      end: endDate,
    },
    { weekStartsOn: 1 },
  ).reduce<{ allocationWeek: number; label: string; days: { label: string; date: Date }[] }[]>(
    (allWeeks, firstDayOfCurrentWeek, weekIndex) => {
      const lastDayOfCurrentWeek = lastDayOfWeek(firstDayOfCurrentWeek, { weekStartsOn: 1 });

      const days = eachDayOfInterval({
        start: new Date(firstDayOfCurrentWeek),
        end: new Date(lastDayOfCurrentWeek),
      }).reduce<{ label: string; date: Date }[]>((allDays, date) => {
        if (
          isSameDay(date, startDate) ||
          isSameDay(date, endDate) ||
          (isAfter(date, startDate) && isBefore(date, endDate))
        ) {
          allDays.push({
            label: `${DAYS_OF_THE_WEEK[getDay(date)]} ${formatDateCustom(
              date,
              {
                day: defaultDayFormat,
                month: defaultMonthFormat,
              },
              localeCode,
            )}`,
            date,
          });
        }
        return allDays;
      }, []);

      allWeeks.push({
        allocationWeek: weekIndex,
        label: `Week ${formatDateCustom(
          firstDayOfCurrentWeek,
          {
            day: defaultDayFormat,
            month: defaultMonthFormat,
          },
          localeCode,
        )}`,
        days,
      });

      return allWeeks;
    },
    [],
  );

  return {
    label: `${formatDateCustom(
      startDate,
      { day: defaultDayFormat, month: defaultMonthFormat, year: defaultYearFormat },
      localeCode,
    )} - ${formatDateCustom(endDate, { day: defaultDayFormat, month: defaultMonthFormat, year: defaultYearFormat }, localeCode)}`,
    weeks,
  };
};

export const togglePinnedColumnOnColumnGroupOpen = (
  params: ColumnGroupOpenedEvent,
  columnGroupId: string,
  columnId: string,
  pinnedType: ColumnPinnedType,
): void => {
  if (params.columnGroup.getGroupId() !== columnGroupId) {
    return;
  }

  params.columnApi.setColumnPinned(columnId, params.columnGroup.isExpanded() ? pinnedType : null);
};

export const redrawGroupedRowsWhenSortChanged = (params: SortChangedEvent): void => {
  if (params.columnApi.getRowGroupColumns().length > 0) {
    params.api.redrawRows();
  }
};
