import MultipleSwitchBox from 'components/patterns/MultipleSwitchBox';
import { IN_USE_STATUS } from 'consts/inUseStatus';
import { FilterAllOrInUseToggleProps } from './FilterAllOrInUseToggle.types';

const FilterAllOrInUseToggle: React.FC<FilterAllOrInUseToggleProps> = ({ changeFilters, value, dataTestId }) => {
  return (
    <div className="flex whitespace-nowrap">
      <MultipleSwitchBox
        source={[
          { label: 'All', value: '' },
          { label: 'In use', value: IN_USE_STATUS.TRUE },
        ]}
        selectedValue={value || ''}
        textKey="label"
        valueKey="value"
        onChange={(inUseValue) => changeFilters({ inUse: inUseValue })}
        dataTestId={dataTestId}
      />
    </div>
  );
};

export default FilterAllOrInUseToggle;
