import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { formatPercent, FormatTypeEnum } from 'modules/I18N';
import { Store } from 'components/common/types/Store.types';
import { isCreatedAfterSummaryPanelChanges } from 'components/pages/DealWithLines/AdsDealLines/transformDealDetails';
import { DealSummaryCard } from 'components/pages/DealWithLines/common/RightSidebar/DealSummary/DealSummaryCard/DealSummaryCard';
import DealSummaryCardSection from 'components/pages/DealWithLines/common/RightSidebar/DealSummary/DealSummaryCardSection';
import { DealSummaryCardSectionLineType } from 'components/pages/DealWithLines/common/RightSidebar/DealSummary/DealSummaryCardSection/DealSummaryCardSection.types';
import { handleValueFormat } from 'components/pages/DealWithLines/common/RightSidebar/utils';
import { Color } from 'lib/Button';
import ProgressBar, { BarBackgroundColor, BarColor } from 'lib/ProgressBar';
import CheckAllocationButton from '../../DealSummary/CheckAllocationButton';
import { DeliveryCardProps } from './DeliveryCardProps.types';

const REALLOCATE_MESSAGE = 'Your OSI is off track. Try reallocating your campaign to meet your target';

export const DeliveryCard: React.FC<DeliveryCardProps> = ({
  expectedImpressionsElapsed = 0,
  totalAllocatedImpressions = 0,
  soldImpressions = 0,
  osi = 0,
  isReallocateButtonVisible = false,
}) => {
  const { currencyCode, localeCode } = useSelector((state: RootState) => state.publisher.configuration);
  const dealType = useSelector((state: Store) => state.dealManagement.commonDeal.dealType);
  const lines = useSelector((state: Store) => state.dealManagement.backupFormData.lines);

  const isOsiBarVisible = !!totalAllocatedImpressions;

  const osiBarColor = ((): BarColor => {
    switch (true) {
      case osi < 1 && osi >= 0.98:
        return BarColor.YELLOW;
      case osi < 0.98:
        return BarColor.DEEP_RED;
      default:
        return BarColor.DARK_GREEN;
    }
  })();

  return (
    <DealSummaryCard header="DELIVERY" customCardHeight={isOsiBarVisible ? 320 : 0}>
      <DealSummaryCardSection
        lines={[
          {
            label: 'Traded impressions',
            value: isCreatedAfterSummaryPanelChanges({
              dealType,
              lines,
            })
              ? handleValueFormat({
                  valueType: FormatTypeEnum.ROUNDED_NUMBER,
                  value: soldImpressions,
                  currencyCode,
                  localeCode,
                })
              : '-',
            lineType: DealSummaryCardSectionLineType.HEADER,
          },
          {
            label: 'Expected impressions',
            value: expectedImpressionsElapsed
              ? handleValueFormat({
                  valueType: FormatTypeEnum.ROUNDED_NUMBER,
                  value: expectedImpressionsElapsed,
                  currencyCode,
                  localeCode,
                })
              : '-',
            lineType: DealSummaryCardSectionLineType.SUBHEADER_SECONDARY,
          },
        ]}
      >
        {isOsiBarVisible && (
          <div className="flex flex-col rounded-t-xl border-solid border-t border-neutral-300 p-2 -mx-3">
            <div className="flex items-baseline justify-between p-1">
              <div className="sub-header-base text-essential-primary">OSI</div>
              <div className="header-xl">{formatPercent({ value: osi, localeCode, maximumFractionDigits: 2 })}</div>
            </div>
            <ProgressBar
              completed={soldImpressions}
              max={totalAllocatedImpressions}
              indicatorPosition={expectedImpressionsElapsed}
              barColor={osiBarColor}
              barBackgroundColor={BarBackgroundColor.LIGHT_GRAY}
              barLegend={{
                barBackgroundDescription: 'Total',
                barColorDescription: 'Traded',
                indicatorDescription: 'Expected',
              }}
              id="osi-metrics"
            />
            {isReallocateButtonVisible && (
              <div className="flex flex-col p-4 gap-3 bg-primary-600-opacity-5 rounded-xl border-solid border border-neutral-300 mt-2 -mx-2 -mb-5">
                <div className="sub-header-base text-primary">{REALLOCATE_MESSAGE}</div>
                <CheckAllocationButton label="Reallocate" color={Color.PRIMARY} />
              </div>
            )}
          </div>
        )}
      </DealSummaryCardSection>
    </DealSummaryCard>
  );
};
