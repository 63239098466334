import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import { useDealFormReadOnly } from 'customHooks/useDealFormReadOnly';
import { FeatureFlags } from 'components/common/types/Features.types';
import DealActions from 'components/pages/DealWithLines/common/Main/ControllerBar/DealActions';
import LineActions from 'components/pages/DealWithLines/common/Main/ControllerBar/LineActions';
import { getIsAdServer } from 'store/publisher/selectors';
import { DealType } from 'components/common/types/Deal.types';
import { useDealAndLineActions } from '../../hooks/useDealAndLineActions';

const ControllerBar = ({ dealRef }) => {
  const dealType = useSelector((state) => state.dealManagement.commonDeal.dealType);
  const isAdServerMarket = useSelector(getIsAdServer);

  const readOnly = useDealFormReadOnly();
  const hasOutOfChargeEnabled = useHasFeatureAccess(FeatureFlags.OUT_OF_CHARGE);

  const { createLineHandler, deleteLineHandler, stopLineHandler, updateLineHandler, checkAllocateHandler, saveDeal } =
    useDealAndLineActions();

  if (readOnly && (!isAdServerMarket || dealType === DealType.GUARANTEED)) return null;

  return (
    <div
      className="flex bg-neutral-50 py-2.5 rounded-t-xl border border-neutral-950-opacity-10 divide-x divide-neutral-950-opacity-10 sticky bottom-0 w-max m-auto shadow-lg"
      data-test-id="controller-bar"
    >
      <LineActions
        createLine={createLineHandler}
        updateLine={updateLineHandler}
        deleteLine={deleteLineHandler}
        terminateLine={stopLineHandler}
      />
      <DealActions
        saveDeal={saveDeal}
        checkAllocate={checkAllocateHandler}
        dealRef={dealRef}
        hasOutOfChargeEnabled={hasOutOfChargeEnabled}
      />
    </div>
  );
};

ControllerBar.propTypes = {
  dealRef: PropTypes.shape({ current: PropTypes.object }),
};

ControllerBar.defaultProps = {
  dealRef: null,
};

export default ControllerBar;
