import { Padding, Theme, Width } from 'lib/ButtonGroup/ButtonGroup.types';
import ButtonGroup from 'lib/ButtonGroup';
import { useCampaignTypeSwitch } from './useCampaignTypeSwitch';

const CampaignTypeSwitch: React.FC = () => {
  const { campaignType, isNewDeal, onChange, options } = useCampaignTypeSwitch();

  return (
    <ButtonGroup
      onChange={onChange}
      options={options}
      selectedValue={campaignType}
      padding={Padding.TIGHT}
      theme={Theme.LIGHT}
      isDisabled={!isNewDeal}
      width={Width.MAX}
    />
  );
};

export default CampaignTypeSwitch;
