import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import config from 'config';
import Auth from 'modules/Auth';
import Session from './Session';

const isLoggedEnvironment = config.environment === 'uat' || config.environment === 'prd';

export const dataDogLogExternalPublisherId = (): void => {
  if (isLoggedEnvironment) {
    datadogRum.setGlobalContextProperty('publisherExternalId', Session.getEnvironmentId());
  }
};

export const dataDogInitialise = (): void => {
  if (isLoggedEnvironment) {
    const { email, userName } = Auth.getData();
    datadogRum.init({
      ...config.dataDog,
      env: config.environment,
      // @ts-expect-error VERSION is a globally available variable set in webpack config
      version: VERSION,
    } as RumInitConfiguration);
    datadogRum.setUser({
      email,
      name: userName,
    });
    datadogLogs.init({
      ...config.dataDog,
      env: config.environment,
      // @ts-expect-error VERSION is a globally available variable set in webpack config
      version: VERSION,
      sessionSampleRate: 100,
    });
    datadogLogs.setUser({
      email,
      name: userName,
    });
  }
};
