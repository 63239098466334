import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import uniq from 'lodash/uniq';
import SVG from 'react-inlinesvg';
import { withPageFilters } from 'components/hocs/withPageFilters';
import withCancelRequest from 'components/hocs/withCancelRequest';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';
import Modal, { ModalSize } from 'components/patterns/Modal';
import { getAllCompatibleMarkets, assignMarketsToSelectedCreatives } from 'modules/api/creativesAdministration';
import { getCommonMarkets } from 'utils/commonMarkets';
import { getDealIdsStringFromMarkets } from 'utils/dealIdsFromMarkets';
import RadioCheckBox, { types } from 'components/patterns/RadioCheckBox';
import downloadSvg from 'assets/icons/download.svg';
import monitoringSvg from 'assets/icons/monitoring.svg';
import { CreativeType } from 'components/common/types/Creative.types';

import Filters from './Filters';
import Creatives from './Creatives';
import styles from './CreativeAdministration.pcss';

const cx = classNames.bind(styles);

class CreativeAdministration extends React.Component {
  state = {
    isDialogOpen: false,
    selectedCreatives: [],
    selectedMarkets: [],
    commonMarkets: [],
    areMarketsUpdated: false,
  };

  onOpenDialog = async () => {
    try {
      const { cancelFunctions } = this.props;
      const { selectedCreatives } = this.state;
      const dspsCodes = uniq(selectedCreatives.map((creative) => creative.dsp.code));
      const allCompatibleMarkets = await getAllCompatibleMarkets(cancelFunctions, dspsCodes);
      const commonMarkets = getCommonMarkets(selectedCreatives);
      const selectedMarkets = allCompatibleMarkets.map((market) => ({
        market,
        isSelected: commonMarkets.includes(market),
      }));

      this.setState({
        isDialogOpen: true,
        selectedMarkets,
        commonMarkets,
        areMarketsUpdated: false,
      });
    } catch {} // eslint-disable-line no-empty
  };

  onCloseDialog = () => {
    this.setState({
      isDialogOpen: false,
    });
  };

  setSelectedCreatives = async (selectedCreatives) => {
    await this.setState({ selectedCreatives });
  };

  onSelectMarket = (value, isChecked) => {
    const { selectedMarkets } = this.state;

    const updatedSelectedMarkets = selectedMarkets.map(({ market, isSelected }) =>
      value === market
        ? {
            market,
            isSelected: isChecked,
          }
        : { market, isSelected },
    );

    this.setState({ selectedMarkets: updatedSelectedMarkets });
  };

  onConfirm = async () => {
    const { cancelFunctions } = this.props;
    const { selectedCreatives, selectedMarkets, commonMarkets } = this.state;

    try {
      const areMarketsUpdated = await assignMarketsToSelectedCreatives(
        cancelFunctions,
        selectedCreatives,
        selectedMarkets,
        commonMarkets,
      );

      this.setState({
        areMarketsUpdated,
        isDialogOpen: false,
        selectedCreatives: [],
      });
    } catch (error) {
      this.setState({
        areMarketsUpdated: false,
        isDialogOpen: false,
      });
    }
  };

  getCreativeInfoItem = (label, value) => {
    return (
      <div className="grid grid-cols-2 text-neutral-950-opacity-80">
        <span className="body-base">{label}</span>
        <span className="sub-header-base">{value}</span>
      </div>
    );
  };

  render() {
    const { isDialogOpen, selectedMarkets, selectedCreatives, areMarketsUpdated } = this.state;
    const { filters } = this.props;

    return (
      <>
        <Filters />
        <Creatives
          onOpenDialog={this.onOpenDialog}
          setSelectedCreatives={this.setSelectedCreatives}
          areMarketsUpdated={areMarketsUpdated}
          hasUserSelectedCreatives={selectedCreatives.length > 0}
          filters={filters}
        />
        <Modal
          dataTestId="assign-market-dialog"
          icon={<SVG src={monitoringSvg} className="text-primary" />}
          title="Assign market"
          isOpen={isDialogOpen}
          size={ModalSize.MEDIUM}
          actionButtons={
            <>
              <Button btnType={ButtonType.DANGER} btnShape={ButtonShape.NORMAL} onClick={this.onCloseDialog}>
                Cancel
              </Button>
              <Button onClick={this.onConfirm} btnShape={ButtonShape.NORMAL} btnType={ButtonType.PRIMARY}>
                Assign market
              </Button>
            </>
          }
          sidebar={
            selectedCreatives.length === 1 ? (
              <div
                className={cx('creative-info', 'bg-neutral-100-opacity-50', 'min-h-full', 'py-8', 'px-12', 'space-y-7')}
              >
                <div className="relative">
                  <img
                    className={cx('creative-info-image', 'rounded-2xl')}
                    src={selectedCreatives[0].thumbnail}
                    alt={selectedCreatives[0].externalId}
                  />
                  {selectedCreatives[0].type === CreativeType.DYNAMIC && (
                    <div className={cx('image-overlay', 'absolute w-full text-center')}>
                      <a href={selectedCreatives[0].downloadURL}>
                        <Button btnShape={ButtonShape.CIRCLE} btnType={ButtonType.SUCCESS}>
                          <SVG src={downloadSvg} />
                        </Button>
                      </a>
                    </div>
                  )}
                </div>
                <div className="space-y-2.5 break-words">
                  {this.getCreativeInfoItem('Creative ID', selectedCreatives[0].externalId)}
                  {this.getCreativeInfoItem('DSP', selectedCreatives[0].dsp.name)}
                  {this.getCreativeInfoItem('Deal ID', getDealIdsStringFromMarkets(selectedCreatives[0].market))}
                </div>
              </div>
            ) : null
          }
        >
          <div className="-mx-8 px-12 py-3 border-t border-b border-neutral-100-opacity-10">
            {selectedMarkets.length === 0 ? (
              <p className="ml-4 body-base text-neutral-950-opacity-60">
                There are no common DSP markets for the selected creatives. Please review selected creatives
              </p>
            ) : (
              <ul className="grid grid-cols-1 gap-2">
                {selectedMarkets.map(({ market, isSelected }) => {
                  return (
                    <li key={market} className={cx('market', { 'bg-neutral-950-opacity-5': isSelected })}>
                      <RadioCheckBox
                        type={types.CHECKBOX}
                        value={market}
                        label={<p className="inline ml-2 body-base text-neutral-950-opacity-80">{market}</p>}
                        isChecked={isSelected}
                        groupName="market"
                        onChange={this.onSelectMarket}
                      />
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </Modal>
      </>
    );
  }
}

CreativeAdministration.propTypes = {
  cancelFunctions: PropTypes.objectOf(PropTypes.func).isRequired,
  filters: PropTypes.object.isRequired,
};

CreativeAdministration.defaultProps = {};

export default withCancelRequest(withPageFilters(CreativeAdministration));
