import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import multiPublisherRequest from 'modules/api/multiPublisherRequest';
import { changeDsps } from 'store/publisher/reducer';
import { getIsReseller } from 'store/publisher/selectors';
import { getLookupData } from 'modules/api/lookups';
import { PublisherDspsMediaOwner } from 'components/common/types/Publisher.types';
import { Store } from 'components/common/types/Store.types';
import { useCancelRequest } from './useCancelRequest';

type DspResponse = {
  publisher?: string;
  code: string;
  name: string;
};

const formatDspsForStore = (environmentId: string, dspResponse: DspResponse[]): PublisherDspsMediaOwner => {
  return dspResponse.reduce((acc, cur) => {
    if (!acc[cur.code]) {
      acc[cur.code] = { code: cur.code, name: cur.name, mediaOwners: [] };
    }
    acc[cur.code].mediaOwners.push(cur.publisher || environmentId);

    return acc;
  }, {} as PublisherDspsMediaOwner);
};

export const useDsps = (): void => {
  const dispatch = useDispatch();
  const { environmentId } = useSelector((state: Store) => state.environment);
  const isReseller = useSelector(getIsReseller);
  const cancelFunctions = useCancelRequest();
  const availableMediaOwners: string[] = useSelector((state: Store) => state.reseller.availableMediaOwners);
  const { type } = useSelector((state: Store) => state.publisher.configuration);

  const requestArguments = {
    cancelFunctions: isReseller ? {} : cancelFunctions,
    lookupURLName: 'dsp',
    query: '',
  };

  useEffect(() => {
    if (type && environmentId) {
      (async () => {
        try {
          let dspResponse: DspResponse[] = [];
          if (isReseller && availableMediaOwners) {
            dspResponse = (await multiPublisherRequest(availableMediaOwners, getLookupData, {
              ...requestArguments,
            })) as DspResponse[];
          } else {
            dspResponse = await getLookupData(requestArguments);
          }
          dispatch(changeDsps(formatDspsForStore(environmentId, dspResponse)));
        } catch {} // eslint-disable-line no-empty
      })();
    } else {
      dispatch(changeDsps({}));
    }
  }, [availableMediaOwners, environmentId, type]);
};
