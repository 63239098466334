import { DropdownOption } from 'lib/Dropdown';

export enum PatternOption {
  REPEAT_TWO_WEEKS = 'REPEAT_TWO_WEEKS',
  REPEAT_ONE_WEEK = 'REPEAT_ONE_WEEK',
  NO_REPEAT = 'NO_REPEAT',
}

export enum FullLengthDaysOfTheWeek {
  MONDAY = 'Monday',
  TUESDAY = 'Tuesday',
  WEDNESDAY = 'Wednesday',
  THURSDAY = 'Thursday',
  FRIDAY = 'Friday',
  SATURDAY = 'Saturday',
  SUNDAY = 'Sunday',
}

export enum DaysOfTheWeek {
  MONDAY = 'MO',
  TUESDAY = 'TU',
  WEDNESDAY = 'WE',
  THURSDAY = 'TH',
  FRIDAY = 'FR',
  SATURDAY = 'SA',
  SUNDAY = 'SU',
}

export interface DaypartHour {
  id: number;
  startOffset: number;
  endOffset: number;
  startLabel: string;
  endLabel: string;
  displayName: string;
}

export interface PatternDropdownOption {
  text: string;
  value: PatternOption;
  isDisabled?: boolean;
}

export interface WeekDropdownOption extends DropdownOption {
  name: string;
  code: string;
  startDate: Date;
  endDate: Date;
}

export type GetWeekDropdownOptions = (startDate: Date, endDate: Date, isRepeatPattern: boolean) => WeekDropdownOption[];
export type GetDaypartHoursIndexes = (startOffset: number, endOffset: number) => number[];

export interface DaypartTargetingModalProps {
  isOpen: boolean;
  onClose: () => void;
  isSegment?: boolean;
}

export interface DaypartTargetingResultChipProps {
  onClickCloseButton: () => void;
}

export type DaypartTargetingHour = {
  date: Date;
  dayPartId: number;
  displayName: string;
  endOffset: number;
  id: number;
  dayId: string;
  isDisabled: boolean;
  isSelected: boolean;
  startOffset: number;
};

export interface DaypartTargetingDay {
  date: Date;
  dayId: number;
  dayOfTheWeek: DaysOfTheWeek;
  dayparts: DaypartTargetingHour[];
  id: string;
  isDisabled: boolean;
  isFullDay: boolean;
  isSelected: boolean;
}

export interface DaypartTargetingWeek {
  startDate: Date;
  endDate: Date;
  weekId: string;
  days: DaypartTargetingDay[];
}

export interface SelectedDaypart {
  date: Date;
  dayPartId: number;
  dayId: string;
  endOffset: number;
  startOffset: number;
  isSelected: boolean;
}

export type PatternOptionChangeHandler = (patternOption: PatternOption) => void;
export type WeekOptionChangeHandler = (weekOptionValue: string) => void;
export type DaypartButtonClickHandler = (selectedDaypart: SelectedDaypart) => void;
export type HourHeaderButtonClickHandler = (hourIndex: number) => () => void;
export type WeekDayHeaderButtonClickHandler = (daypartDay: DaypartTargetingDay) => () => void;
export type DaypartTargetingProps = {
  isSegment?: boolean;
};
