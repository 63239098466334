import PropTypes from 'prop-types';
import chunk from 'lodash/chunk';
import uniqueId from 'lodash/uniqueId';

import { getStringFromObjectsList } from 'utils/formatters';
import {
  PriceModel,
  ProhibitionModel,
  TimeModel,
  NetworkModel,
  TagModel,
} from 'components/common/prop-types/inventory';
import Timeline from 'components/patterns/Timeline';

import { PriceInfo } from '../Prices';
import { ProhibitionInfo } from '../Prohibitions';
import { TimeModelInfo } from '../TimeModels';
import { NetworkInfo } from '../Networks';
import { TagInfo } from '../Tags';
import Panel from './Panel';

const ReadMoreSections = ({ asset, toggleReadMoreSection, openSections }) => {
  return (
    <>
      {asset?.prices?.length ? (
        <Panel isOpen={openSections.prices} toggle={() => toggleReadMoreSection('prices')} title="Prices">
          <Timeline>
            {asset?.prices.map((price) => (
              <PriceInfo key={`${price.startDate}${price.startOffset}${price.priceType}`} price={price} />
            ))}
          </Timeline>
        </Panel>
      ) : null}
      {asset?.prohibitions?.length ? (
        <Panel
          isOpen={openSections.prohibitions}
          toggle={() => toggleReadMoreSection('prohibitions')}
          title="Prohibitions"
        >
          <Timeline>
            {chunk(asset?.prohibitions, 3).map((prohibitionGroup) => (
              <ProhibitionInfo
                key={`${asset?.routeFrameCode}, ${getStringFromObjectsList(prohibitionGroup, 'productCategoryUuid')}`}
                prohibitionGroup={prohibitionGroup}
              />
            ))}
          </Timeline>
        </Panel>
      ) : null}
      {asset?.timeModels?.length ? (
        <Panel isOpen={openSections.timeModels} toggle={() => toggleReadMoreSection('timeModels')} title="Time models">
          <Timeline>
            {asset?.timeModels.map((model) => (
              <TimeModelInfo key={uniqueId(`${'readMoreTimeModel_'}`)} model={model} />
            ))}
          </Timeline>
        </Panel>
      ) : null}
      {asset?.networks?.length ? (
        <Panel isOpen={openSections.networks} toggle={() => toggleReadMoreSection('networks')} title="Networks">
          <Timeline>
            {asset?.networks.map((network) => (
              <NetworkInfo key={network.networkUuid} network={network} />
            ))}
          </Timeline>
        </Panel>
      ) : null}
      {asset?.tags?.length ? (
        <Panel isOpen={openSections.tags} toggle={() => toggleReadMoreSection('tags')} title="Tags">
          <Timeline>
            {chunk(asset?.tags, 3).map((tagGroup) => (
              <TagInfo key={getStringFromObjectsList(tagGroup, 'tagUuid')} tagGroup={tagGroup} />
            ))}
          </Timeline>
        </Panel>
      ) : null}
    </>
  );
};

ReadMoreSections.propTypes = {
  asset: PropTypes.shape({
    prices: PropTypes.arrayOf(PriceModel),
    prohibitions: PropTypes.arrayOf(ProhibitionModel),
    timeModels: PropTypes.arrayOf(TimeModel),
    networks: PropTypes.arrayOf(NetworkModel),
    tags: PropTypes.arrayOf(TagModel),
    routeFrameCode: PropTypes.string,
  }),
  openSections: PropTypes.shape({
    prices: PropTypes.bool.isRequired,
    prohibitions: PropTypes.bool.isRequired,
    timeModels: PropTypes.bool.isRequired,
    networks: PropTypes.bool.isRequired,
    tags: PropTypes.bool.isRequired,
  }).isRequired,
  toggleReadMoreSection: PropTypes.func.isRequired,
};

ReadMoreSections.defaultProps = {
  asset: {},
};

export default ReadMoreSections;
