import { ReactElement } from 'react';
import IconButton from 'lib/IconButton';
import uploadSvg from 'assets/icons/upload.svg';

const FileIconOnlyButton = ({ isDisabled, open }: { isDisabled: boolean; open: () => void }): ReactElement => {
  return (
    <IconButton
      name="file-upload"
      icon={uploadSvg}
      handleOnClick={open}
      isDisabled={isDisabled}
      dataTestId="file-upload-icon"
    />
  );
};

export default FileIconOnlyButton;
