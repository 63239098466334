import { useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from 'lib/Button';
import { selectVisualUnit } from 'store/pages/contentManagement/creativeSetDetails/reducer';
import FilterArea from './FilterArea';
import { VisualUnitGroup } from './VisualUnitGroups.types';
import VisualUnitTable from './VisualUnitTable';

const VisualUnitGroups: React.FC = () => {
  const dispatch = useDispatch();
  const [selectedRowData, setSelectedRowData] = useState<VisualUnitGroup | null>(null);

  const onSelectButtonClick = (): void => {
    if (!selectedRowData) return;

    dispatch(selectVisualUnit(selectedRowData));
  };

  return (
    <>
      <div data-test-id="visual-unit-groups" className="p-4 flex-1 border-t border-t-neutral-950-opacity-10">
        <FilterArea />
        <VisualUnitTable getSelectedRow={(value: VisualUnitGroup | null) => setSelectedRowData(value)} />
      </div>
      <div className="flex justify-end pr-4">
        <Button label="Select" onClick={onSelectButtonClick} isDisabled={!selectedRowData} />
      </div>
    </>
  );
};

export default VisualUnitGroups;
