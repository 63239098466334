import { useSelector } from 'react-redux';

import startSvg from 'assets/icons/start.svg';
import Button, { Color, Size } from 'lib/Button';
import { Store } from 'components/common/types/Store.types';
import { getIsAdServer } from 'store/publisher/selectors';
import useProgrammaticPlannerActions from 'components/pages/Planner/hooks/useProgrammaticPlannerActions';
import { getActionConditions } from '../../../getActionConditions';

const CheckAllocationButton = ({
  label = 'Check Allocation',
  color = Color.SECONDARY,
}: {
  label?: string;
  color?: Color;
}): React.ReactElement => {
  const { checkAllocateHandler } = useProgrammaticPlannerActions();
  const state = useSelector((store: Store) => store.dealManagement);
  const isAdServerMarket = useSelector(getIsAdServer);
  const dealConfig = useSelector((store: Store) => store.dealConfig);

  const { checkAllocate } = getActionConditions(state, isAdServerMarket, dealConfig);

  return (
    <Button
      dataTestId="check-allocation-button"
      color={color}
      label={label}
      size={Size.FIT_CONTAINER}
      svg={startSvg}
      isDisabled={checkAllocate.disabled}
      onClick={checkAllocateHandler}
    />
  );
};

export default CheckAllocationButton;
