import { useSelector } from 'react-redux';
import Auth from 'modules/Auth';
import { PermissionsEnum } from 'consts/permissions';
import { DealAction, DealFilters, DealStatus } from 'components/common/types/Deal.types';
import { FeatureFlags } from 'components/common/types/Features.types';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import { getIsAdServer } from 'store/publisher/selectors';
import { usePageFilters } from 'customHooks/usePageFilters';
import FilterArea from 'components/common/FilterArea';
import { dealTypesList } from 'consts/dealTypesList';
import { FilterAdvertiser, FilterDealType, FilterDsp, FilterStatus } from 'components/common/FilterArea/Filters';
import FilterDealNameOrId from './FilterDealNameOrId';
import FilterInternalId from './FilterInternalId';
import FilterDealAction from './FilterDealAction';

const Filters: React.FC = () => {
  const {
    changeFilters,
    filters: { dealNameOrId, status, dealType, dsp, advertiser, dismountedSearch, internalId },
  } = usePageFilters<DealFilters>('insights/dashboard');
  const isAdServerMarket = useSelector(getIsAdServer);

  const STATUSES = [
    { code: DealStatus.PENDING_APPROVAL, name: 'Pending approval' },
    { code: DealStatus.APPROVED, name: 'Approved' },
    { code: DealStatus.REJECTED, name: 'Rejected' },
    { code: DealStatus.LIVE, name: 'Live' },
    { code: DealStatus.ENDED, name: 'Ended' },
    { code: DealStatus.CANCELLED, name: 'Cancelled' },
    { code: DealStatus.TERMINATED, name: 'Terminated' },
    ...(isAdServerMarket ? [{ code: DealStatus.FAILED, name: 'Failed' }] : []),
    ...(useHasFeatureAccess(FeatureFlags.DV360)
      ? [
          { code: DealStatus.PENDING_RESERVATION, name: 'Pending Reservation' },
          { code: DealStatus.RESERVED, name: 'Reserved' },
        ]
      : []),
  ];

  return (
    <FilterArea customWrapperStyles="flex flex-row w-full h-full gap-5 [&>*]:flex-1">
      <FilterDsp selectedValue={dsp} onChange={(name, value) => changeFilters({ [name]: value })} />
      <FilterDealNameOrId changeFilters={changeFilters} selectedDealNameOrId={dealNameOrId} />
      <FilterAdvertiser changeFilters={changeFilters} selectedAdvertiser={advertiser} />
      <FilterStatus changeFilters={changeFilters} status={status} statusesList={STATUSES} />
      {Auth.hasPermission(PermissionsEnum.DEAL_MANAGEMENT_AUTOMATION_REFERENCE_VIEW) && !isAdServerMarket && (
        <FilterInternalId changeFilters={changeFilters} selectedInternalId={internalId} />
      )}
      {(useHasFeatureAccess(FeatureFlags.DEAL_TYPE_FILTER) || isAdServerMarket) && (
        <FilterDealType dealType={dealType} changeFilters={changeFilters} dealTypesList={dealTypesList} />
      )}
      {useHasFeatureAccess(FeatureFlags.OUT_OF_CHARGE) && (
        <FilterDealAction changeFilters={changeFilters} selectedDealAction={dismountedSearch ?? DealAction.NONE} />
      )}
    </FilterArea>
  );
};

export default Filters;
