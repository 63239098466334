import { useDispatch, useSelector } from 'react-redux';
import MultiSelect from 'components/patterns/MultiSelect';
import Chips from 'components/patterns/Chips';
import { FrontEndType, Tag } from 'components/common/types/Deal.types';
import { PopupPosition } from 'components/patterns/Popup';
import { Store } from 'components/common/types/Store.types';
import { changeDealCurrentLineData } from 'store/dealManagement/reducer';
import BaseSelect from 'lib/BaseSelect';
import { TagsContentProps } from './TagsContent.types';
import { CodeNameModel } from '../types';
import { TagCategory } from '../types/DirectSalesCampaign.types';

const tagContentStyles = {
  [FrontEndType.STANDARD]: 'space-y-4 xl:w-4/12',
  [FrontEndType.PLANNER]: 'space-y-4 min-w-36',
};

const TagsContent: React.FC<TagsContentProps> = ({ onCategorySelection, readOnly, tagCategory, tagsData = [] }) => {
  const dispatch = useDispatch();
  const tags = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.tags);
  const frontEndType = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.frontEndType);
  const environments = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.environments);

  const style = tagContentStyles[frontEndType];

  const onTagSelection = (newTags: Tag[]): void => {
    dispatch(changeDealCurrentLineData({ tags: [...newTags] }));
  };

  const onTagDeletion = (tag?: CodeNameModel): void => {
    if (!tag) {
      dispatch(changeDealCurrentLineData({ tags: [] }));
      return;
    }

    const remainingTags = tags.filter(({ code }) => code !== tag.code);
    dispatch(changeDealCurrentLineData({ tags: remainingTags }));
  };

  const onTagIncludeExclude = (item: Tag, include: boolean): void => {
    const modifiedTags = tags.map((tag) =>
      tag.code === item.code
        ? {
            ...tag,
            include,
          }
        : tag,
    );
    onTagSelection(modifiedTags);
  };

  return (
    <>
      <div className={style}>
        <div className="mb-2">
          <BaseSelect
            name="tags"
            dataTestId="tags"
            options={tagsData}
            selectedValue={tagCategory}
            placeholder="Select tags"
            onSelect={(_, value: CodeNameModel) => onCategorySelection(value as TagCategory)}
            isDisabled={readOnly || !environments.length || !tagsData.length}
          />
        </div>
        <MultiSelect
          items={tagCategory?.tags}
          dataTestId="tag-input"
          selectedItems={tags}
          onSelect={onTagSelection}
          onClearAll={() => onTagDeletion()}
          isDisabled={readOnly || !tagCategory}
          containerPosition={PopupPosition.TOP}
        />
      </div>
      <div className="flex flex-wrap items-start">
        {!!tags?.length && (
          <Chips
            dataTestId="tags-list-chips"
            dataList={tags}
            idKey="code"
            labelKey="name"
            isClearAllVisible={false}
            onRemove={onTagDeletion}
            excludeKey="include"
            onIncludeExcludeChange={onTagIncludeExclude}
            isDisabled={readOnly}
          />
        )}
      </div>
    </>
  );
};

export default TagsContent;
