import DealCpm from 'components/common/Deal/DealCpm';
import { InputSize, InputTheme } from 'components/common/types/Input.types';
import { Store } from 'components/common/types/Store.types';
import { useSelector } from 'react-redux';
import { getIsAdServer } from 'store/publisher/selectors';
import { DealStatus } from 'components/common/types/Deal.types';
import { Label } from 'lib/Label';
import { useLineCPM } from '../../CampaignSettings/LineCPMSwitch/useLineCPM';

export const InputDealCpm: React.FC = () => {
  const isCpmCampaignLevel = useSelector((state: Store) => state.dealManagement.isCpmCampaignLevel);
  const bookingStatusCode = useSelector((state: Store) => state.dealManagement.commonDeal.bookingStatusCode);
  const dealCpm = useSelector((state: Store) => state.dealManagement.commonDeal.cpm);
  const isAdServerMarket = useSelector(getIsAdServer);

  const { readOnly, onCpmChange } = useLineCPM();

  const isPendingReservation = bookingStatusCode === DealStatus.PENDING_RESERVATION;

  return (
    <>
      <Label id="cpm" theme={InputTheme.FLAT_GRAY} label="CPM" isRequired={isCpmCampaignLevel} />
      <DealCpm
        name="CPM"
        theme={InputTheme.FLAT_GRAY}
        size={InputSize.EXTRA_SMALL}
        value={dealCpm}
        onChange={onCpmChange}
        isDisabled={(readOnly && !isPendingReservation) || (isAdServerMarket && !isCpmCampaignLevel)}
        isRequired={isCpmCampaignLevel}
      />
    </>
  );
};
