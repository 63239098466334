import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { Store } from 'components/common/types/Store.types';
import IconButton from 'lib/IconButton';
import sidebarSimpleLeftIcon from 'assets/icons/sidebar-simple-left.svg';
import { changeFormParams } from 'store/dealManagement/reducer';
import { ChipBookingStatus } from '../ChipBookingStatus/ChipBookingStatus';
import { IconTradingType } from '../IconTradingType/IconTradingType';
import { SelectTradingType } from '../SelectTradingType/SelectTradingType';

export const CampaignNameAndStatus: React.FC<{
  customCampaignDrawerToggle?: () => void; // Temporary, remove after both ADS-6831 and ADS-6897 are implemented
  isPlannerHeader?: boolean;
}> = ({ customCampaignDrawerToggle, isPlannerHeader }) => {
  const dispatch = useDispatch();

  const dealName = useSelector((state: Store) => state.dealManagement.commonDeal.dealName);
  const isCampaignDrawerOpen = useSelector((state: Store) => state.dealManagement.isCampaignDrawerOpen);

  const handleToggleCampaignDrawer = (): void => {
    dispatch(changeFormParams({ isCampaignDrawerOpen: !isCampaignDrawerOpen }));
  };

  return (
    <div
      className={cx('flex flex-shrink-0 gap-2 items-center px-[8px]', {
        'border-r border-neutral-950-opacity-10': !isCampaignDrawerOpen,
        'w-[380px]': isPlannerHeader && !isCampaignDrawerOpen,
        'w-[348px]': !isPlannerHeader || isCampaignDrawerOpen,
      })}
    >
      {isCampaignDrawerOpen ? (
        <SelectTradingType />
      ) : (
        <>
          <IconTradingType />
          <button
            data-test-id="deal-header-deal-name"
            className={cx('header-base w-[271px] truncate text-left', {
              'text-essential-tertiary italic': !dealName,
              'text-essential-primary': !!dealName,
            })}
            type="button"
            onClick={customCampaignDrawerToggle ?? handleToggleCampaignDrawer}
          >
            {dealName || 'New campaign'}
          </button>
          <ChipBookingStatus />
        </>
      )}

      <IconButton
        icon={sidebarSimpleLeftIcon}
        name="Toggle campaign drawer"
        handleOnClick={customCampaignDrawerToggle ?? handleToggleCampaignDrawer}
      />
    </div>
  );
};
