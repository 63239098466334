import { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import cx from 'classnames';

import chevronRightSvg from 'assets/icons/chevron_right.svg';
import { DropdownItemProps, Option, OptionCode } from 'components/common/types/DropdownItem.types';
import RadioCheckBox, { types } from 'components/patterns/RadioCheckBox';
import { DropdownItemContainer } from './DropdownItemContainer';

const ITEM_SELECTION_STATUS = {
  CHECKED: 'CHECKED',
  INDETERMINATE: 'INDETERMINATE',
  UNCHECKED: 'UNCHECKED',
};

export const getLeafNodes = (option: Option): Option[] =>
  option.children?.length ? option.children.flatMap(getLeafNodes) : [option];

const getItemSelectionStatus = (option: Option, selectedItems: OptionCode[] = []): string => {
  const leafNodes = getLeafNodes(option);
  const selectedLeafNodes = leafNodes.filter((node: Option) =>
    selectedItems.find((code: OptionCode) => node.code === code),
  );

  if (!selectedLeafNodes.length) return ITEM_SELECTION_STATUS.UNCHECKED;
  if (selectedLeafNodes.length === leafNodes.length) return ITEM_SELECTION_STATUS.CHECKED;
  return ITEM_SELECTION_STATUS.INDETERMINATE;
};

export const getSelectedAncestors = (option: Option, selectedItems: OptionCode[]): Option[] => {
  if (option.selectable && getItemSelectionStatus(option, selectedItems) === ITEM_SELECTION_STATUS.CHECKED)
    return [option];
  if (option.children?.length)
    return option.children.flatMap((i) =>
      getSelectedAncestors(
        { ...i, name: `${option.selectable ? `${option.name} > ${i.name}` : i.name}` },
        selectedItems,
      ),
    );
  return [];
};

export const DropdownItem = ({
  multiLevelDropdownId,
  option,
  isSubMenuOpen,
  setIsSubMenuOpen,
  isSelectable,
  selectedItems,
  onChangeSelectedItem,
  isPositionFixed,
  depth = 0,
}: DropdownItemProps): JSX.Element => {
  const [openSubMenu, setOpenSubMenu] = useState<number | null>(null);

  useEffect(() => {
    if (!isSubMenuOpen) setOpenSubMenu(null);
  }, [isSubMenuOpen]);

  const onChange = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    getLeafNodes(option).forEach((element: Option) => {
      onChangeSelectedItem?.(element, checked);
    });
  };

  const itemSelectionStatus = getItemSelectionStatus(option, selectedItems);

  return (
    <>
      {option.children?.length ? (
        <>
          <li
            aria-label={`${multiLevelDropdownId}-${option.name}-item-${depth}`}
            key={`${multiLevelDropdownId}-${option.name}-item-${depth}`}
            className={cx('cursor-pointer px-3', { relative: depth > 0 })}
            onMouseEnter={() => setIsSubMenuOpen?.(option.code)}
          >
            <div
              className={cx(
                'flex p-2 mb-0.5 text-neutral-900 body-base rounded-md justify-between items-center hover:bg-neutral-200 hover:text-primary-950',
                {
                  'bg-neutral-200 text-primary-950 hovered-dropdown-item': isSubMenuOpen,
                },
              )}
            >
              {isSelectable ? (
                <div className="w-max px-1">
                  <RadioCheckBox
                    value={option.code}
                    label={
                      <p className="text-neutral-950-opacity-80 body-base inline break-words ml-2">{option.name}</p>
                    }
                    type={types.CHECKBOX}
                    isChecked={itemSelectionStatus === ITEM_SELECTION_STATUS.CHECKED}
                    groupName={`group-${option.code}`}
                    onChange={onChange}
                    isIndeterminate={itemSelectionStatus === ITEM_SELECTION_STATUS.INDETERMINATE}
                  />
                </div>
              ) : (
                <div className="flex items-center">
                  <span
                    className={`h-1 w-1 bg-primary inline-block rounded-full mr-1.5 ${
                      itemSelectionStatus === ITEM_SELECTION_STATUS.UNCHECKED ? 'invisible' : ''
                    }`}
                  />
                  <p>{option.name}</p>
                </div>
              )}
              <SVG src={chevronRightSvg} className="h-4 w-4 text-neutral-950-opacity-50" />
            </div>
          </li>

          {isSubMenuOpen && (
            <DropdownItemContainer dropdownId={multiLevelDropdownId} optionName={option.name} depth={depth}>
              {option.children.map((opt) => (
                <DropdownItem
                  multiLevelDropdownId={multiLevelDropdownId}
                  option={opt}
                  key={opt.code}
                  setIsSubMenuOpen={(code: number) => {
                    setOpenSubMenu(code);
                  }}
                  isSubMenuOpen={openSubMenu === opt.code}
                  isSelectable={opt.selectable}
                  selectedItems={selectedItems}
                  onChangeSelectedItem={onChangeSelectedItem}
                  depth={depth + 1}
                  isPositionFixed={isPositionFixed}
                />
              ))}
            </DropdownItemContainer>
          )}
        </>
      ) : (
        <li
          aria-label={`${multiLevelDropdownId}-${option.name}-item-${depth}`}
          key={`${multiLevelDropdownId}-${option.name}-item-${depth}`}
          className="cursor-pointer px-3"
        >
          <div className="flex p-2 mb-0.5 text-neutral-900 body-base rounded-md items-center hover:bg-neutral-200 hover:text-primary-950">
            <div className="w-max grow px-1">
              <RadioCheckBox
                value={option.code}
                label={<p className="text-neutral-950-opacity-80 body-base inline break-words ml-2">{option.name}</p>}
                type={types.CHECKBOX}
                isChecked={itemSelectionStatus === ITEM_SELECTION_STATUS.CHECKED}
                groupName={`group-${option.code}`}
                onChange={onChange}
                isIndeterminate={itemSelectionStatus === ITEM_SELECTION_STATUS.INDETERMINATE}
                chipText={option?.chipText}
              />
            </div>
          </div>
        </li>
      )}
    </>
  );
};

DropdownItem.defaultProps = {
  isSubMenuOpen: false,
  isSelectable: false,
  selectedItems: [],
  isPositionFixed: false,
};

export default DropdownItem;
