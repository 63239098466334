import { Dispatch, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SWEEP_OPTIONS_INFO, SWEEP_OPTIONS_NAMES } from 'consts/deal';
import { DealType, DealStatus, SweepOptions } from 'components/common/types/Deal.types';
import { getSweepOptions, getDefaultSweep } from 'utils/deal';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import { FeatureFlags } from 'components/common/types/Features.types';
import InfoMessage from 'components/common/Deal/InfoMessage';
import { Store } from 'components/common/types/Store.types';
import { changeDealCurrentLineData } from 'store/dealManagement/reducer';

import { SweepProps } from 'components/common/types/Sweep.types';
import { getIsAdServer } from 'store/publisher/selectors';
import { CardTheme } from 'components/patterns/Card/Card.types';
import BaseSelect from 'lib/BaseSelect';
import { CodeNameModel } from 'components/common/types';

const Sweep: React.FC<SweepProps> = ({ disabled }) => {
  const dispatch = useDispatch();
  const bookingStatusCode = useSelector((state: Store) => state.dealManagement.commonDeal.bookingStatusCode);
  const dealType = useSelector((state: Store) => state.dealManagement.commonDeal.dealType);
  const id = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.id);
  const sweep = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.sweep as SweepOptions);
  const isAdServerMarket = useSelector(getIsAdServer);

  const hasNonGuaranteedExtendedTargetEnabled = useHasFeatureAccess(FeatureFlags.NON_GUARANTEED_EXTENDED_TARGET);

  useEffect(() => {
    if (!id) {
      dispatch(
        changeDealCurrentLineData({
          sweep: getDefaultSweep(dealType, hasNonGuaranteedExtendedTargetEnabled),
        }),
      );
    }
  }, [id]);

  const onSweepSelect: Dispatch<SweepOptions> = (value) => {
    dispatch(changeDealCurrentLineData({ sweep: value }));
  };

  const sweepOptions = useMemo(
    () =>
      Object.entries(getSweepOptions(isAdServerMarket)).map(([key, { name }]: [string, { name: string }]) => ({
        code: key,
        name,
      })),
    [isAdServerMarket],
  );

  if (dealType !== DealType.GUARANTEED && !hasNonGuaranteedExtendedTargetEnabled) return null;

  return (
    <div>
      <label
        htmlFor="sweep"
        className="sub-header-base text-neutral-950-opacity-60 mb-2 inline-block"
        data-test-id="sweep-header"
      >
        Sweep
      </label>
      <BaseSelect
        name="sweep"
        dataTestId="sweep-dropdown"
        options={sweepOptions}
        selectedValue={{
          code: sweep,
          name: SWEEP_OPTIONS_NAMES[sweep],
        }}
        onSelect={(_, value: CodeNameModel) => onSweepSelect(value.code as SweepOptions)}
        isDisabled={disabled && bookingStatusCode !== DealStatus.PENDING_RESERVATION}
      />
      <div className="mt-4" data-test-id="sweep-info-message">
        <InfoMessage cardTheme={CardTheme.PRIMARY}>
          <span className="body-base text-primary-600">{SWEEP_OPTIONS_INFO[sweep as SweepOptions]}</span>
        </InfoMessage>
      </div>
    </div>
  );
};

export default Sweep;
