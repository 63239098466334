import Overview from './Overview';
import CampaignSettings from './CampaignSettings';
import Details from './Details';

export const CampaignDrawer: React.FC = () => {
  return (
    <div
      className="w-[350px] shrink-0 bg-white h-full border-x border-neutral-950-opacity-10 flex flex-col overflow-auto"
      data-test-id="campaign-drawer-open"
    >
      <Overview />
      <Details />
      <CampaignSettings />
    </div>
  );
};
