import { InputTheme } from 'components/common/types/Input.types';
import { Store } from 'components/common/types/Store.types';
import { getLineLevelCpmSettings } from 'components/pages/DealWithLines/common/Main/Deal/selectors';
import ToggleSwitch, { ToggleTheme } from 'components/patterns/ToggleSwitch';
import { Label } from 'lib/Label';
import { useSelector } from 'react-redux';
import { useLineCPM } from './useLineCPM';

const LineCPMSwitch: React.FC = () => {
  const isCpmCampaignLevel = useSelector((state: Store) => state.dealManagement.isCpmCampaignLevel);
  const { isLineLevelCpmVisible, isLineLevelCpmEditable } = useSelector(getLineLevelCpmSettings);

  const { onCpmCampaignLevelChange } = useLineCPM();

  return isLineLevelCpmVisible ? (
    <div className="flex justify-between items-center h-8">
      <Label id="Toggle for CPM line" theme={InputTheme.FLAT_GRAY} label="Apply CPM at deal line" />
      <ToggleSwitch
        id="Toggle for CPM line"
        theme={ToggleTheme.SMALL_LIGHT}
        onChange={onCpmCampaignLevelChange}
        isChecked={!isCpmCampaignLevel}
        isDisabled={!isLineLevelCpmEditable}
      />
    </div>
  ) : null;
};

export default LineCPMSwitch;
