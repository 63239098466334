import PropTypes from 'prop-types';

export const ProhibitionModel = PropTypes.shape({
  productCategoryUuid: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  productCategoryName: PropTypes.string,
});

export const TagModel = PropTypes.shape({
  tagUuid: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  tagName: PropTypes.string,
});

export const PriceModel = PropTypes.shape({
  price: PropTypes.number.isRequired,
  days: PropTypes.array.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  priceType: PropTypes.string.isRequired,
  priceCover: PropTypes.number.isRequired,
  startOffset: PropTypes.number,
  endOffset: PropTypes.number,
});

export const TimeModel = PropTypes.shape({
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  days: PropTypes.array.isRequired,
  startOffset: PropTypes.number.isRequired,
  endOffset: PropTypes.number.isRequired,
});

export const NetworkModel = PropTypes.shape({
  networkUuid: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  priceFactor: PropTypes.number.isRequired,
  networkGroupName: PropTypes.string,
  networkName: PropTypes.string,
});
