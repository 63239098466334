import { LegacyRef } from 'react';
import { ShadowStyle } from 'consts/shadow';
import { InputShape, InputSize, InputTheme } from 'components/common/types/Input.types';
import { MenuPlacement, GroupBase, MenuPosition } from 'react-select';
import { CodeNameModel } from 'components/common/types';

export enum Padding {
  NONE = 'py-0',
  DEFAULT = 'py-1',
}

export interface CustomBaseSelectProps {
  customComponents?: {};
  dataTestId?: string;
  placeholder?: string;
  defaultOptions?: CodeNameModel[];
  isClearable?: boolean;
  menuPlacement?: MenuPlacement;
  isMultiple?: boolean;
  isSelectionVisible?: boolean;
  isPlaceholderVisible?: boolean;
  maxMenuHeight?: number;
  name: string;
  onSelect: (arg1: string, arg2: CodeNameModel[] | CodeNameModel) => void;
  selectedValue?: CodeNameModel[] | CodeNameModel | null;
  menuPosition?: MenuPosition;
  shadow?: ShadowStyle;
  shape?: InputShape;
  padding?: Padding;
  isDisabled?: boolean;
  closeMenuOnSelect?: boolean;
  withPortal?: boolean;
  isSearchable?: boolean;
  theme?: InputTheme;
  size?: InputSize;
  classNameWrapper?: string;
  dataPendoId?: string | null;
}

export type BaseSelectOptionProps = {
  isMultiple: boolean;
  isSelected: boolean;
  innerRef: LegacyRef<HTMLDivElement>;
  innerProps: BaseSelectOptionInnerProps;
  children: React.ReactNode;
};

export type OptionObject = {
  [key: string]: string;
};

export type OptionType = {
  code: string;
  name: string;
};

export type BaseSelectProps = GroupBase<OptionType> & CustomBaseSelectProps;

export type BaseSelectOptionInnerProps = {
  id: string;
  onClick: () => void;
};
