import React from 'react';
import { useSelector } from 'react-redux';
import { Store } from 'components/common/types/Store.types';
import Chip from 'lib/Chip';
import { Size } from 'lib/Chip/Chip.types';
import { bookingStatusStyles, defaultBookingStatusStyle } from './consts';

interface ChipBookingStatusProps {
  dataTestId?: string;
}

export const ChipBookingStatus: React.FC<ChipBookingStatusProps> = ({ dataTestId = 'chip-booking-status' }) => {
  const bookingStatus = useSelector((state: Store) => state.dealManagement.commonDeal.bookingStatusCode);
  const styles = bookingStatusStyles[bookingStatus] ?? defaultBookingStatusStyle;

  return <Chip label={styles.label} color={styles.color} size={Size.SMALL} dataTestId={dataTestId} />;
};
