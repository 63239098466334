import { Color } from 'lib/Chip/Chip.types';

export interface EditableChipProps {
  buttonLabel?: string;
  chipColor?: Color;
  chipLabel: string;
  dataTestId?: string;
  isActive?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
}

export const defaultButtonLabel = 'Edit';
