import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface DspFromMediaOwners {
  publisher: string;
  code: string;
  name: string;
}

export interface ResellerState {
  availableMediaOwners: string[];
}

const initialState: ResellerState = {
  availableMediaOwners: [],
};

const resellerSlice = createSlice({
  name: 'reseller',
  initialState,
  reducers: {
    changeAvailableMediaOwners: (state: ResellerState, action: PayloadAction<string[]>) => {
      return {
        ...state,
        availableMediaOwners: action.payload,
      };
    },
  },
});

export const { changeAvailableMediaOwners } = resellerSlice.actions;
export default resellerSlice.reducer;
