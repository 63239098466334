import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TagProps } from 'components/common/Deal/Tags/Tags.types';
import multiPublisherRequest from 'modules/api/multiPublisherRequest';
import { getResellerDealMediaOwners } from 'store/reseller/selectors';
import { getIsReseller } from 'store/publisher/selectors';
import { Label } from 'lib/Label';
import TagsContent from 'components/common/TagsContent';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { Store } from 'components/common/types/Store.types';
import { TagCategory } from 'components/common/types/DirectSalesCampaign.types';
import { getTagData } from 'modules/api/lookups';
import { transformTagsData } from 'utils/transformTagsData';

const Tags: React.FC<TagProps> = ({ readOnly }: TagProps) => {
  const cancelFunctions = useCancelRequest();
  const resellerMediaOwners = useSelector(getResellerDealMediaOwners);
  const isReseller = useSelector(getIsReseller);
  const frontEndType = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.frontEndType);
  const environments = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.environments);
  const [tagsData, setTagsData] = useState<TagCategory[] | []>([]);
  const [tagCategory, setTagCategory] = useState<TagCategory>();

  useEffect(() => {
    const getTags = async (): Promise<void> => {
      try {
        const environmentCodes = environments.map((item) => item.code);
        const requestArguments = { cancelFunctions, lookupURLName: 'tagGroup', environmentCodes };
        let result;

        if (isReseller) {
          result = await multiPublisherRequest(resellerMediaOwners, getTagData, {
            ...requestArguments,
          });
        } else {
          result = await getTagData(requestArguments);
        }

        if (result && !result.length) return;

        const tagsResponse = transformTagsData(result, frontEndType);

        setTagsData(tagsResponse);
        setTagCategory(undefined);
      } catch {} // eslint-disable-line no-empty
    };

    if (environments.length >= 1) {
      getTags();
    }
  }, [environments]);

  return (
    <div className="space-y-4 px-12 py-6">
      <Label label="Tags" />
      <TagsContent
        readOnly={readOnly}
        tagsData={tagsData}
        tagCategory={tagCategory}
        onCategorySelection={(value) => setTagCategory(value)}
      />
    </div>
  );
};

export default Tags;
