import Input from 'lib/Input';
import { Label } from 'lib/Label';
import { useDspSync } from 'components/common/Deal/DspSync/useDspSync';
import { InputSize, InputTheme } from 'components/common/types/Input.types';
import ToggleSwitch, { ToggleTheme } from 'components/patterns/ToggleSwitch';
import { useDspSettings } from 'customHooks/useDspSettings';

const DspSyncSwitch: React.FC = () => {
  const { dspSeatId, isWithDspSync, isDisabled, handleSwitchToggle, updateDspSeat } = useDspSync();
  const { isSelectedDspSupportingSync } = useDspSettings();

  if (!isSelectedDspSupportingSync) return null;

  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between items-center h-8">
        <Label theme={InputTheme.FLAT_GRAY} label="Sync deal to DSP" />
        <ToggleSwitch
          onChange={handleSwitchToggle}
          isChecked={isWithDspSync}
          isDisabled={isDisabled}
          dataTestId="dsp-sync-switch"
          theme={ToggleTheme.SMALL_LIGHT}
        />
      </div>
      {isWithDspSync && (
        <div className="flex items-center justify-between">
          <Label theme={InputTheme.FLAT_GRAY} label="DSP seat ID" isRequired />
          <Input
            dataTestId="dsp-seat-id"
            theme={InputTheme.FLAT_GRAY}
            size={InputSize.EXTRA_SMALL}
            isRequired
            value={dspSeatId}
            onBlur={updateDspSeat}
            isDisabled={isDisabled}
          />
        </div>
      )}
    </div>
  );
};

export default DspSyncSwitch;
