export enum BannerType {
  NONE = '',
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export interface BannerButtonSettings {
  actionCallback?: () => void;
  buttonLabel?: string;
}

export const backgroundColorStyle = {
  [BannerType.INFO]: 'bg-primary-600',
  [BannerType.WARNING]: 'bg-yellow-200',
  [BannerType.ERROR]: 'bg-pinkRed-200',
};

export const borderColorStyle = {
  [BannerType.INFO]: 'border-primary-600',
  [BannerType.WARNING]: 'border-yellow-200',
  [BannerType.ERROR]: 'border-pinkRed-200',
};

export const fontColorStyle = {
  [BannerType.INFO]: 'text-neutral-50',
  [BannerType.WARNING]: 'text-yellow-950',
  [BannerType.ERROR]: 'text-pinkRed-950 ',
};

export const confirmButtonStyle = {
  [BannerType.INFO]: 'text-primary-600 hover:bg-white',
  [BannerType.WARNING]: 'text-primary-600 hover:bg-white0',
  [BannerType.ERROR]: 'text-pinkRed-50 !bg-pinkRed-800 hover:bg-pinkRed-800',
};
