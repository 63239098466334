import { PropsWithChildren } from 'react';
import ReactModal from 'react-modal';
import SVG from 'react-inlinesvg';
import close from 'assets/icons/close.svg';
import Button, { Color } from 'lib/Button';
import { ModalProps } from './Modal.types';

const Modal: React.FC<PropsWithChildren<ModalProps>> = ({
  isOpen,
  onClose,
  title,
  text,
  icon,
  children,
  actionButtons,
  dataTestId,
  shouldCloseOnOverlayClick = true,
  shouldCloseOnEsc = true,
  customHeaderComponent,
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldCloseOnEsc={shouldCloseOnEsc}
      onRequestClose={onClose}
      overlayClassName="bg-modalOverlay-dark fixed inset-x-0 inset-y-0 z-50"
      className="bg-neutral-50 absolute transform -translate-y-2/4 -translate-x-2/4 top-1/2 left-1/2 rounded-md"
    >
      <div className="flex flex-row p-6 gap-4" data-test-id={dataTestId}>
        {icon && (
          <div data-test-id="modal-icon">
            <SVG src={icon} className="text-neutral-500 w-6 h-6 p-0.5" />
          </div>
        )}
        <div className="flex flex-col gap-4">
          <div className="flex">
            <div className="grow sub-header-lg">{title}</div>
            {customHeaderComponent && <div>{customHeaderComponent}</div>}
            {onClose && (
              <Button
                dataTestId="close-modal-button"
                onClick={onClose}
                svg={close}
                svgClassnames="w-6 h-6 text-neutral-950-opacity-70"
                color={Color.TRANSPARENT}
                classNames="!p-0"
              />
            )}
          </div>
          {text && <div className="body-lg text-essential-secondary">{text}</div>}
          {children && <div>{children}</div>}
          {actionButtons && <div className="flex justify-end space-x-3 pt-3">{actionButtons}</div>}
        </div>
      </div>
    </ReactModal>
  );
};

export default Modal;
