import { Preloader } from 'components/patterns/Loader';
import Button, { Color, Size } from 'lib/Button';
import downloadSvg from 'assets/icons/download.svg';
import { AllocationReportProps, ReportType } from './AllocationReport.types';
import { AllocationReportModal } from './AllocationReportModal/AllocationReportModal';
import { DropdownDealLine } from './Filters/DropdownDealLine';
import { DropdownMetric } from './Filters/DropdownMetric';
import { useAllocationReport } from './useAllocationReport';
import { AllocationReportTable } from './Table/AllocationReportTable';
import { ReportTypeButtons } from './ReportTypeButtons/ReportTypeButtons';
import { DropdownGroupBy } from './Filters/DropdownGroupBy';

const AllocationReport: React.FC<AllocationReportProps> = ({ defaultReportType = ReportType.FINAL, onClose }) => {
  const {
    activeReportType,
    allocationReportTableParams: { gridOptions, onGridReady, rowData },
    dealLines,
    handleChangeLine,
    handleChangeMetricType,
    handleExportReport,
    handleFetchAllocationReport,
    isLoading,
    isSelectedLineWithNewProposal,
    selectedLine,
    selectedMetricType,
    selectedRowGroupColumns,
    handleToggleRowGroupColumn,
  } = useAllocationReport({
    defaultReportType,
  });

  return (
    <AllocationReportModal onClose={onClose}>
      <div className="h-full flex flex-col overflow-hidden">
        <header className="flex flex-col gap-3 p-4 border-b border-neutral-950-opacity-10 mb-2">
          <ReportTypeButtons
            activeReportType={activeReportType}
            handleFetchAllocationReport={handleFetchAllocationReport}
            isSelectedLineWithNewProposal={isSelectedLineWithNewProposal}
            selectedLine={selectedLine}
          />
          <div className="flex items-end gap-3">
            <DropdownDealLine dealLines={dealLines} handleChangeLine={handleChangeLine} selectedLine={selectedLine} />
            <DropdownGroupBy
              handleToggleRowGroupColumn={handleToggleRowGroupColumn}
              selectedRowGroupColumns={selectedRowGroupColumns}
            />
            <DropdownMetric handleChangeMetricType={handleChangeMetricType} selectedMetricType={selectedMetricType} />
            <Button
              classNames="ml-auto"
              svg={downloadSvg}
              label="Export report"
              size={Size.LARGE}
              color={Color.TRANSPARENT}
              onClick={handleExportReport}
              ddActionName="Allocation report / Export button"
            />
          </div>
        </header>
        {isLoading ? (
          <Preloader isLoading />
        ) : (
          <AllocationReportTable gridOptions={gridOptions} onGridReady={onGridReady} rowData={rowData} />
        )}
      </div>
    </AllocationReportModal>
  );
};

export default AllocationReport;
