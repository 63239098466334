/* eslint-disable react/jsx-props-no-spreading */

import SVG from 'react-inlinesvg';
import cx from 'classnames';
import { Label } from 'lib/Label';
import { InputShape, InputSize, InputTheme, InputType } from 'components/common/types/Input.types';
import { InputAlign, BaseInputProps, InputBorderStyleProps, InputStyleProps } from './BaseInput.types';

import './BaseInput.pcss';

const CommonInputStyle =
  'truncate flex w-full outline-none appearance-none input-textfield disabled:cursor-not-allowed focus:outline-none focus:border-none';

const IconStyles: {
  size: Record<InputSize, string>;
  theme: Record<InputTheme, string>;
} = {
  size: {
    [InputSize.LARGE]: 'ml-3 mr-1',
    [InputSize.MEDIUM]: 'ml-3 mr-1',
    [InputSize.SMALL]: 'ml-3 mr-1',
    [InputSize.EXTRA_SMALL]: 'ml-[8px] w-4 h-4 leading-4 body-sm',
  },
  theme: {
    [InputTheme.DARK]: 'text-essential-primary',
    [InputTheme.LIGHT]: 'text-essential-primary',
    [InputTheme.FLAT_GRAY]:
      'text-essential-secondary group-hover:text-essential-primary group-focus-within:text-essential-primary group-disabled:text-neutral-400',
    [InputTheme.ITALIC_WHITE]: 'text-transparent group-hover:text-primary-600 group-focus-within:text-primary-600',
  },
};

const InputStyles: {
  size: Record<InputSize, string>;
  shape: Record<InputShape, string>;
  theme: Record<InputTheme, string>;
  border: Record<InputTheme, { default: string; noErrorAndNotDisabled: string }>;
} = {
  size: {
    [InputSize.LARGE]: 'px-4 py-3 body-base placeholder:body-base',
    [InputSize.MEDIUM]: 'px-4 py-2 body-base placeholder:body-base',
    [InputSize.SMALL]: 'px-4 py-1 body-base placeholder:body-base',
    [InputSize.EXTRA_SMALL]: 'px-2 py-0.5 body-sm placeholder:body-sm',
  },
  shape: {
    [InputShape.SQUARE]: 'rounded-none',
    [InputShape.ROUNDED]: 'rounded',
    [InputShape.ROUNDED_MEDIUM]: 'rounded-md',
    [InputShape.CIRCLE]: 'rounded-full',
  },
  theme: {
    [InputTheme.DARK]:
      'bg-transparent text-essential-primary placeholder:text-neutral-600 disabled:placeholder:text-neutral-950-opacity-30 placeholder:font-normal disabled:text-neutral-950-opacity-30',
    [InputTheme.LIGHT]: cx(
      'bg-transparent text-essential-primary disabled:text-neutral-950-opacity-30',
      'placeholder:text-neutral-600 disabled:placeholder:text-neutral-950-opacity-30',
    ),
    [InputTheme.FLAT_GRAY]:
      'bg-transparent text-essential-primary placeholder:text-essential-tertiary group-hover:enabled:text-essential-primary group-focus-within:text-essential-primary group-disabled:bg-neutral-200 group-disabled:placeholder:text-neutral-400',
    [InputTheme.ITALIC_WHITE]:
      'bg-transparent text-essential-primary placeholder:text-essential-tertiary placeholder:italic placeholder:header-base header-base',
  },
  border: {
    [InputTheme.DARK]: {
      default:
        'border border-neutral-950-opacity-10 shadow-inner-sm focus-within:ring-2 focus-within:border-1 focus-within:outline-none',
      noErrorAndNotDisabled: 'hover:bg-neutral-100',
    },
    [InputTheme.LIGHT]: {
      default:
        'border border-neutral-950-opacity-10 shadow-inner-sm focus-within:ring-2 focus-within:border-1 focus-within:outline-none',
      noErrorAndNotDisabled: 'hover:bg-neutral-100',
    },
    [InputTheme.FLAT_GRAY]: {
      default: 'border-none focus-within:ring-2 bg-neutral-100',
      noErrorAndNotDisabled:
        'hover:bg-primary-600-opacity-5 focus-within:hover:bg-primary-600-opacity-10 focus-within:bg-primary-600-opacity-10',
    },
    [InputTheme.ITALIC_WHITE]: {
      default: 'border-none focus-within:ring-2 bg-transparent',
      noErrorAndNotDisabled:
        'hover:bg-primary-600-opacity-5 focus-within:hover:bg-primary-600-opacity-10 focus-within:bg-primary-600-opacity-10',
    },
  },
};

export const getInputBorderStyle = ({
  errorMessage = '',
  classNames = '',
  shape = InputShape.ROUNDED_MEDIUM,
  theme = InputTheme.LIGHT,
  isDisabled = false,
}: InputBorderStyleProps = {}): string =>
  cx(
    'relative group w-full flex align-items-center',
    InputStyles.border[theme].default,
    InputStyles.shape[shape],
    classNames,
    {
      [cx(
        InputStyles.border[theme].noErrorAndNotDisabled,
        'focus-within:border-primary-600 focus-within:ring-primary-600',
      )]: !errorMessage && !isDisabled,
      'border-pinkRed-200 ring-pinkRed-100 hover:bg-pinkRed-50 focus-within:border-pinkRed-200 focus-within:ring-pinkRed-400':
        errorMessage && !isDisabled,
      'bg-neutral-950-opacity-5 cursor-not-allowed': isDisabled && !errorMessage,
    },
  );

export const getInputStyle = ({
  size = InputSize.MEDIUM,
  theme = InputTheme.LIGHT,
  classNames = '',
  align = InputAlign.LEFT,
  shape = InputShape.ROUNDED_MEDIUM,
}: InputStyleProps = {}): string =>
  cx(CommonInputStyle, InputStyles.size[size], InputStyles.theme[theme], InputStyles.shape[shape], classNames, {
    'text-right': align === InputAlign.RIGHT,
  });

export const BaseInput: React.FC<BaseInputProps> = ({
  id,
  dataTestId,
  label,
  name,
  value,
  placeholder,
  onChange,
  onBlur,
  isDisabled,
  isRequired,
  infoMessage,
  errorMessage,
  textSymbol,
  startIconSvg,
  endIconSvg,
  endComponent,
  renderEndComponent,
  align = InputAlign.LEFT,
  type = InputType.TEXT,
  size = InputSize.MEDIUM,
  iconSize,
  shape = InputShape.ROUNDED_MEDIUM,
  theme = InputTheme.LIGHT,
  className,
  dataPendoId,
  ...htmlInputProps
}) => {
  return (
    <div className="relative">
      {label && <Label id={id} label={label} isRequired={isRequired} />}
      <div
        className={getInputBorderStyle({
          isDisabled,
          errorMessage,
          shape,
          theme,
        })}
      >
        {startIconSvg && (
          <div data-test-id={`${id}-icon`}>
            <SVG src={startIconSvg} className={cx(IconStyles.size[iconSize ?? size], IconStyles.theme[theme])} />
          </div>
        )}
        {textSymbol && align === InputAlign.LEFT && (
          <span
            data-test-id={`${id}-text-symbol`}
            className={cx(IconStyles.size[iconSize ?? size], IconStyles.theme[theme])}
          >
            {textSymbol}
          </span>
        )}
        <input
          className={cx(
            getInputStyle({
              size,
              theme,
              align,
              shape,
            }),
            className,
          )}
          data-test-id={dataTestId}
          data-pendo-id={dataPendoId}
          id={id}
          type={type}
          name={name}
          aria-label={name ?? label ?? id}
          value={value}
          disabled={isDisabled}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          onReset={onChange}
          {...htmlInputProps}
        />
        {textSymbol && align === InputAlign.RIGHT && (
          <span
            data-test-id={`${id}-text-symbol`}
            className={cx('flex items-center mr-3 body-base', IconStyles.theme[theme])}
          >
            {textSymbol}
          </span>
        )}
        <span
          aria-disabled={isDisabled}
          className={cx({
            'relative flex align-items-center mr-2 ml-1 space-x-3 bg-transparent h-full':
              !!endComponent || !!endIconSvg,
          })}
        >
          {renderEndComponent?.({ isDisabled }) ?? endComponent}
          {endIconSvg && (
            <SVG src={endIconSvg} className={cx(IconStyles.size[iconSize ?? size], IconStyles.theme[theme])} />
          )}
        </span>
      </div>
      {infoMessage && (
        <span data-test-id={`${dataTestId}-info-message`} className="text-essential-tertiary block text-sm pt-2 pl-0.5">
          &#9432; {infoMessage}
        </span>
      )}
      {errorMessage && (
        <span data-test-id={`${dataTestId}-error-message`} className="text-danger block text-sm pt-2 pl-0.5">
          &#9432; {errorMessage}
        </span>
      )}
    </div>
  );
};

/* eslint-enable react/jsx-props-no-spreading */
