import { CodeNameType } from 'components/common/types/AssetList.types';
import AsyncSelect from 'components/patterns/AsyncSelect';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { getNetworks } from 'modules/api/inventory';
import { formatFilterOptionsName } from 'utils/formatters';
import { FilterNetworkProps } from './FilterNetwork.types';

const FilterNetwork: React.FC<FilterNetworkProps> = ({ changeFilters, network }) => {
  const cancelFunctions = useCancelRequest();

  const fetchFilterOptions = async (query: string): Promise<CodeNameType[]> => {
    if (query.length >= 3) {
      try {
        return await getNetworks(cancelFunctions, query);
      } catch (error) {
        return [];
      }
    }
    return [];
  };

  return (
    <AsyncSelect
      dataTestId="network-input"
      name="network"
      loadData={async (_name: string, query: string) => formatFilterOptionsName(await fetchFilterOptions(query))}
      onSelect={(_name: string, value: CodeNameType) => {
        changeFilters({ network: value });
      }}
      placeholder="Network"
      selectedValue={network}
    />
  );
};

export default FilterNetwork;
