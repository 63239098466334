import { usePageFilters } from 'customHooks/usePageFilters';
import AssetTable from './AssetTable';
import Filters from './Filters';

const AssetContent: React.FC = () => {
  const { filters } = usePageFilters();

  return (
    <>
      <Filters />
      <AssetTable filters={filters} />
    </>
  );
};

export default AssetContent;
