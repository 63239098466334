import { PatternOption } from 'components/common/DaypartTargeting/DaypartTargeting.types';
import { differenceInDays } from 'date-fns';

export const transformPatternRepeatTypeResponse = (
  rangePatternLength: number,
  startDate: string,
  endDate: string,
): PatternOption => {
  if (rangePatternLength > 2) {
    return PatternOption.NO_REPEAT;
  }

  const start = new Date(startDate);
  const end = new Date(endDate);

  const daysDifference = differenceInDays(end, start);

  if (rangePatternLength === 2 && daysDifference > 14) {
    return PatternOption.REPEAT_TWO_WEEKS;
  }

  if (rangePatternLength === 1 && daysDifference > 7) {
    return PatternOption.REPEAT_ONE_WEEK;
  }

  return PatternOption.NO_REPEAT;
};
