import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SVG from 'react-inlinesvg';
import PropTypes from 'prop-types';

import { DATE_FORMAT } from 'modules/api/constants';
import { NOTIFICATION_TIMEOUT } from 'consts/notifications';
import { getDaysDiff } from 'utils/dateFormatUtil';
import warningSvg from 'assets/icons/warning.svg';

import { notifyError } from 'store/notification/reducer';
import { changeDealCurrentLineData } from 'store/dealManagement/reducer';

import { DealType, DealStatus } from 'components/common/types/Deal.types';
import DayPicker from 'components/patterns/DayPicker';
import { hasLineStarted, hasLineEnded } from 'components/pages/DealWithLines/common/getActionConditions';
import FieldWithInfoMessage from 'components/patterns/FieldWithInfoMessage';
import { useDealFormReadOnly } from 'customHooks/useDealFormReadOnly';

const DateSelector = ({ handlePatternInfoMessage, isPatternInfoMessage }) => {
  const dispatch = useDispatch();
  const lines = useSelector((state) => state.dealManagement.backupFormData.lines);
  const bookingStatusCode = useSelector((state) => state.dealManagement.commonDeal.bookingStatusCode);
  const dealType = useSelector((state) => state.dealManagement.commonDeal.dealType);
  const currentLine = useSelector((state) => state.dealManagement.commonDeal.currentLine);
  const startDate = useSelector((state) => state.dealManagement.commonDeal.currentLine.startDate);
  const endDate = useSelector((state) => state.dealManagement.commonDeal.currentLine.endDate);

  const [disabledEndDates, setDisabledEndDates] = useState(new Date());
  const startDisabledDays = [
    {
      before: new Date(),
    },
  ];
  const readOnly = useDealFormReadOnly();
  const isPendingReservation = bookingStatusCode === DealStatus.PENDING_RESERVATION;
  const isDealLive = bookingStatusCode === DealStatus.LIVE;
  const isDealPendingOrRejected = [DealStatus.PENDING_APPROVAL, DealStatus.REJECTED].includes(bookingStatusCode);
  const backupLine = lines.find(({ id }) => id === currentLine.id);

  useEffect(() => {
    if (startDate > endDate) {
      dispatch(changeDealCurrentLineData({ endDate: startDate }));
    }
    setDisabledEndDates(startDate);
  }, [startDate, endDate]);

  useEffect(() => {
    if (dealType === DealType.GUARANTEED && getDaysDiff(startDate, endDate) > 180) {
      dispatch(changeDealCurrentLineData({ endDate: startDate }));
    }
  }, [dealType]);

  const getWarningMessage = () => {
    if (!isDealPendingOrRejected) return '';

    if (hasLineEnded(currentLine))
      return 'The end date of this line has passed. Please revise your end date and save your line.';

    if (hasLineStarted(currentLine))
      return 'The start date of this line has passed, which means your line will go live immediately. If you wish to change this, please update your start date.';

    return '';
  };

  const changeEndDate = (date) => {
    if (dealType !== DealType.GUARANTEED || getDaysDiff(startDate, date) <= 180) {
      dispatch(changeDealCurrentLineData({ endDate: date }));

      if (date > endDate && !isPatternInfoMessage) {
        handlePatternInfoMessage();
      }

      return;
    }

    dispatch(changeDealCurrentLineData({ endDate }));
    dispatch(
      notifyError({
        message:
          'The maximum duration for a guaranteed line is 6 months. Please revise your end date, or create another line.',
        timeout: NOTIFICATION_TIMEOUT.NEVER,
      }),
    );
  };

  return (
    <div className="flex flex-row space-x-5 flex-shrink-0">
      <div>
        <label htmlFor="schedule-start-date" className="sub-header-base text-neutral-950-opacity-60 mb-2">
          Start date
        </label>
        <DayPicker
          inputId="schedule-start-date"
          format={DATE_FORMAT}
          disabledDays={startDisabledDays}
          onDayChange={(newStartDate) => dispatch(changeDealCurrentLineData({ startDate: newStartDate }))}
          selectedDay={startDate}
          fromMonth={startDisabledDays.before}
          isDisabled={readOnly || (isDealLive && hasLineStarted(backupLine)) || isPendingReservation}
        />
      </div>
      <div className="pr-4">
        <label htmlFor="schedule-end-date" className="sub-header-base text-neutral-950-opacity-60 mb-2">
          End date
        </label>
        <FieldWithInfoMessage
          field={
            <DayPicker
              inputId="schedule-end-date"
              format={DATE_FORMAT}
              disabledDays={[
                {
                  before: disabledEndDates,
                },
              ]}
              onDayChange={changeEndDate}
              selectedDay={endDate}
              fromMonth={disabledEndDates}
              isDisabled={readOnly || isPendingReservation}
            />
          }
          icon={<SVG src={warningSvg} className="fill-current text-yellow" />}
          message={getWarningMessage()}
        />
      </div>
    </div>
  );
};

DateSelector.propTypes = {
  handlePatternInfoMessage: PropTypes.func,
  isPatternInfoMessage: PropTypes.bool,
};

DateSelector.defaultProps = {
  handlePatternInfoMessage: () => {},
  isPatternInfoMessage: false,
};

export default DateSelector;
