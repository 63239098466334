import { useDispatch, useSelector } from 'react-redux';
import { Label } from 'lib/Label';
import BaseSelect from 'lib/BaseSelect';
import {
  DealPriority as DealPriorityType,
  DealPriorityLabel,
  DealType,
  DealStatus,
} from 'components/common/types/Deal.types';
import { InputShape, InputSize, InputTheme } from 'components/common/types/Input.types';
import { Store } from 'components/common/types/Store.types';
import { codeNameDealPriorityOptions } from 'consts/dealPriority';
import { Padding } from 'lib/BaseSelect/BaseSelect.types';
import { ShadowStyle } from 'consts/shadow';
import { changeDealData } from 'store/dealManagement/reducer';
import { CodeNameModel } from 'components/common/types';
import { getIsReseller } from 'store/publisher/selectors';
import { isReadOnly } from 'utils/isReadOnly';

const DealPriority: React.FC = () => {
  const dispatch = useDispatch();

  const dealPriority = useSelector((state: Store) => state.dealManagement.commonDeal.dealPriority);
  const isEditingDisabled = useSelector((state: Store) => state.dealManagement.isEditingDisabled);
  const bookingStatusCode = useSelector((state: Store) => state.dealManagement.commonDeal.bookingStatusCode);
  const dealType = useSelector((state: Store) => state.dealManagement.commonDeal.dealType);
  const isReseller = useSelector(getIsReseller);

  const isDisabled =
    (isReadOnly(bookingStatusCode, isEditingDisabled) && bookingStatusCode !== DealStatus.PENDING_RESERVATION) ||
    isReseller;

  const handleSelect = (_name: string, value: CodeNameModel): void => {
    dispatch(changeDealData({ dealPriority: value.code as DealPriorityType }));
  };

  if (dealType === DealType.GUARANTEED) return null;
  return (
    <>
      <Label id="priority" theme={InputTheme.FLAT_GRAY} label="Priority" />
      <BaseSelect
        name="Priority"
        options={codeNameDealPriorityOptions}
        selectedValue={{
          code: dealPriority,
          name: DealPriorityLabel[dealPriority],
        }}
        onSelect={handleSelect}
        isDisabled={isDisabled}
        theme={InputTheme.FLAT_GRAY}
        size={InputSize.EXTRA_SMALL}
        padding={Padding.NONE}
        shadow={ShadowStyle.NONE}
        shape={InputShape.ROUNDED}
        dataTestId="priority-select"
      />
    </>
  );
};

export default DealPriority;
