import { Component } from 'react';
import PropTypes from 'prop-types';

import BaseSelect from 'lib/BaseSelect';
import { findOption } from 'lib/BaseSelect/utils';

class ActionRenderer extends Component {
  state = {
    dropdownOptions: [
      {
        code: 'inclusion',
        name: 'Include',
      },
      {
        code: 'exclusion',
        name: 'Exclude',
      },
    ],
  };

  onDropdownSelect = (_dropdownName, selectedObj) => {
    const { onTypeChange, data } = this.props;

    onTypeChange({ ...data, type: selectedObj?.code ?? '' });
  };

  render() {
    const { data } = this.props;
    const { dropdownOptions } = this.state;

    return (
      <div className="w-[130px] p-1">
        <BaseSelect
          name="type"
          options={dropdownOptions}
          selectedValue={findOption(dropdownOptions, 'code', data.type)}
          onSelect={this.onDropdownSelect}
          placeholder="Set rule"
        />
      </div>
    );
  }
}

ActionRenderer.propTypes = {
  data: PropTypes.shape({
    type: PropTypes.string,
  }).isRequired,
  onTypeChange: PropTypes.func,
};

ActionRenderer.defaultProps = {
  onTypeChange: () => undefined,
};

export default ActionRenderer;
