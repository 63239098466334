import React from 'react';
import CampaignTypeSwitch from 'components/pages/Planner/PlannerDealOverlay/CampaignTypeSwitch';
import { IconTradingType } from '../IconTradingType/IconTradingType';

export const SelectTradingType: React.FC = () => {
  return (
    <div className="w-[340px] flex gap-2 pl-[16px] items-center">
      <IconTradingType />
      <h2 data-test-id="deal-header-deal-name" className="sub-header-base w-full truncate text-essential-primary">
        Programmatic
      </h2>
      <div className="absolute !h-0 left-32 top-2 scale-50">
        <CampaignTypeSwitch />
      </div>
    </div>
  );
};
