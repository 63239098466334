export const transformDealIds = (dealIds) => dealIds.map((d) => ({ code: d.dealId, name: d.dealId }));

export const transformToCodeName = (data) => data.map((d) => ({ code: d, name: d }));

export const transformCreativeFrameTags = (data) =>
  data.map((d) => ({ ...d, compositeName: `${d.group.name} - ${d.name} (${d.frameCount} linked frames)` }));

export const transformTagFrameDetails = (data) =>
  data.map((d) => ({
    'Frame code': d.code,
    'Post code': d.postCode,
    'Lat/Lon': `${d.latitude}/${d.longitude}`,
    'Product format': d.productFormat?.name,
    Environment: d.environment,
  }));

export const transformSalesPerson = (salesPerson) =>
  salesPerson.map(({ name, person_uuid, person_type }) => ({ code: person_uuid, name, category: person_type }));

export const transformSalesTeam = (salesTeam) =>
  salesTeam.map(({ name, sales_team_uuid }) => ({ code: sales_team_uuid, name }));
