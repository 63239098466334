import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SVG from 'react-inlinesvg';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';

import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';
import Tooltip, { TooltipDirection, TooltipTheme } from 'components/patterns/Tooltip';

import { DealItemData } from 'components/common/types/DealItem';
import { FeatureFlags } from 'components/common/types/Features.types';
import { DealStatus, FrontEndType } from 'components/common/types/Deal.types';

import editSvg from 'assets/icons/edit.svg';
import copySvg from 'assets/icons/copy.svg';

import { getIsAdServer } from 'store/publisher/selectors';
import { useDuplicateDeal } from 'customHooks/useDuplicateDeal';

type ActionRendererProps = {
  data?: DealItemData;
};

const ActionRenderer: React.FC<ActionRendererProps> = ({ data = {} }) => {
  const hasPlannerNativeEnabled = useHasFeatureAccess(FeatureFlags.PLANNER_NATIVE);
  const isAdServerMarket = useSelector(getIsAdServer);
  const navigate = useNavigate();
  const { handleDuplicateDeal } = useDuplicateDeal();

  const { internalId, frontEndType, bookingStatusCode, dealId } = data;

  if (!internalId) return null;

  const onEditButtonClick = (): void => {
    if (hasPlannerNativeEnabled && frontEndType === FrontEndType.PLANNER) {
      navigate(`/planner/programmatic/${internalId}`);
      return;
    }

    navigate(`/deal/${internalId}`);
  };

  const onDuplicateButtonClick = (): void => {
    handleDuplicateDeal(internalId);
  };

  return (
    <span className="table-action-button">
      <Button
        dataTestId={`edit-${bookingStatusCode}`}
        btnShape={ButtonShape.CIRCLE}
        btnType={ButtonType.ICON}
        onClick={onEditButtonClick}
      >
        <SVG src={editSvg} />
      </Button>

      {bookingStatusCode !== DealStatus.FAILED && isAdServerMarket && (
        <Tooltip tooltip="Duplicate campaign" direction={TooltipDirection.RIGHT} theme={TooltipTheme.DARK}>
          <Button
            dataTestId={`copy-${dealId}`}
            btnShape={ButtonShape.CIRCLE}
            btnType={ButtonType.ICON}
            onClick={onDuplicateButtonClick}
          >
            <SVG src={copySvg} />
          </Button>
        </Tooltip>
      )}
    </span>
  );
};

export default ActionRenderer;
