export enum BarColor {
  SOFT_GRAY = 'bg-neutral',
  MAGENTA_RED = 'bg-[#c13584]',
  SECONDARY_BLUE = 'bg-sky-600',
  PRIMARY_PURPLE = 'bg-primary-600-opacity-80',
  LIGHT_PRIMARY_PURPLE = 'bg-primary-600-opacity-20',
  DEEP_RED = 'bg-pinkRed-900',
  YELLOW = 'bg-yellow-600',
  DARK_GREEN = 'bg-green-700',
}

export enum BarBackgroundColor {
  LIGHT_PRIMARY_PURPLE = 'bg-primary-600-opacity-20',
  LIGHT_GRAY = 'bg-primary-100',
}

export interface ProgressBarProps {
  barColor: BarColor;
  barBackgroundColor?: BarBackgroundColor;
  completed?: number;
  id: string;
  max?: number;
  indicatorPosition?: number;
  barLegend?: {
    barColorDescription: string;
    barBackgroundDescription: string;
    indicatorDescription?: string;
  };
}
