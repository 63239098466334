import SVG from 'react-inlinesvg';
import cx from 'classnames';
import copySvg from 'assets/icons/copy.svg';
import { copyToClipboard } from 'utils/copyToClipboard';

const PLACEHOLDER_CAMPAIGN_ID = 'Campaign ID';
const commonStyles = 'body-sm text-essential-tertiary';

type CampaignIdProps = {
  campaignId?: string;
};

const CampaignId: React.FC<CampaignIdProps> = ({ campaignId }) => {
  if (!campaignId) return <span className={commonStyles}>{PLACEHOLDER_CAMPAIGN_ID}</span>;

  return (
    <button
      className={cx(
        commonStyles,
        'group w-fit flex justify-center overflow-hidden items-center rounded hover:bg-primary-600-opacity-5',
      )}
      type="button"
      onClick={() => {
        copyToClipboard(campaignId);
      }}
    >
      <p className="px-0.5 overflow-hidden text-ellipsis">{campaignId}</p>
      <div className="p-1 invisible group-hover:visible text-primary-600 bg-primary-600-opacity-5 rounded">
        <SVG src={copySvg} className="w-4 h-4" />
      </div>
    </button>
  );
};

export default CampaignId;
