import { useSelector } from 'react-redux';
import SVG from 'react-inlinesvg';

import { formatDateTime, formatDateTimeNoUTC } from 'modules/I18N';
import checkmarkSvg from 'assets/icons/checkmark.svg';
import handSvg from 'assets/icons/hand.svg';
import flagSvg from 'assets/icons/flag.svg';
import hourglassSvg from 'assets/icons/hourglass.svg';
import binSvg from 'assets/icons/bin.svg';
import restoreSvg from 'assets/icons/restore.svg';

import { CreativeStatus, AuditDetails } from 'components/common/types/Creative.types';
import { Store } from 'components/common/types/Store.types';
import Card from 'components/patterns/Card';

interface AuditLogProps {
  combinedAuditDetails: AuditDetails[];
}

export const AuditLog: React.FC<AuditLogProps> = ({ combinedAuditDetails }) => {
  const localeCode = useSelector((state: Store) => state.publisher.configuration.localeCode);

  const getModerationStatusIcon = (status: CreativeStatus): React.ReactElement => {
    const modIcon = (color: string, icon: string): React.ReactElement => (
      <figure className={`${color} w-10 h-10 mr-2 rounded-full inline-block align-top relative`}>
        <SVG src={icon} className="fill-current text-neutral-50 absolute top-2.5 right-2.5" />
      </figure>
    );

    const map: Partial<{ [key in CreativeStatus]: React.ReactElement }> = {
      [CreativeStatus.APPROVED]: modIcon('bg-green', checkmarkSvg),
      [CreativeStatus.REJECTED]: modIcon('bg-pinkRed', handSvg),
      [CreativeStatus.FLAGGED]: modIcon('bg-yellow', flagSvg),
      [CreativeStatus.PENDING]: modIcon('bg-neutral-600', hourglassSvg),
      [CreativeStatus.SENT_FOR_APPROVAL]: modIcon('bg-neutral-600', hourglassSvg),
      [CreativeStatus.DELETED]: modIcon('bg-pinkRed', binSvg),
      [CreativeStatus.RESTORED]: modIcon('bg-green', restoreSvg),
    };

    return map[status] as React.ReactElement;
  };

  const getModerationStatusHTML = (status: CreativeStatus, updatedBy: { code: string }): React.ReactElement => {
    const modStatus = (textClass: string, statusText: string): React.ReactElement => (
      <p>
        <span className={`sub-header-base ${textClass}`}>{statusText}</span>
        <span className="body-base text-secondary"> by </span>
        <span className="body-base">{updatedBy.code}</span>
      </p>
    );

    const map: Partial<{ [key in CreativeStatus]: React.ReactElement }> = {
      [CreativeStatus.APPROVED]: modStatus('text-success', 'Approved'),
      [CreativeStatus.REJECTED]: modStatus('text-danger', 'Rejected'),
      [CreativeStatus.FLAGGED]: modStatus('text-warning', 'Flagged'),
      [CreativeStatus.PENDING]: modStatus('text-secondary', 'Pending approval'),
      [CreativeStatus.SENT_FOR_APPROVAL]: modStatus('text-secondary', 'Sent for approval'),
      [CreativeStatus.DELETED]: modStatus('text-secondary', 'Deleted'),
      [CreativeStatus.RESTORED]: modStatus('text-secondary', 'Restored'),
    };

    return map[status] as React.ReactElement;
  };

  return (
    <Card header="Audit log">
      <ul className="list-none w-full m-0 p-0" data-test-id="moderation-log">
        {combinedAuditDetails?.map((mod) => {
          return (
            <li key={mod.updateStamp} className="w-full list-none py-2.5">
              {getModerationStatusIcon(mod.status ?? mod.type)}
              <div className="max-w-[70%] inline-block align-middle">
                {getModerationStatusHTML(mod.status ?? mod.type, mod.updatedBy ?? mod.user)}
                {mod.comment && (
                  <p className="body-base text-secondary mt-0 mb-1.5">
                    <i>{mod.comment}</i>{' '}
                  </p>
                )}
                {mod.updateStamp ? (
                  <p className="body-sm text-secondary">{formatDateTimeNoUTC(mod.updateStamp, localeCode)}</p>
                ) : (
                  <p className="body-sm text-secondary">
                    {formatDateTime({
                      dateStyle: 'short',
                      isoDateString: mod.createdAt,
                      localeCode,
                    })}
                  </p>
                )}
              </div>
            </li>
          );
        })}
      </ul>
    </Card>
  );
};
