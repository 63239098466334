/* eslint-disable sonarjs/cognitive-complexity */

import { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import Auth from 'modules/Auth';
import { PermissionsEnum } from 'consts/permissions';
import { CREATIVE_STATUS_BADGE } from 'consts/creative';
import { usePageFilters } from 'customHooks/usePageFilters';
import { CreativeType, CreativeStatus, CreativesFilters } from 'components/common/types/Creative.types';
import withCancelRequest from 'components/hocs/withCancelRequest';
import { getLookupData } from 'modules/api/lookups';
import MultipleSwitchBox from 'components/patterns/MultipleSwitchBox';
import FiltersCard from 'components/patterns/FiltersCard';
import { CodeNameModel } from 'components/common/types';
import { useSelector } from 'react-redux';
import { getIsReseller } from 'store/publisher/selectors';
import FilterStatus from 'components/common/FilterArea/Filters/FilterStatus';
import { FilterCreativeId, FilterCreativeType, FilterDsp } from 'components/common/FilterArea/Filters';
import AdvancedFilters from './AdvancedFilters';
import { FilterAreaProps, FilterAreaType } from './Filters.types';

const creativeTypeData = [
  {
    code: '',
    name: 'Select creative type',
  },
  {
    code: CreativeType.VIDEO,
    name: 'Video',
  },
  {
    code: CreativeType.BANNER,
    name: 'Banner',
  },
  {
    code: CreativeType.DYNAMIC,
    name: 'Dynamic',
  },
];

const cx = classNames.bind(null);

const Filters: React.FC<FilterAreaProps> = ({ cancelFunctions }) => {
  const isReseller = useSelector(getIsReseller);
  const {
    filters: filterData,
    filters: { startDate, endDate, status, dsp, deleted, creativeType, externalId },
    changeFilters: setCreativeFilters,
    resetFilters: resetCreativeFilters,
  } = usePageFilters<CreativesFilters>();

  const [productFormatOptions, setProductFormatOptions] = useState([]);

  const moderationFilter = CREATIVE_STATUS_BADGE.filter(
    (item) =>
      item.code !== CreativeStatus.UPLOADED &&
      item.code !== CreativeStatus.DELETED &&
      item.code !== CreativeStatus.RESTORED,
  );

  const onLoadLookupData = async (dropdownName: string, query: CodeNameModel | string): Promise<[]> => {
    try {
      return await getLookupData({ cancelFunctions, lookupURLName: dropdownName, query });
    } catch {
      return [];
    }
  };

  const getProductFormatOptions = async (): Promise<void> => {
    try {
      const response = await onLoadLookupData('productFormat', '');
      if (Array.isArray(response) && response.length > 0) {
        setProductFormatOptions(response);
      }
      // eslint-disable-next-line no-empty
    } catch {}
  };

  useEffect(() => {
    !isReseller && getProductFormatOptions();

    setCreativeFilters({ deleted: false });
  }, []);

  const onDropdownChange = (value: FilterAreaType, fieldName: string): void => {
    const getDateFilters = {
      ...filterData,
      startDate: fieldName === 'status' && !value ? null : startDate,
      endDate: fieldName === 'status' && !value ? null : endDate,
    };

    if (fieldName === 'startDate' && endDate && value > endDate) {
      getDateFilters.endDate = null;
    }

    setCreativeFilters({ ...getDateFilters, [fieldName]: value });
  };

  const onShowDeleted = (isDeleted: boolean): void =>
    setCreativeFilters({ ...filterData, deleted: isDeleted, status: isDeleted ? '' : filterData.status });

  return (
    <>
      {Auth.hasPermission(PermissionsEnum.CONTENT_SEARCH) ? (
        <FiltersCard
          containerClassName="flex flex-wrap gap-4 w-full"
          advancedFilters={
            <AdvancedFilters
              onChange={onDropdownChange}
              advancedFilterData={filterData}
              productFormatOptions={productFormatOptions}
              onClearAll={resetCreativeFilters}
              changeFilters={setCreativeFilters}
            />
          }
        >
          <div className="contents">
            <div className="flex-initial mr-4">
              <MultipleSwitchBox
                source={[
                  { label: 'Library', value: false },
                  { label: 'Deleted', value: true },
                ]}
                selectedValue={!!deleted}
                textKey="label"
                valueKey="value"
                onChange={onShowDeleted}
              />
            </div>
            {!deleted && (
              <div className="flex-grow flex-shrink-0 min-w-62.5 max-w-100">
                <FilterStatus changeFilters={setCreativeFilters} statusesList={moderationFilter} status={status} />
              </div>
            )}
            <div className="flex-grow flex-shrink-0 min-w-62.5 max-w-100">
              <FilterDsp onChange={(name, value) => setCreativeFilters({ [name]: value })} selectedValue={dsp} />
            </div>
            <div className="flex-grow flex-shrink-0 min-w-62.5 max-w-100">
              <div className="flex-grow flex-shrink-0 min-w-62.5 max-w-100">
                <FilterCreativeId
                  changeFilters={(newFilter) => setCreativeFilters({ ...filterData, externalId: newFilter.creative })}
                  creativeId={externalId}
                />
              </div>
            </div>
            <div
              className={cx('flex-grow flex-shrink-0 min-w-62.5', {
                'max-w-88 xl:max-w-100': deleted,
                'xl:max-w-100': !deleted,
              })}
            >
              <FilterCreativeType
                options={creativeTypeData}
                selectedValue={creativeTypeData.find((item) => item.code === creativeType)}
                onSelect={(_name, value: CodeNameModel) => {
                  onDropdownChange(value.code, 'creativeType');
                }}
              />
            </div>
          </div>
        </FiltersCard>
      ) : null}
    </>
  );
};
export default withCancelRequest(Filters);

/* eslint-enable sonarjs/cognitive-complexity */
