import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import Panel from 'components/common/Deal/Panel';
import DealWithRightSidebar from 'components/common/layout/DealWithRightSidebar';
import { FeatureFlags } from 'components/common/types/Features.types';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import { getDealConfig, getMetaData } from 'modules/api/deal';
import { updateDealConfig } from 'store/dealConfig/reducer';
import { getDealDetails } from 'store/dealManagement/actions';
import { changeMeta } from 'store/dealManagement/reducer';
import { getIsAdServer } from 'store/publisher/selectors';
import CampaignHeader from 'components/common/Deal/CampaignHeader/CampaignHeader';
import RightSidebar from '../common/RightSidebar';
import Main from './Main';

const DealLines = () => {
  const { internalId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const hasAdsDealLevelCPMEnabled = useHasFeatureAccess(FeatureFlags.ADS_DEAL_LEVEL_CPM);
  const hasNonGuaranteedExtendedTargetEnabled = useHasFeatureAccess(FeatureFlags.NON_GUARANTEED_EXTENDED_TARGET);
  const isAdServerMarket = useSelector(getIsAdServer);
  const cancelFunctions = useCancelRequest();
  const dealConfig = useSelector((state) => state.dealConfig);

  useEffect(() => {
    const getMeta = async () => {
      try {
        const metaData = await getMetaData({ cancelFunctions });
        dispatch(changeMeta(metaData));
      } catch {} // eslint-disable-line no-empty
    };

    getMeta();
  }, []);

  useEffect(() => {
    const getDealConfigData = async () => {
      try {
        const config = await getDealConfig(cancelFunctions);

        dispatch(updateDealConfig({ ...config, isAdServer: false }));
      } catch {
        // eslint-disable-next-line no-console
        console.error('Failed fetching deal config for the automation market');
      }
    };

    if (!dealConfig.isDealConfigFetched) {
      getDealConfigData();
    }
  }, [dealConfig.isDealConfigFetched]);

  useEffect(() => {
    if (internalId && dealConfig.isDealConfigFetched) {
      dispatch(
        getDealDetails(
          internalId,
          cancelFunctions,
          navigate,
          dealConfig,
          hasAdsDealLevelCPMEnabled,
          hasNonGuaranteedExtendedTargetEnabled,
          isAdServerMarket,
        ),
      );
    }
  }, [internalId, cancelFunctions, dealConfig.isDealConfigFetched]);

  return (
    <DealWithRightSidebar
      sidebar={<RightSidebar />}
      header={<CampaignHeader />}
      leftContent={<Panel />}
      rightContent={<Main />}
    />
  );
};

export default DealLines;
