import { Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { Store } from 'components/common/types/Store.types';
import { thoughtspot } from 'config';
import Overlay from 'lib/Overlay';
import LiveboardVisuals from 'components/patterns/LiveboardVisuals';
import { hideMarketFilterStyle, filterByMarket, labsReportVisibleActions } from '../utils';

interface YieldInsightsLiveboardProps {
  showLiveboard: boolean;
  setShowLiveboard: Dispatch<SetStateAction<boolean>>;
}

export const YieldInsightsLiveboard: React.FC<YieldInsightsLiveboardProps> = ({ showLiveboard, setShowLiveboard }) => {
  const { marketId } = useSelector((state: Store) => state.publisher.configuration);

  if (!showLiveboard) return null;
  return (
    <Overlay
      onClose={() => setShowLiveboard(false)}
      isOpen={showLiveboard}
      overflowYScroll
      header={
        <header>
          <h3 className="sub-header-lg text-neutral-900">Yield insights</h3>
        </header>
      }
    >
      <LiveboardVisuals
        liveboardId={thoughtspot.yieldInsightsLiveboardId}
        customizations={hideMarketFilterStyle}
        visibleActions={labsReportVisibleActions}
        filterByOptions={[filterByMarket(marketId)]}
      />
    </Overlay>
  );
};
