import { CodeType } from 'components/common/types/AssetList.types';
import AsyncSelect from 'components/patterns/AsyncSelect';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { getVisualUnitCodes } from 'modules/api/inventory';
import { FilterVisualUnitCodeAsyncProps } from './FilterVisualUnitCodeAsync.types';

const FilterVisualUnitCodeAsync: React.FC<FilterVisualUnitCodeAsyncProps> = ({ changeFilters, visualUnit }) => {
  const cancelFunctions = useCancelRequest();

  const fetchVisualUnits = async (_name: string, query: string): Promise<CodeType[]> => {
    try {
      return await getVisualUnitCodes(cancelFunctions, query);
    } catch {
      return [];
    }
  };

  return (
    <AsyncSelect
      dataTestId="visual-unit-code-input"
      name="visualUnit"
      textKey="code"
      loadData={fetchVisualUnits}
      onSelect={(_name: string, value: CodeType) => {
        changeFilters({ visualUnit: value });
      }}
      placeholder="Select visual unit code"
      selectedValue={visualUnit}
    />
  );
};

export default FilterVisualUnitCodeAsync;
