import * as lookups from 'modules/api/lookups';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { useSelector } from 'react-redux';
import { Store } from 'components/common/types/Store.types';

export const useLoadLookup = (
  minQueryLength: number = 1,
): {
  onLoadLookup: typeof onLoadLookup;
  onLoadBrands: typeof onLoadBrands;
  onLoadSalesPerson: typeof onLoadSalesPerson;
  onLoadSalesTeam: typeof onLoadSalesTeam;
} => {
  const advertiser = useSelector((state: Store) => state.dealManagement.commonDeal.advertiser);

  const cancelFunctions = useCancelRequest();

  const onLoadLookup = async (lookupName: string, query: string): Promise<[]> => {
    if (query.length <= minQueryLength) return [];
    try {
      return await lookups.getLookupData({ cancelFunctions, lookupURLName: lookupName, query });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Failed to fetch ${lookupName}:`, error);
      return [];
    }
  };

  const onLoadBrands = async (_name: string, query: string): Promise<[]> => {
    if (query.length <= minQueryLength) return [];

    try {
      return await lookups.getBrandLookupData(cancelFunctions, query, advertiser?.code);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to fetch brands:', error);
      return [];
    }
  };

  const onLoadSalesPerson = async (_dropdownName: string, query: string): Promise<[]> => {
    if (query.length <= minQueryLength) return [];

    try {
      return await lookups.getSalesPersonLookup(cancelFunctions, query);
    } catch {
      return [];
    }
  };

  const onLoadSalesTeam = async (_dropdownName: string, query: string): Promise<[]> => {
    if (query.length <= minQueryLength) return [];

    try {
      return await lookups.getSalesTeamLookup(cancelFunctions, query);
    } catch {
      return [];
    }
  };

  return { onLoadLookup, onLoadBrands, onLoadSalesPerson, onLoadSalesTeam };
};
