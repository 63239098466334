import { useState } from 'react';
import SVG from 'react-inlinesvg';
import { useDispatch, useSelector } from 'react-redux';

import AsyncSelect from 'components/patterns/AsyncSelect';
import {
  getActiveFrameTags,
  getCreativeMarketId,
  getIsInsideFrameTags,
} from 'components/pages/ContentManagement/EditCreatives/EditPanel/utils';
import { CreativeMetaOperation } from 'components/common/types/Creative.types';
import MultipleSwitchBox from 'components/patterns/MultipleSwitchBox';
import { FrameTag } from 'components/common/types/FrameTag.types';
import Button, { ButtonType, ButtonShape, ButtonSize } from 'components/patterns/Button';
import { TaggingRulesModal } from 'components/pages/ContentManagement/Preview/PreviewContent/TaggingRules/TaggingRulesModal/TaggingRulesModal';

import { Store } from 'components/common/types/Store.types';
import { getAllSelectedCreativesPendingStatus } from 'store/pages/contentManagement/creatives/selectors';
import { setCreativeDetails } from 'store/creative/reducer';
import { getCreativeFrameTags } from 'store/creative/selectors';
import {
  updateFrameTagsMode,
  updateNewFrameTags,
  updateIsUnsavedFrameTags,
} from 'store/pages/contentManagement/creativesAdditionalData/reducer';

import { getFrameTags } from 'modules/api/content';
import { useCancelRequest } from 'customHooks/useCancelRequest';

import plusSvg from 'assets/icons/plus.svg';
import crossSvg from 'assets/icons/close.svg';
import FrameTagCard from './FrameTagCard';

const FrameTags: React.FC = () => {
  const dispatch = useDispatch();
  const cancelFunctions = useCancelRequest();
  const areAllItemsPending = useSelector(getAllSelectedCreativesPendingStatus);
  const newFrameTags = useSelector(
    (state: Store) => state.pages.contentManagement.creativesAdditionalData.newFrameTags,
  );
  const frameTagsMode = useSelector(
    (state: Store) => state.pages.contentManagement.creativesAdditionalData.frameTagsMode,
  );
  const selectedCreatives = useSelector((state: Store) => state.pages.contentManagement.creatives.selectedCreatives);
  const marketId = getCreativeMarketId(selectedCreatives[0]);
  const frameTags = useSelector(getCreativeFrameTags);
  const [isFrameTaggingModalOpen, setIsFrameTaggingModalOpen] = useState(false);

  const onLoadFrameTagsLookupData = async (_dropdownName: string, query: string): Promise<FrameTag[]> => {
    if (query.length < 2) return [];

    try {
      return await getFrameTags(cancelFunctions, query, marketId);
    } catch (error) {
      return [];
    }
  };

  const onSelectFrameTag = async (_dropdownName: string, newValue: FrameTag): Promise<void> => {
    if (!newValue) return;

    const isInsideFrameTags = getIsInsideFrameTags(newFrameTags, newValue.id);
    if (!isInsideFrameTags) dispatch(updateNewFrameTags([...newFrameTags, { ...newValue, type: 'inclusion' }]));
  };

  const onModeChange = (mode: CreativeMetaOperation): void => {
    dispatch(updateFrameTagsMode(mode));

    const isUnsavedData = getActiveFrameTags(newFrameTags).length > 0;
    const isAddOperation = mode === CreativeMetaOperation.ADD;
    const isUnsavedOperationChange = !isAddOperation || (isAddOperation && isUnsavedData);
    dispatch(updateIsUnsavedFrameTags(isUnsavedOperationChange));
  };

  const onViewDetailsClick = (): void => {
    dispatch(
      setCreativeDetails({
        frameTags: newFrameTags,
      }),
    );
    setIsFrameTaggingModalOpen(true);
  };

  return (
    <>
      <div data-test-id="frame-tags" className="flex flex-col space-y-2">
        <AsyncSelect
          dataTestId="frame-tags-input"
          name="tags"
          loadData={onLoadFrameTagsLookupData}
          selectedValue={null}
          placeholder="Select a frame tag"
          onSelect={onSelectFrameTag}
          textKey="compositeName"
          isDisabled={!areAllItemsPending}
        />
        <div className="w-min flex justify-start">
          <MultipleSwitchBox
            source={[
              { code: CreativeMetaOperation.ADD, name: 'Add', icon: plusSvg },
              { code: CreativeMetaOperation.REPLACE, name: 'Replace', icon: crossSvg },
            ]}
            selectedValue={frameTagsMode}
            textKey="name"
            valueKey="code"
            onChange={onModeChange}
            isDisabled={!areAllItemsPending}
            dataTestId="frame-tags-switch"
            hideBorder
          />
          <div>
            {newFrameTags.map((tag) => (
              <FrameTagCard
                dataTestId={`frame-tags-card-${tag.id}`}
                key={tag.id}
                frameTag={tag}
                isDisabled={!areAllItemsPending}
              />
            ))}
          </div>
        </div>

        <Button
          btnShape={ButtonShape.ROUNDED}
          btnSize={ButtonSize.FULL_FLEX}
          btnType={ButtonType.TRANSPARENT_WITH_BORDER}
          onClick={onViewDetailsClick}
        >
          <div className="content-center w-full space-x-2">
            <SVG src={plusSvg} />
            <span>View details</span>
          </div>
        </Button>
      </div>

      <TaggingRulesModal
        isOpen={isFrameTaggingModalOpen}
        frameTags={frameTags}
        marketId={marketId}
        onClose={() => setIsFrameTaggingModalOpen(false)}
      />
    </>
  );
};

export default FrameTags;
