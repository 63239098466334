import { Routes, Route, Navigate } from 'react-router-dom';
import { PageWrapper } from 'components/common/layout/PageWrapper/PageWrapper';
import PrivateRoute from 'components/common/Auth/Private';
import { ChildRoute } from 'components/common/types/Route.types';
import withLoader from 'components/hocs/withLoader';
import InventoryManagementHeader from './InventoryManagementHeader';
import AssetList from './AssetList';
import ReferenceData from './ReferenceData';
import CommercialEntities from './CommercialEntities';
import Setup from './Setup';

const InventoryManagement: React.FC = () => {
  const childRoutes: ChildRoute[] = [
    {
      id: 1,
      path: 'asset-list',
      main: AssetList,
      pageAccessPermission: undefined,
    },
    {
      id: 2,
      path: 'reference-data/*',
      main: ReferenceData,
      pageAccessPermission: undefined,
    },
    {
      id: 3,
      path: 'commercial-entities',
      main: CommercialEntities,
      pageAccessPermission: undefined,
    },
    {
      id: 4,
      path: 'setup',
      main: Setup,
      pageAccessPermission: undefined,
    },
    {
      id: 5,
      path: '*',
      main: () => <Navigate to="asset-list" />,
      pageAccessPermission: undefined,
    },
  ];

  return (
    <PageWrapper>
      <InventoryManagementHeader />
      <Routes>
        {childRoutes.map((route) => (
          <Route
            key={route.id}
            path={route.path}
            element={<PrivateRoute component={route.main} pageAccessPermission={route.pageAccessPermission} />}
          />
        ))}
      </Routes>
    </PageWrapper>
  );
};

export default withLoader(InventoryManagement);
