import { useSelector } from 'react-redux';
import { Store } from 'components/common/types/Store.types';
import { FrontEndType } from 'components/common/types/Deal.types';
import Accordion from 'lib/Accordion';
import slidersSVG from 'assets/icons/sliders.svg';
import LineCPMSwitch from './LineCPMSwitch';
import BidsSubmissionSwitch from './BidsSubmissionSwitch';
import DspSyncSwitch from './DspSyncSwitch';

const CampaignSettings: React.FC = () => {
  const frontEndType = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.frontEndType);

  return (
    <div className="mx-6">
      <Accordion
        dataTestId="campaign-settings"
        label="Campaign settings"
        icon={slidersSVG}
        labelClassNames="text-essential-tertiary body-sm"
      >
        <div className="grid grid-cols-1">
          <DspSyncSwitch />
          <BidsSubmissionSwitch />
          {frontEndType === FrontEndType.STANDARD ? <LineCPMSwitch /> : null}
        </div>
      </Accordion>
    </div>
  );
};

export default CampaignSettings;
