import cx from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import Button, { Color } from 'lib/Button';
import plusSvg from 'assets/icons/plus.svg';
import { Line } from 'components/common/types/Deal.types';
import { LineSelectProps } from './LineSelect.types';

const LineSelect: React.FC<LineSelectProps> = ({
  selectedLineId,
  onSelectOne,
  onNewLineClick,
  lines,
  isDisabled = false,
  isAddNewLineDisabled = false,
  isSelectLineDisabled = false,
}) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const activeLineRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);

  const onLineSelect = (selectedLine: Line): void => {
    if (selectedLine.lineId !== selectedLineId) onSelectOne(selectedLine);
    setIsOpen(false);
  };

  useEffect(() => {
    if (activeLineRef?.current && isOpen) {
      activeLineRef.current.scrollIntoView();
    }
  }, [activeLineRef?.current, isOpen]);

  return (
    <div ref={parentRef} data-test-id="line-select" className="relative">
      <Popover
        containerClassName="z-20 py-1"
        parentElement={parentRef.current as HTMLElement}
        align="end"
        positions={['bottom']}
        isOpen={isOpen}
        onClickOutside={() => setIsOpen(false)}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor="var(--transparent-color)"
            arrowSize={0}
          >
            <div className="relative w-80 bg-neutral-50 shadow-md border border-neutral-300 rounded-md">
              <div className="flex flex-col">
                <div className="w-full flex justify-start items-center space-x-2 py-4 px-4 border-b border-neutral-300">
                  <div
                    data-test-id="line-select-new-line"
                    className="w-full flex justify-between items-center body-base space-x-2 mx-1"
                  >
                    <p className="sub-header-base font-bold">Deal Lines</p>
                    <Button
                      classNames="text-primary shadow-inner-sm border border-neutral-300"
                      dataTestId="line-select-new-line-button"
                      label="New Deal Line"
                      svg={plusSvg}
                      color={Color.TRANSPARENT}
                      onClick={onNewLineClick}
                      isDisabled={isAddNewLineDisabled || isDisabled}
                    />
                  </div>
                </div>
                <div
                  data-test-id="line-select-list"
                  ref={listRef}
                  className="max-h-[500px] overflow-y-auto py-1 px-2.5"
                >
                  {lines.map((line) => {
                    const { name, lineId } = line;

                    return (
                      <div
                        ref={lineId === selectedLineId ? activeLineRef : null}
                        data-test-id={`option-${lineId}`}
                        key={`option-${lineId}`}
                        className={cx('w-full flex justify-start items-center space-x-2 px-2.5 rounded-md', {
                          'bg-neutral-950-opacity-5 sub-header-base text-primary': lineId === selectedLineId,
                          'hover:bg-primary-600-opacity-5 hover:text-primary': lineId !== selectedLineId,
                        })}
                      >
                        <button
                          className="py-2 w-full flex justify-start items-center body-base space-x-2"
                          type="button"
                          onClick={() => onLineSelect(line)}
                          disabled={(isSelectLineDisabled || isDisabled) && lineId !== selectedLineId}
                        >
                          <p className="w-10/12 text-left body-base truncate whitespace-nowrap overflow-hidden">
                            {name}
                          </p>
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </ArrowContainer>
        )}
      >
        <button
          className={cx(
            'bg-neutral-950-opacity-5 sub-header-base text-primary w-[30px] h-[24px] border rounded-md hover:bg-neutral-950-opacity-10',
            {
              'border-neutral-400': isOpen,
              'border-transparent': !isOpen,
            },
          )}
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          disabled={isDisabled}
        >
          <p>...</p>
        </button>
      </Popover>
    </div>
  );
};

export default LineSelect;
