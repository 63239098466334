import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_CRITERIA } from 'consts/targetCriteria';
import { DirectSalesLineObjective } from 'components/common/types/DirectSalesCampaign.types';

const initialState: DirectSalesLineObjective = {
  id: 0,
  name: '',
  formats: [],
  locations: [],
  poi: {},
  tags: [],
  frameList: { routeFrameCodes: [], listFiles: [] },
  budget: {
    ...DEFAULT_CRITERIA,
    value: 0,
  },
  frames: {
    ...DEFAULT_CRITERIA,
    value: 0,
  },
  impressions: {
    ...DEFAULT_CRITERIA,
    value: 0,
  },
};

export const objectiveSlice = createSlice({
  name: 'objective',
  initialState,
  reducers: {
    changeActiveObjective: (state, action: PayloadAction<Partial<DirectSalesLineObjective>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    changeActiveObjectiveName: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        name: action.payload,
      };
    },
    clearActiveObjective: () => {
      return initialState;
    },
  },
});

export const { changeActiveObjective, changeActiveObjectiveName, clearActiveObjective } = objectiveSlice.actions;

export default objectiveSlice.reducer;
