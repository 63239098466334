import { FilterDsp } from 'components/common/FilterArea/Filters';
import { DealStatus } from 'components/common/types/Deal.types';
import { InputShape, InputSize, InputTheme } from 'components/common/types/Input.types';
import { Store } from 'components/common/types/Store.types';
import { ShadowStyle } from 'consts/shadow';
import { Padding } from 'lib/BaseSelect/BaseSelect.types';
import { Label } from 'lib/Label';
import { useDispatch, useSelector } from 'react-redux';
import { changeProgrammaticDealInfo } from 'store/dealManagement/actions';
import { isReadOnly } from 'utils/isReadOnly';

export const SelectDsp: React.FC = () => {
  const dispatch = useDispatch();

  const isEditingDisabled = useSelector((state: Store) => state.dealManagement.isEditingDisabled);
  const bookingStatusCode = useSelector((state: Store) => state.dealManagement.commonDeal.bookingStatusCode);
  const dsp = useSelector((state: Store) => state.dealManagement.programmatic.dsp);

  const readOnly = isReadOnly(bookingStatusCode, isEditingDisabled);
  const isPendingReservation = bookingStatusCode === DealStatus.PENDING_RESERVATION;

  return (
    <>
      <Label id="dsp" theme={InputTheme.FLAT_GRAY} label="DSP" isRequired />
      <FilterDsp
        dataTestId="async-select-dsp"
        placeholder=""
        selectedValue={dsp}
        onChange={(key, value) => dispatch(changeProgrammaticDealInfo({ [key]: value, syncWithDsp: false }))}
        isDisabled={readOnly || DealStatus.LIVE === bookingStatusCode || isPendingReservation}
        theme={InputTheme.FLAT_GRAY}
        size={InputSize.EXTRA_SMALL}
        padding={Padding.NONE}
        shadow={ShadowStyle.NONE}
        shape={InputShape.ROUNDED}
      />
    </>
  );
};
