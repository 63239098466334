import BaseSelect from 'lib/BaseSelect';
import { FilterProductCategoryProps } from './FilterProductCategory.types';

const FilterProductCategory: React.FC<FilterProductCategoryProps> = ({
  dataTestId,
  isClearable,
  isDisabled,
  options,
  selectedValue,
  onSelect,
  theme,
  size,
  padding,
  shadow,
  shape,
}) => {
  return (
    <BaseSelect
      placeholder="Select product category"
      name="productCategory"
      dataTestId={dataTestId}
      isClearable={isClearable}
      isDisabled={isDisabled}
      options={options}
      selectedValue={selectedValue}
      onSelect={onSelect}
      theme={theme}
      size={size}
      padding={padding}
      shadow={shadow}
      shape={shape}
    />
  );
};

export default FilterProductCategory;
