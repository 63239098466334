import { CodeNameModel, FileList } from '.';
import { Code } from '../../../utils/framesUtils.types';
import { PatternOption } from '../DaypartTargeting/DaypartTargeting.types';
import { City, Country, County, PostCode as PostCodeLocation, Street } from '../Deal/Location/Location.types';
import { Asset } from './Asset.types';
import { FileSuccess } from './File.types';
import { OpenStreetMapPoiWithPoints, PlannerPoiWithPoints } from './Planner.types';
import { SelectedSegment, TransformedSegmentFormData } from './Segment.types';

export type Advertiser = {
  code: string;
  id?: string;
  name: string;
  publisher?: string;
  brands?: BrandWithProductCategory[];
};

export enum AffinityGoal {
  MAX = 'MaximizationGoalData',
  MIN = 'MinimizationGoalData',
  EQUALS = 'ValueGoalData',
  AT_LEAST = 'AtLeastGoalData',
  AT_MOST = 'AtMostGoalData',
}

export type Brand = {
  code: string;
  name: string;
};

export interface BrandWithProductCategory extends Brand {
  id?: string;
  productCategory: CodeNameModel;
  publisher?: string;
}

export type Agency = {
  code: string;
  name: string;
};

export enum AuctionModel {
  FIRST_PRICE = 'FIRST_PRICE',
  SECOND_PRICE = 'SECOND_PRICE',
}

export type Availability = {
  allocatedImpressions: number;
  availableImpressions: number;
  allocatedFrames: number;
  availableFrames: number;
  totalCost: number;
  netMediaValue?: number;
  grossMediaValue?: number;
  assets: Asset[];
  assetCpmDistribution: {
    weightedMean: number;
    min: number;
    max: number;
  };
};

export type Allocation = {
  campaignID: string;
  dealId?: string;
};

export type AllocationProcessing = {
  campaignID: string;
  messages: string[];
  processingStatus: DealProcessingStatuses;
};

export type LineAllocationData = {
  availableFrames: number;
  availableImpressions: number;
  deliveredImpressions: number;
  assets: Asset[];
  assetCpmDistribution: {
    weightedMean: number;
    min: number;
    max: number;
    median: number;
  };
  totalCost: number;
  totalFrames: number;
  totalImpressions: number;
  totalSot: number;
  summary: Summary;
};

export enum DealStatus {
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  LIVE = 'LIVE',
  ENDED = 'ENDED',
  CANCELLED = 'CANCELLED',
  TERMINATED = 'TERMINATED',
  PROPOSAL = 'PROPOSAL',
  FAILED = 'FAILED',
  PENDING_RESERVATION = 'PENDING_RESERVATION',
  RESERVED = 'RESERVED',
  CONFIRMED = 'CONFIRMED',
}

export enum DealStatusLabel {
  PENDING_APPROVAL = 'Pending approval',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  LIVE = 'Live',
  ENDED = 'Ended',
  CANCELLED = 'Cancelled',
  TERMINATED = 'Terminated',
  PROPOSAL = 'Proposal',
  FAILED = 'Failed',
  PENDING_RESERVATION = 'Pending reservation',
  RESERVED = 'Reserved',
}

export enum FrontEndType {
  PLANNER = 'PLANNER',
  STANDARD = 'STANDARD',
}

export type DayPartHours = number[];

export type DayPart = {
  id: number;
  dayPartId: number;
  startOffset: number;
  endOffset: number;
  displayName: string;
};

export type Dsp = {
  id?: string;
  code: string;
  name: string;
};

export type Environment = {
  code: string;
  name: string;
  audienceCategoryGroupCode: string;
  dayPartGroupCode: string;
};

export type Network = {
  code: string;
  name: string;
  assets: number;
};

export type IndexOptimisation = {
  dataProvider: string;
  displayName: string;
  secondaryAudienceKey: string;
  type: AffinityGoal;
  threshold: number | null;
  target: number | null;
};

export interface RouteFrameCode extends CodeNameModel {
  include?: boolean;
  mediaOwner?: string;
}

export interface Line {
  availability: Availability;
  adjustedSot?: number;
  budget: number | undefined;
  bookingStatusCode?: DealStatus;
  ceilingSot?: number;
  cities: City[];
  counties: County[];
  countries: Country[];
  cpm: number | string;
  createdDate: Date | string;
  currentRemainingImpressions?: number;
  earliestFrameStartDate: Date | string;
  earliestFrameTimezone: string;
  endDate: Date | string | null;
  environments: Environment[];
  floorSot?: number;
  frames: number | undefined;
  frontEndType: FrontEndType;
  id: string;
  impressions: number | undefined;
  impressionMetrics: ImpressionMetrics | undefined;
  indexOptimisation: IndexOptimisation[];
  isCurrentLineWithProposalAllocation: boolean;
  deliveredImpressions: number;
  latestFrameEndDate: Date | string;
  latestFrameTimezone: string;
  lineId: string;
  dischargedFrameIds: string[];
  listFiles: FileList[];
  uploadedFrameLists: FileList[];
  mediaOwners?: string[];
  maxCPM?: number | undefined;
  name: string;
  patternLength: number;
  patternRepeatType: PatternOption;
  postCodes: PostCodeLocation[];
  preservedAvailability: PreservedAvailability;
  preservedAllocation: PreservedLineAllocation;
  productFormats: unknown[];
  proximity: Proximity;
  routeFrameCodes?: RouteFrameCode[];
  segment: {
    selectedSegmentDataProvider: string;
    selectedSegments: SelectedSegment;
  };
  selectedDays?: GroupOfSelectedDays;
  sot: number | undefined;
  sotCeiling?: number;
  sotFloor?: number;
  sortOrderNumber?: string;
  startDate: Date | string | null;
  streets: Street[];
  sweep?: string;
  tags: Tag[];
  terminated: boolean;
  venueTaxonomies: number[];
  visualUnitCodes?: Code[];
  visualUnitFiles?: FileList[];
}

export interface LinePost {
  id: string;
  name: string;
  startDate: Date | string;
  endDate: Date | string;
  cpm: number;
  impressions: number;
  budget: number;
  sot: number;
  sotCeiling: number;
  sotFloor: number;
  sweep: string;
  frames: number;
  frontEndType: FrontEndType;
  cities: CodeNameModel[];
  counties: CodeNameModel[];
  countries: CodeNameModel[];
  streets: CodeNameModel[];
  postCodes: CodeNameModel[];
  environments: Environment[];
  productFormats: [];
  selectedDays: GroupOfSelectedDays;
  patternLength: number;
  tags: Tag[];
  routeFrameCodes: string[];
  listFiles: unknown[];
  visualUnitCodes: { code: string; include: boolean; mediaOwner: string }[];
  visualUnitFiles: [];
  venueTaxonomies: number[];
  proximity: Proximity;
  secondaryAudience: TransformedSegmentFormData[] | null;
  indexOptimisation: IndexOptimisation[] | null;
}

export interface Bids {
  bidRequests: number;
  bidResponses: number;
  bookedImpressions: number;
  fillRate: number;
  revenue: number;
  soldImpressions: number;
  wonImpressions: number;
}

export type Programmatic = {
  dsp: Dsp | null;
  syncWithDsp: boolean;
  dspSeatId: string;
  enableCreativeSubmissionInBidStream: boolean;
  enableOpenMarketplace: boolean;
  enableLineLevelTrading: boolean;
};

export interface ImpressionMetrics {
  expectedImpressionsElapsed: number;
  osi: number;
  remainingImpressions: number;
  soldImpressions: number;
  totalAllocatedImpressions: number;
}

export interface ProgrammaticCampaign extends CommonDeal, Programmatic {}

export interface CommonDeal {
  advertiser: Advertiser | null;
  agency: CodeNameModel;
  auctionModel: AuctionModel;
  bids: Bids;
  bookingStatusCode: DealStatus;
  brand: Brand | null;
  cpm: string | number | undefined;
  currentLine: Line;
  dealId: string;
  dealName: string;
  dealPriority: DealPriority;
  dealType: DealType;
  expirationDate: Date;
  externalReference: string;
  internalId: string;
  impressionMetrics: ImpressionMetrics | undefined;
  overriddenCampaignId: string;
  productCategory: CodeNameModel | null;
  salesPerson: CodeNameModel | null;
  salesTeam: CodeNameModel | null;
  adminPerson?: CodeNameModel | null;
  sbId: string;
  sourceSystem: SourceSystem;
  specialist: CodeNameModel;
  summary: Summary;
}

export interface DealPost {
  dealName: string;
  advertiser: Advertiser | null;
  brand: Brand | null;
  productCategory: CodeNameModel | null;
  auctionModel: AuctionModel;
  bookingStatusCode: DealStatus;
  dealPriority: DealPriority;
  dealType: DealType;
  dsp: Dsp | null;
  dspSeatId: string;
  cpm: number;
  externalReference: string;
  internalId: string;
  overriddenCampaignId: string;
  agency: CodeNameModel | null;
  salesPerson: CodeNameModel | null;
  salesTeam: CodeNameModel | null;
  adminPerson?: CodeNameModel | null;
  specialist: CodeNameModel | null;
  syncWithDsp: boolean;
  summary: unknown;
  secondaryAudience?: TransformedSegmentFormData[] | null;
  enableCreativeSubmissionInBidStream: boolean;
  enableOpenMarketplace: boolean;
}

export enum DealPriority {
  STANDARD = 'STANDARD',
  PREFERRED = 'PREFERRED',
}

export enum DealPriorityLabel {
  STANDARD = 'Standard',
  PREFERRED = 'Preferred',
}

export enum DealType {
  NON_GUARANTEED_FLOOR_PRICE = 'NON_GUARANTEED',
  NON_GUARANTEED_FIXED_PRICE = 'GUARANTEED_PRICE',
  GUARANTEED = 'GUARANTEED_PRICE_IMPRESSIONS',
}

export enum DealTypeLabel {
  NON_GUARANTEED_FLOOR_PRICE = 'Non-guaranteed floor price',
  NON_GUARANTEED_FIXED_PRICE = 'Non-guaranteed fixed price',
  GUARANTEED = 'Guaranteed',
}

export const DealTypeValueLabel = {
  [DealType.NON_GUARANTEED_FLOOR_PRICE]: 'Non-guaranteed floor price',
  [DealType.NON_GUARANTEED_FIXED_PRICE]: 'Non-guaranteed fixed price',
  [DealType.GUARANTEED]: 'Guaranteed',
};

export enum Distances {
  Metres = 'M',
  Km = 'KM',
  Miles = 'MI',
}

export interface Poi {
  attributeCode: string;
  dataSourceCode: string;
  distance: string;
  include: boolean;
  selectedCodes: CodeNameModel[];
  tags: Tag[];
  unit: Distances;
}

export type Points = {
  distance: string;
  files?: FileSuccess[];
  include: boolean;
  isFileUploadActive: boolean;
  latitude: string;
  longitude: string;
  unit: Distances;
};

export type PostCode = {
  distance: string;
  files?: FileSuccess[];
  include: boolean;
  isFileUploadActive: boolean;
  tags: Tag[];
  unit: Distances;
};

export type Proximity = {
  openStreetMapPoi: OpenStreetMapPoiWithPoints[];
  plannerPoi: PlannerPoiWithPoints[];
  poi: Poi;
  points: Points;
  postCode: PostCode;
};

export enum SourceSystem {
  ADSERVER = 'ADSERVER',
  AUTOMATION = 'AUTOMATION',
  DV360 = 'DV360',
}

export type Summary = {
  adjustedSots?: number[];
  availability: Availability;
  budget?: number;
  cities?: CodeNameModel[];
  counties?: CodeNameModel[];
  countries?: CodeNameModel[];
  currentRemainingImpressions?: number;
  deliveredImpressions?: number;
  endDate: Date | string | null;
  environments?: Environment[];
  frames?: number;
  impressions?: number;
  listFiles: File[];
  maxTargetSot?: number;
  minTargetSot?: number;
  postCodes?: CodeNameModel[];
  productFormats?: CodeNameModel[];
  routeFrameCodes: RouteFrameCode[];
  sot?: string[] | number[];
  startDate: Date | string | null;
  streets?: CodeNameModel[];
  sweeps?: string[];
  tags?: Tag[];
};

export type Tag = {
  code: string;
  name: string;
};

export type TagInclude = Tag & { include: boolean; category?: string };

export interface GroupOfSelectedDays {
  [key: string]: SelectedDay;
}

export interface SelectedDay {
  dayId?: number;
  id: string;
  date: Date;
  currentSelection: boolean;
  isFullDay: boolean;
  dayParts: DayPart[];
  tempCustomHours: unknown;
}

export interface PreservedAvailability {
  allocatedImpressions: number;
  totalCost: number;
  totalSot: number;
  totalSpans: number;
}

export interface PreservedLineAllocation {
  allocatedFrames: number;
  allocatedImpressions: number;
  availableFrames: number;
  availableImpressions: number;
  budget: number;
  deliveredImpressions: number;
  frames: number;
  impressions: number;
  totalCost: number;
}

export type DealListBids = {
  [dealId: string]: {
    [marketId: string]: Bids;
  };
};

export enum SweepOptions {
  SWEEP_TARGET_EXACT_SOT = 'SWEEP_TARGET_EXACT_SOT',
  SWEEP_TO_FILL = 'SWEEP_TO_FILL',
  SWEEP_TO_HOUR = 'SWEEP_TO_HOUR',
  SWEEP_TO_DAYPART = 'SWEEP_TO_DAYPART',
  SWEEP_TO_DAY = 'SWEEP_TO_DAY',
  SWEEP_WEIGHTED_DISTRIBUTION = 'SWEEP_WEIGHTED_DISTRIBUTION',
}

export enum DealProcessingStatuses {
  CREATED = 'CREATED',
  STILL_PROCESSING = 'STILL_PROCESSING',
  ERROR = 'ERROR',
}

export interface DealFilters {
  dsp?: CodeNameModel;
  dealNameOrId?: string;
  dealType?: DealType;
  status?: DealStatus;
  advertiser?: Advertiser;
  internalId?: string;
  dismountedSearch: DealAction;
}

export interface Impressions {
  targetImpressions: number | null;
  aggregatedImpressions: number | null;
  allocatedImpressions: number | null;
  availableImpressions: number | null;
  currentTradedImpressions: number | null;
  currentRemainingImpressions: number | null;
}

export interface LostImpressions {
  lostImpressions: number;
  lossReason: string;
  lossCode: string;
}

export enum DealAction {
  NONE = 'NONE',
  ACTIVE = 'ACTIVE',
}
