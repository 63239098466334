import { useRef } from 'react';
import SVG from 'react-inlinesvg';
import cx from 'classnames';
import arrowDown from 'assets/icons/chevron_down.svg';
import { useToggle } from 'customHooks/useToggle';
import { useOutsideClick } from 'customHooks/useOutsideClick';
import { Color, ButtonTheme } from 'lib/Button/Button.types';
import { DropdownMenuPosition, DropdownMenuProps, dropdownMenuPositions } from './DropdownMenu.types';

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  dropdownMenuOptions,
  icon,
  label,
  dropdownMenuPosition = DropdownMenuPosition.RIGHT,
  color = Color.PRIMARY,
}) => {
  const splitButtonRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const { isOpen, toggle, close } = useToggle(false);

  useOutsideClick(splitButtonRef, close);

  return (
    <div className="inline-flex relative" ref={splitButtonRef}>
      <button
        className={cx('flex items-center sub-header-base px-4 py-2.5 rounded-md', ButtonTheme.theme[color])}
        onClick={toggle}
        type="button"
      >
        {icon ? (
          <div data-test-id="button-icon">
            <SVG src={icon} className="mr-2 w-4 h-4" />
          </div>
        ) : null}
        {label}
        <div data-test-id="arrow-icon">
          <SVG src={arrowDown} className="ml-2 w-5 h-5" />
        </div>
      </button>
      {isOpen && (
        <div
          className={`flex flex-col space-y-2 absolute top-9 ${dropdownMenuPositions[dropdownMenuPosition]} py-3 px-2 mt-[1px] bg-neutral-50 shadow-md rounded-md w-60`}
        >
          {dropdownMenuOptions.map((buttonOption) => (
            <button
              className="flex items-center body-base text-neutral-950 px-4 py-2 rounded-md hover:bg-primary-600-opacity-10"
              key={buttonOption.id}
              onClick={buttonOption.onClick}
              type="button"
              data-pendo-id={buttonOption.dataPendoId || null}
            >
              {buttonOption.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
