import { useDispatch, useSelector } from 'react-redux';
import { isReadOnly } from 'utils/isReadOnly';
import Input from 'lib/Input';
import { Label } from 'lib/Label';
import { DealStatus } from 'components/common/types/Deal.types';
import { InputShape, InputSize, InputTheme } from 'components/common/types/Input.types';
import { Store } from 'components/common/types/Store.types';
import { changeDealInfo } from 'store/dealManagement/actions';

const ExternalReference: React.FC = () => {
  const dispatch = useDispatch();

  const externalReference = useSelector((state: Store) => state.dealManagement.commonDeal.externalReference);
  const isEditingDisabled = useSelector((state: Store) => state.dealManagement.isEditingDisabled);
  const bookingStatusCode = useSelector((state: Store) => state.dealManagement.commonDeal.bookingStatusCode);

  const isDisabled =
    (isReadOnly(bookingStatusCode, isEditingDisabled) || DealStatus.LIVE === bookingStatusCode) &&
    bookingStatusCode !== DealStatus.PENDING_RESERVATION;

  const handleChange = (value: string): Promise<void> => dispatch(changeDealInfo({ externalReference: value }));

  return (
    <>
      <Label id="ext-reference" theme={InputTheme.FLAT_GRAY} label="Ext.reference" />
      <Input
        name="External reference"
        theme={InputTheme.FLAT_GRAY}
        size={InputSize.EXTRA_SMALL}
        shape={InputShape.ROUNDED}
        dataTestId="external-ref-input"
        debounceTime={1000}
        onChange={handleChange}
        isDisabled={isDisabled}
        value={externalReference}
      />
    </>
  );
};

export default ExternalReference;
