import { Component } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import isEqual from 'lodash/isEqual';
import queryString from 'query-string';
import classNames from 'classnames/bind';

import Auth from 'modules/Auth';
import Session from 'modules/Session';
import { fetchCreativesFromAllMarkets } from 'modules/api/creativesAdministration';
import { PermissionsEnum } from 'consts/permissions';
import { getValidPageSize } from 'utils/pageSize';
import { getDealIdsStringFromMarkets } from 'utils/dealIdsFromMarkets';
import withCancelRequest from 'components/hocs/withCancelRequest';
import PageSize from 'components/patterns/PageSize';
import Table, { TableHeight, TableRowHeight } from 'components/patterns/Table';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';
import globeSvg from 'assets/icons/globe.svg';
import ImageRenderer from 'components/common/ImageRenderer';

import HeaderRenderer from 'components/common/HeaderRenderer';
import styles from './Creatives.pcss';

const cx = classNames.bind(styles);

class Creatives extends Component {
  pageSizeKey = 'creativeAdmin';

  state = {
    pageSize: getValidPageSize(Session.getPageSizeByKey(this.pageSizeKey)),
  };

  componentDidUpdate = (prevProps) => {
    const { filters, areMarketsUpdated, setSelectedCreatives } = this.props;

    if (
      this.tableApi &&
      (!isEqual(prevProps.filters, filters) || (prevProps.areMarketsUpdated === false && areMarketsUpdated))
    ) {
      this.tableApi.setDatasource(this.getDataSource());
      setSelectedCreatives([]);
    }
  };

  onSelectionChanged = (event) => {
    const { setSelectedCreatives } = this.props;

    setSelectedCreatives(event.api.getSelectedRows());
  };

  columnDefs = [
    {
      headerName: 'Creative',
      field: 'thumbnail',
      minWidth: 180,
      headerTooltip: 'Creative',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      headerComponent: HeaderRenderer,
      headerComponentParams: {
        onSelectionChanged: this.onSelectionChanged,
      },
      checkboxSelection: true,
      cellRenderer: ImageRenderer,
      cellRendererParams: (params) => ({
        link: params.data?.id ? `/content-management/previewCreative/${params.data.id}` : '',
      }),
    },
    { headerName: 'ID', field: 'externalId', minWidth: 215, headerTooltip: 'ID' },
    {
      headerName: 'DSP',
      field: 'dsp',
      minWidth: 337,
      headerTooltip: 'DSP',
      cellRenderer: (params) => {
        if (!params.data) return '';
        return params.data.dsp?.name;
      },
    },
    {
      headerName: 'Deal ID',
      field: 'market',
      minWidth: 368,
      headerTooltip: 'Deal ID',
      cellRenderer: (params) => {
        if (!params.data?.market) return '-';
        return getDealIdsStringFromMarkets(params.data.market);
      },
    },
    {
      headerName: 'Market',
      field: 'market',
      minWidth: 240,
      headerTooltip: 'Market',
      resizable: false,
      cellRenderer: (params) => {
        if (!params.data?.market) return '-';
        const markets = params.data.market.map((m) => m.environment);

        return markets.length ? `${markets.join(',')}` : '';
      },
    },
  ];

  getFilterQuerystring = (filters) => {
    const filtersMap = {
      withMarket: filters?.marketStatus,
      environment: filters.market?.name,
      dspName: filters.dsps?.map((item) => item.name),
      externalId: filters.creativeIds?.map((item) => item.name),
      dealId: filters.dealIds?.map((item) => item.name),
    };

    return queryString.stringify(filtersMap, { skipEmptyString: true, arrayFormat: 'comma' });
  };

  getDataSource = () => {
    const { cancelFunctions, filters } = this.props;
    const { pageSize } = this.state;

    return {
      getRows: async (params) => {
        try {
          const pageNumber = parseInt(params.startRow / pageSize);
          const { content, totalElements } = await fetchCreativesFromAllMarkets(
            cancelFunctions,
            this.getFilterQuerystring(filters),
            `&page=${pageNumber}&size=${pageSize}`,
          );

          if (totalElements === 0) {
            this.tableApi.showNoRowsOverlay();
          } else {
            this.tableApi.hideOverlay();
          }

          params.successCallback(content, totalElements);
        } catch {} // eslint-disable-line no-empty
      },
    };
  };

  getTableApi = (tableApi) => {
    this.tableApi = tableApi;
    this.tableApi.setDatasource(this.getDataSource());
  };

  onPageSizeChange = (numberOfItems) => {
    const { setSelectedCreatives } = this.props;

    this.setState(
      {
        pageSize: numberOfItems,
      },
      () => {
        Session.setPageSizeByKey(this.pageSizeKey, numberOfItems);

        setSelectedCreatives([]);
      },
    );
  };

  render() {
    const { onOpenDialog, hasUserSelectedCreatives } = this.props;
    const { pageSize } = this.state;

    return (
      <>
        <div className="row my-4">
          <div className={cx('col-12', 'col-md-6', 'wrapper')}>
            <PageSize
              dataTestId="page-size-selection"
              onPageSizeChange={this.onPageSizeChange}
              pageSize={pageSize}
              pageInfoText="creatives per page"
            />
          </div>
          {Auth.hasPermission(PermissionsEnum.ADMINISTRATION_ASSIGN_MARKET) && (
            <div className="col-12 col-md-6 col-lg-3 ml-auto text-right">
              <Button
                onClick={onOpenDialog}
                btnShape={ButtonShape.NORMAL}
                btnType={ButtonType.TERTIARY}
                isDisabled={!hasUserSelectedCreatives}
              >
                <SVG className="fill-current text-neutral-50" src={globeSvg} />
                Assign market
              </Button>
            </div>
          )}
        </div>
        <Table
          dataTestId="creatives-table"
          key={pageSize}
          columnDefs={this.columnDefs}
          getTableApi={this.getTableApi}
          extraGridOptions={{
            rowModelType: 'infinite',
            rowHeight: TableRowHeight.NORMAL,
            pagination: true,
            paginationPageSize: pageSize,
            cacheBlockSize: pageSize,
            maxBlocksInCache: 1,
            rowSelection: 'multiple',
            suppressRowClickSelection: true,
            onSelectionChanged: this.onSelectionChanged,
            enableCellTextSelection: true,
            domLayout: 'autoHeight',
          }}
          tableSize={{ tableHeight: TableHeight.AUTO }}
        />
      </>
    );
  }
}

Creatives.propTypes = {
  cancelFunctions: PropTypes.objectOf(PropTypes.func).isRequired,
  filters: PropTypes.shape({}).isRequired,
  areMarketsUpdated: PropTypes.bool.isRequired,
  setSelectedCreatives: PropTypes.func.isRequired,
  onOpenDialog: PropTypes.func.isRequired,
  hasUserSelectedCreatives: PropTypes.bool.isRequired,
};

export default withCancelRequest(Creatives);
