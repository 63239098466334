import isEqual from 'lodash/isEqual';
import Auth from 'modules/Auth';
import { PermissionsEnum } from 'consts/permissions';
import { SOURCE_SYSTEM } from 'consts/sourceSystem';
import { DealType, DealStatus, FrontEndType } from 'components/common/types/Deal.types';
import { formatDateObjToIsoDateString, getCurrentDateWithTimezone } from 'utils/dateFormatUtil';
import { isReadOnly } from 'utils/isReadOnly';

export const isFormValid = (formState, isReseller) => {
  const {
    advertiser,
    brand,
    productCategory,
    cpm: dealCpm,
    dealType,
    sourceSystem,
    currentLine: { environments, name, impressions, budget, cpm: lineCpm, frontEndType },
  } = formState.commonDeal;
  const { dsp, syncWithDsp, dspSeatId } = formState.programmatic;
  const isDV360Deal = sourceSystem === SOURCE_SYSTEM.DV360;

  if (!advertiser?.code) return false;
  if (!brand?.code) return false;
  if (!productCategory?.code) return false;
  if (!isDV360Deal && Number(dealCpm) <= 0 && Number(lineCpm) <= 0) return false;
  if (!dsp?.code) return false;
  if (syncWithDsp && !dspSeatId) return false;
  if (!environments?.length && !isReseller) return false;
  if ((!frontEndType || frontEndType !== FrontEndType.PLANNER) && !name) return false;
  if (dealType === DealType.GUARANTEED && !impressions && !budget) return false;

  return true;
};

export const hasLineStarted = (line) => {
  if (!line?.startDate) return false;

  const currentDate = getCurrentDateWithTimezone(line?.earliestFrameTimezone);
  const formattedStartDate = formatDateObjToIsoDateString(line?.startDate, false);
  const formattedCurrentDate = formatDateObjToIsoDateString(currentDate, false);

  return formattedStartDate < formattedCurrentDate;
};

export const hasLineEnded = (line) => {
  if (!line?.endDate) return false;

  const currentDate = getCurrentDateWithTimezone(line?.earliestFrameTimezone);
  const formattedEndDate = formatDateObjToIsoDateString(line?.endDate, false);
  const formattedCurrentDate = formatDateObjToIsoDateString(currentDate, false);

  return formattedEndDate < formattedCurrentDate;
};

/* eslint-disable-next-line sonarjs/cognitive-complexity */
export const getActionConditions = (formState, isAdServerMarket, dealConfig, isReseller) => {
  const {
    isNewDeal,
    isEditingDisabled,
    isActionsDisabled,
    isFetchingAvailability,
    isCpmCampaignLevel,
    backupFormData: { lines },
    commonDeal: { bookingStatusCode, currentLine, dealType, sourceSystem },
  } = formState;

  const { maxAllowedLines } = dealConfig;

  const isLive = bookingStatusCode === DealStatus.LIVE;
  const isRejected = bookingStatusCode === DealStatus.REJECTED;
  const isEnded = bookingStatusCode === DealStatus.ENDED;
  const isTerminated = bookingStatusCode === DealStatus.TERMINATED;
  const isPending = bookingStatusCode === DealStatus.PENDING_APPROVAL;
  const isApproved = bookingStatusCode === DealStatus.APPROVED;
  const isPendingReservation = bookingStatusCode === DealStatus.PENDING_RESERVATION;
  const isReserved = bookingStatusCode === DealStatus.RESERVED;
  const isNewLine = !currentLine?.id || (!currentLine?.lineId && isAdServerMarket);
  const activeLinesCount = lines.reduce((count, line) => (line.terminated ? count : count + 1), 0);
  const hasAvailabilityData = Boolean(currentLine?.availability.assets.length);
  const isDisabled =
    isEditingDisabled || isFetchingAvailability || isActionsDisabled || !isFormValid(formState, isReseller);
  const lineLength = isAdServerMarket ? lines.filter((line) => line.terminated === false).length : lines.length;
  const backupLine = lines.find(({ id }) => id === currentLine.id);
  const isDisabledSaveLineAdserver = isLive
    ? hasLineEnded(backupLine)
    : currentLine.startDate < new Date(new Date().setHours(0, 0, 0, 0));
  const isDisabledSaveLineAutomation = hasLineEnded(backupLine);
  const hasLineChanged =
    isAdServerMarket && isCpmCampaignLevel
      ? !isEqual(
          { ...currentLine, cpm: undefined, availability: undefined, preservedAvailability: undefined },
          { ...backupLine, cpm: undefined, availability: undefined, preservedAvailability: undefined },
        )
      : !isEqual(currentLine, backupLine);

  const readOnly = !isNewLine && isReadOnly(bookingStatusCode, isEditingDisabled, isTerminated);

  return {
    terminateLine: {
      show:
        Auth.hasPermission(PermissionsEnum.DEAL_REJECT) &&
        sourceSystem !== SOURCE_SYSTEM.DV360 &&
        hasLineStarted(backupLine) &&
        isLive,
      disabled: isDisabled || !hasAvailabilityData || isNewLine || currentLine.terminated || hasLineEnded(backupLine),
    },
    deleteLine: {
      show:
        sourceSystem !== SOURCE_SYSTEM.DV360 &&
        (isNewLine || !hasLineStarted(backupLine) || (hasLineStarted(backupLine) && !isLive)),
      disabled:
        isEditingDisabled ||
        isFetchingAvailability ||
        isActionsDisabled ||
        readOnly ||
        (!isNewLine && (!hasAvailabilityData || (activeLinesCount <= 1 && !isLive))),
    },
    clearForm: {
      show: isNewDeal,
      disabled: isEditingDisabled || isFetchingAvailability || readOnly,
    },
    resetForm: {
      show: !isNewDeal,
      disabled: isEditingDisabled || isFetchingAvailability || readOnly,
    },
    createLine: {
      show:
        Auth.hasPermission(PermissionsEnum.DEAL_CONFIRM) ||
        (Auth.hasPermission(PermissionsEnum.DEAL_PROPOSE) && !(isLive || isApproved)),
      disabled:
        isDisabled ||
        isNewLine ||
        lineLength >= maxAllowedLines ||
        isPendingReservation ||
        sourceSystem === SOURCE_SYSTEM.DV360 ||
        (readOnly && dealType === DealType.GUARANTEED),
    },
    duplicateLine: {
      show: true,
      disabled:
        isDisabled ||
        isNewLine ||
        lineLength >= maxAllowedLines ||
        isPendingReservation ||
        sourceSystem === SOURCE_SYSTEM.DV360 ||
        (readOnly && dealType === DealType.GUARANTEED),
    },
    saveLine: {
      show:
        Auth.hasPermission(PermissionsEnum.DEAL_CONFIRM) ||
        (Auth.hasPermission(PermissionsEnum.DEAL_PROPOSE) && !(isLive || isApproved)),
      disabled:
        isDisabled ||
        (!hasLineChanged && !isAdServerMarket) ||
        (!hasLineChanged && isAdServerMarket && dealType === DealType.GUARANTEED) ||
        !hasAvailabilityData ||
        isNewDeal ||
        currentLine.terminated ||
        (isAdServerMarket ? isDisabledSaveLineAdserver : isDisabledSaveLineAutomation),
    },
    editDealInfo: {
      show: true,
      disabled: isEditingDisabled || isFetchingAvailability || readOnly,
    },
    saveDealInfo: {
      show:
        Auth.hasPermission(PermissionsEnum.DEAL_CONFIRM) ||
        (Auth.hasPermission(PermissionsEnum.DEAL_PROPOSE) && !(isLive || isApproved)),
      disabled: isDisabled || isNewDeal || readOnly,
    },
    pendingApprovalDeal: {
      show: Auth.hasPermission(PermissionsEnum.DEAL_PROPOSE) && (isNewDeal || isRejected),
      disabled: isDisabled || !hasAvailabilityData || readOnly,
    },
    reservedDeal: {
      show: isPendingReservation,
      disabled: isDisabled || !hasAvailabilityData,
    },
    rejectDeal: {
      show: Auth.hasPermission(PermissionsEnum.DEAL_REJECT) && isPending,
      disabled: isDisabled || !hasAvailabilityData,
    },
    confirmDeal: {
      show: Auth.hasPermission(PermissionsEnum.DEAL_CONFIRM) && (isNewDeal || isPending),
      disabled: isDisabled || !hasAvailabilityData || readOnly,
    },
    cancelDeal: {
      show: Auth.hasPermission(PermissionsEnum.DEAL_REJECT) && (isApproved || isPendingReservation || isReserved),
      disabled: isDisabled || !hasAvailabilityData,
    },
    terminateDeal: {
      show: Auth.hasPermission(PermissionsEnum.DEAL_REJECT) && sourceSystem === SOURCE_SYSTEM.DV360 && isLive,
      disabled: isDisabled || !hasAvailabilityData,
    },
    checkAllocate: {
      show: !isEnded && !isTerminated && (!isAdServerMarket || (isAdServerMarket && dealType === DealType.GUARANTEED)),
      disabled: isDisabled || readOnly,
    },
  };
};
