import { useNavigate } from 'react-router-dom';
import SVG from 'react-inlinesvg';

import notepadIcon from 'assets/icons/notepad.svg';

import Auth from 'modules/Auth';
import { PermissionsEnum } from 'consts/permissions';
import SingleContent from 'components/common/layout/SingleContent';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';

import ContentManagementHeader from '../ContentManagementHeader';
import Main from './Content';

const CreativeSets = () => {
  const navigate = useNavigate();

  const actionButton = Auth.hasPermission(PermissionsEnum.CONTENT_APPROVE) ? (
    <Button
      onClick={() => navigate('/content-management/creative-set-details')}
      btnType={ButtonType.PRIMARY}
      btnShape={ButtonShape.NORMAL}
      dataTestId="create-button"
      label="Open creative sets"
    >
      <SVG src={notepadIcon} />
      <span>Create set</span>
    </Button>
  ) : null;

  return (
    <>
      <ContentManagementHeader actionButton={actionButton} />
      <SingleContent content={<Main />} />
    </>
  );
};

export default CreativeSets;
