import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeProgrammaticDealInfo } from 'store/dealManagement/actions';
import { Store } from 'components/common/types/Store.types';
import { DealStatus } from 'components/common/types/Deal.types';
import { useDspSettings } from 'customHooks/useDspSettings';

interface UseCreativeBidStream {
  handleToggle: () => void;
  isToggleDisabled: boolean;
  isCreativeSubmissionInBidStreamEnabled: boolean;
  isSelectedDspSupportingCreativeSubmissionInBidStream: boolean;
}

export const useCreativeBidStream = (): UseCreativeBidStream => {
  const dispatch = useDispatch();
  const isNewDeal = useSelector((state: Store) => state.dealManagement.isNewDeal);
  const dsp = useSelector((state: Store) => state.dealManagement.programmatic.dsp);
  const backupDsp = useSelector((state: Store) => state.dealManagement.backupFormData.programmatic.dsp);
  const isCreativeSubmissionInBidStreamEnabled = useSelector(
    (state: Store) => state.dealManagement.programmatic.enableCreativeSubmissionInBidStream,
  );
  const bookingStatusCode = useSelector((state: Store) => state.dealManagement.commonDeal.bookingStatusCode);
  const { isSelectedDspSupportingCreativeSubmissionInBidStream } = useDspSettings();

  const isDspChanged = dsp?.code !== backupDsp?.code;

  useEffect(() => {
    if (
      (isNewDeal || isCreativeSubmissionInBidStreamEnabled || isDspChanged) &&
      isSelectedDspSupportingCreativeSubmissionInBidStream
    ) {
      dispatch(
        changeProgrammaticDealInfo({
          enableCreativeSubmissionInBidStream: true,
        }),
      );
    }
  }, [isDspChanged, isNewDeal, isSelectedDspSupportingCreativeSubmissionInBidStream]);

  const handleToggle = useCallback(() => {
    dispatch(
      changeProgrammaticDealInfo({
        enableCreativeSubmissionInBidStream: !isCreativeSubmissionInBidStreamEnabled,
      }),
    );
  }, [dispatch, isCreativeSubmissionInBidStreamEnabled]);

  const isToggleDisabled = useMemo(() => {
    return !isNewDeal && [DealStatus.ENDED, DealStatus.TERMINATED, DealStatus.CANCELLED].includes(bookingStatusCode);
  }, [isNewDeal, bookingStatusCode]);

  return {
    handleToggle,
    isToggleDisabled,
    isCreativeSubmissionInBidStreamEnabled,
    isSelectedDspSupportingCreativeSubmissionInBidStream,
  };
};
