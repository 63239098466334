import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';

import saveSvg from 'assets/icons/save.svg';
import editSvg from 'assets/icons/edit.svg';
import arrowRightSvg from 'assets/icons/arrow_right.svg';
import closeSvg from 'assets/icons/close.svg';
import checkSvg from 'assets/icons/checkmark.svg';
import infoSvg from 'assets/icons/info.svg';
import barchartSvg from 'assets/icons/bar_chart.svg';
import syncedSvg from 'assets/icons/synced.svg';
import closeSquareSvg from 'assets/icons/close_square.svg';
import warningSvg from 'assets/icons/warning.svg';

import { resetForm, toggleCampaignDrawerOpen } from 'store/dealManagement/reducer';
import { DealStatus } from 'components/common/types/Deal.types';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import { FeatureFlags } from 'components/common/types/Features.types';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';
import ButtonLib, { Color } from 'lib/Button';
import { getActionConditions } from 'components/pages/DealWithLines/common/getActionConditions';
import { FrameDetailsModal } from 'components/pages/DealWithLines/common/FrameDetailsModal/FrameDetailsModal';
import Modal from 'components/patterns/Modal';
import { useToggle } from 'customHooks/useToggle';
import { getIsAdServer, getIsReseller } from 'store/publisher/selectors';
import { getModalVisibilityConditions } from 'store/dealManagement/selectors';
import AdvertiserInfoConfirmationModal from 'components/common/Modals/AdvertiserInfoConfirmationModal';
import IconButton from '../IconButton';

// eslint-disable-next-line sonarjs/cognitive-complexity
const DealActions = ({ saveDeal, checkAllocate, dealRef }) => {
  const dispatch = useDispatch();
  const hasOutOfChargeEnabled = useHasFeatureAccess(FeatureFlags.OUT_OF_CHARGE);
  const [showSaveDealErrorDialog, setShowSaveDealErrorDialog] = useState(false);
  const [showCancelDealDialog, setShowCancelDealDialog] = useState(false);
  const [showTerminateDealDialog, setShowTerminateDealDialog] = useState(false);
  const [showFrameListModal, setShowFrameListModal] = useState(false);
  const [showAdvertiserInfoConfirmationModal, setShowAdvertiserInfoConfirmationModal] = useState(false);
  const { isOpen: isOOCModalOpen, close: closeOOCModal, open: openOOCModal } = useToggle(false);

  const dealManagementState = useSelector((state) => state.dealManagement);
  const isReseller = useSelector(getIsReseller);
  const {
    commonDeal: {
      bookingStatusCode,
      currentLine: { dischargedFrameIds, availability },
    },
  } = dealManagementState;
  const isAdServerMarket = useSelector(getIsAdServer);
  const dealConfig = useSelector((state) => state.dealConfig);
  const { shouldShowAdvertiserInfoModal } = useSelector(getModalVisibilityConditions);

  const isLive = bookingStatusCode === DealStatus.LIVE;

  const actionConditions = getActionConditions(dealManagementState, isAdServerMarket, dealConfig, isReseller);

  useEffect(() => {
    if (!hasOutOfChargeEnabled) return;

    if (dischargedFrameIds?.length > 0) {
      openOOCModal();
    } else {
      closeOOCModal();
    }
  }, [dischargedFrameIds]);

  const handleCancelDeal = () => {
    saveDeal(DealStatus.CANCELLED);
    setShowCancelDealDialog(false);
  };

  const handleTerminateDeal = () => {
    saveDeal(DealStatus.TERMINATED);
    setShowTerminateDealDialog(false);
  };

  const handleSaveDeal = () => {
    if (shouldShowAdvertiserInfoModal) {
      setShowAdvertiserInfoConfirmationModal(true);

      return;
    }

    saveDeal(null, () => setShowSaveDealErrorDialog(true));
  };

  return (
    <>
      <div className="space-x-3 px-6 flex items-center">
        {actionConditions.editDealInfo.show ? (
          <IconButton
            dataTestId="deal-edit-button"
            icon={editSvg}
            tooltipText="Edit deal info"
            disabled={actionConditions.editDealInfo.disabled}
            onClick={() => {
              dispatch(toggleCampaignDrawerOpen(true));
              if (dealRef.current) dealRef.current.scrollIntoView({ behavior: 'smooth' });
            }}
          />
        ) : null}
        {actionConditions.saveDealInfo.show ? (
          <IconButton
            dataTestId="deal-save-button"
            icon={saveSvg}
            tooltipText="Save deal info"
            disabled={actionConditions.saveDealInfo.disabled}
            onClick={handleSaveDeal}
          />
        ) : null}
      </div>
      <div className="px-6 space-x-3 flex items-center">
        {actionConditions.checkAllocate.show ? (
          <IconButton
            dataTestId="check-allocate-button"
            icon={barchartSvg}
            tooltipText="Check allocation"
            disabled={actionConditions.checkAllocate.disabled}
            onClick={checkAllocate}
          />
        ) : null}
        {!isLive && (
          <>
            {actionConditions.reservedDeal.show && (
              <ButtonLib
                dataTestId="reserved-button"
                label="Reserve"
                svg={syncedSvg}
                onClick={() => saveDeal(DealStatus.RESERVED)}
                color={Color.TERTIARY}
                isDisabled={actionConditions.reservedDeal.disabled}
                svgClassnames="!h-[13px] !w-[13px]"
              />
            )}
            {actionConditions.pendingApprovalDeal.show ? (
              <ButtonLib
                dataTestId="pending-button"
                label="Send for approval"
                svg={arrowRightSvg}
                onClick={() => saveDeal(DealStatus.PENDING_APPROVAL)}
                color={Color.TERTIARY}
                isDisabled={actionConditions.pendingApprovalDeal.disabled}
                svgClassnames="!h-[13px] !w-[13px]"
              />
            ) : null}
            {actionConditions.rejectDeal.show ? (
              <ButtonLib
                dataTestId="reject-button"
                label="Reject"
                svg={closeSvg}
                onClick={() => saveDeal(DealStatus.REJECTED)}
                color={Color.TERTIARY}
                isDisabled={actionConditions.rejectDeal.disabled}
                svgClassnames="!h-[13px] !w-[13px]"
              />
            ) : null}
            {actionConditions.confirmDeal.show ? (
              <ButtonLib
                dataTestId="confirm-button"
                label="Confirm"
                svg={checkSvg}
                onClick={() => saveDeal(DealStatus.APPROVED)}
                color={Color.PRIMARY}
                isDisabled={actionConditions.confirmDeal.disabled}
                svgClassnames="!h-[13px] !w-[13px]"
              />
            ) : null}
            {actionConditions.cancelDeal.show ? (
              <ButtonLib
                dataTestId="cancel-button"
                label="Cancel deal"
                svg={closeSvg}
                onClick={() => setShowCancelDealDialog(true)}
                color={Color.PRIMARY}
                isDisabled={actionConditions.cancelDeal.disabled}
              />
            ) : null}
          </>
        )}
        {actionConditions.terminateDeal.show && (
          <span className="col-span-3">
            <ButtonLib
              label="Terminate deal"
              svg={closeSquareSvg}
              onClick={() => setShowTerminateDealDialog(true)}
              color={Color.PRIMARY}
              isDisabled={actionConditions.terminateDeal.disabled}
            />
          </span>
        )}
      </div>
      <AdvertiserInfoConfirmationModal
        isVisible={showAdvertiserInfoConfirmationModal}
        onUndoChanges={() => {
          dispatch(resetForm());
          setShowAdvertiserInfoConfirmationModal(false);
        }}
        onConfirm={() => {
          setShowAdvertiserInfoConfirmationModal(false);
          saveDeal(null, () => setShowSaveDealErrorDialog(true));
        }}
      />
      <Modal
        isOpen={showSaveDealErrorDialog}
        icon={<SVG src={infoSvg} className="text-pinkRed-500" />}
        title="Oops! This deal section cannot be saved!"
        actionButtons={
          <>
            <Button
              btnType={ButtonType.DANGER}
              btnShape={ButtonShape.NORMAL}
              onClick={() => {
                dispatch(resetForm());
                setShowSaveDealErrorDialog(false);
              }}
            >
              Undo Changes
            </Button>
            <Button
              btnType={ButtonType.PRIMARY}
              btnShape={ButtonShape.NORMAL}
              onClick={() => setShowSaveDealErrorDialog(false)}
            >
              Ok, I will try again
            </Button>
          </>
        }
      >
        <p className="ml-8 body-base text-neutral-950-opacity-60">
          Some of the last changes to the deal section affect the availability of the lines and they cannot be saved.
          How do you want to proceed?
        </p>
      </Modal>
      <Modal
        isOpen={showCancelDealDialog}
        icon={<SVG src={closeSvg} className="text-pinkRed-500" />}
        title="Cancel deal?"
        actionButtons={
          <>
            <Button
              btnType={ButtonType.PRIMARY}
              btnShape={ButtonShape.NORMAL}
              onClick={() => setShowCancelDealDialog(false)}
            >
              Close
            </Button>
            <Button btnType={ButtonType.DANGER} btnShape={ButtonShape.NORMAL} onClick={handleCancelDeal}>
              OK
            </Button>
          </>
        }
      >
        <div className="ml-8 body-base text-neutral-950-opacity-60">
          <p className="mb-2">
            All lines within this deal will be cancelled and you will be unable to undo this change. If you proceed to
            cancel, you will be unable to edit this deal or change the status.
          </p>
          <p>Are you sure you want to cancel this deal?</p>
        </div>
      </Modal>
      <Modal
        isOpen={showTerminateDealDialog}
        icon={<SVG src={closeSquareSvg} className="text-pinkRed-500" />}
        title="Terminate deal?"
        actionButtons={
          <>
            <Button
              btnType={ButtonType.PRIMARY}
              btnShape={ButtonShape.NORMAL}
              onClick={() => setShowTerminateDealDialog(false)}
            >
              Cancel
            </Button>
            <Button btnType={ButtonType.DANGER} btnShape={ButtonShape.NORMAL} onClick={handleTerminateDeal}>
              OK
            </Button>
          </>
        }
      >
        <div className="ml-8 body-base text-neutral-950-opacity-60">
          <p className="mb-2">
            Terminating a live deal will stop all trading immediately, and you will be unable to undo this.
          </p>
          <p>Are you sure you want to Terminate your deal?</p>
        </div>
      </Modal>
      <Modal
        title="Out of Charge Frames"
        isOpen={isOOCModalOpen}
        onClose={closeOOCModal}
        showCloseButton
        icon={<SVG src={warningSvg} className="text-primary" />}
        actionButtons={
          <>
            <Button
              btnType={ButtonType.DANGER}
              btnShape={ButtonShape.NORMAL}
              onClick={() => {
                closeOOCModal();
                setShowFrameListModal(true);
              }}
            >
              Review Frames
            </Button>
            <Button
              btnShape={ButtonShape.NORMAL}
              btnType={ButtonType.PRIMARY}
              onClick={() => {
                closeOOCModal();
                checkAllocate();
              }}
            >
              Reallocate
            </Button>
          </>
        }
      >
        <div className="ml-7 body-base text-neutral-950-opacity-60">
          <p className="mb-2">
            This line includes {dischargedFrameIds?.length} out of charge frames. This may affect the performance of
            this deal.
          </p>
          <p>How would you like to proceed?</p>
        </div>
      </Modal>
      <FrameDetailsModal
        assets={availability.assets}
        isOpen={showFrameListModal}
        onClose={() => setShowFrameListModal(false)}
        footer={
          <div className="text-right mt-4">
            <Button btnShape={ButtonShape.NORMAL} btnType={ButtonType.PRIMARY} onClick={checkAllocate}>
              Reallocate
            </Button>
          </div>
        }
      />
    </>
  );
};

DealActions.propTypes = {
  saveDeal: PropTypes.func.isRequired,
  checkAllocate: PropTypes.func,
  dealRef: PropTypes.shape({ current: PropTypes.object }),
};

DealActions.defaultProps = {
  dealRef: null,
  checkAllocate: () => undefined,
};

export default DealActions;
