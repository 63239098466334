import Input from 'lib/Input';
import FilterNetworkPriceDefinition from 'components/common/FilterArea/Filters/FilterNetworkPriceDefinition/FilterNetworkPriceDefinition';
import FilterAllOrInUseToggle from 'components/common/FilterArea/Filters/FilterAllOrInUseToggle';
import { CodeNameModel } from 'components/common/types';
import FilterArea from 'components/common/FilterArea';
import { usePageFilters } from 'customHooks/usePageFilters';

export type NetworkPriceDefinitionsFilters = {
  networkPriceDefinitionCode: string;
  networkPriceDefinition?: CodeNameModel;
  inUse: boolean | '';
};

const Filters: React.FC = () => {
  const {
    changeFilters,
    filters: { networkPriceDefinitionCode, networkPriceDefinition, inUse },
  } = usePageFilters<NetworkPriceDefinitionsFilters>();

  return (
    <FilterArea customWrapperStyles="flex flex-1 gap-4 self-center [&>*]:flex-1">
      <Input
        dataTestId="network-price-definition-code"
        name="networkPriceDefinitionCode"
        value={networkPriceDefinitionCode}
        placeholder="Type price definition code"
        onChange={(userInput: string) => changeFilters({ networkPriceDefinitionCode: userInput })}
        debounceTime={300}
      />
      <FilterNetworkPriceDefinition
        changeFilters={changeFilters}
        selectedNetworkPriceDefinition={networkPriceDefinition}
      />

      <div className="flex justify-end">
        <FilterAllOrInUseToggle
          changeFilters={changeFilters}
          value={inUse}
          dataTestId="network-price-definition-in-use-select"
        />
      </div>
    </FilterArea>
  );
};
export default Filters;
