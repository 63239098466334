import StatusIcon from 'components/patterns/StatusIcon';
import checkmarkIcon from 'assets/icons/checkmark.svg';
import warningIcon from 'assets/icons/warning.svg';
import closeIcon from 'assets/icons/close.svg';
import alertIcon from 'assets/icons/info.svg';

import AssetItem from './AssetItem';

export const IconColor = {
  RED: 'text-pinkRed-500',
  GREEN: 'text-green',
  YELLOW: 'text-yellow',
  GREY: 'text-neutral-950-opacity-60',
};

export const RecordStatusIcon = {
  FAILED: alertIcon,
  SUCCESS: checkmarkIcon,
  ERROR: closeIcon,
  WARNING: warningIcon,
};

export type AssetDetailsProps = {
  newRecords?: number;
  errorRecords?: number;
  skippedRecords?: number;
  totalRecords?: number;
  updatedRecords?: number;
  isSuccess?: boolean;
};

const AssetDetails: React.FC<AssetDetailsProps> = ({
  newRecords = 0,
  errorRecords = 0,
  skippedRecords = 0,
  totalRecords = 0,
  updatedRecords = 0,
  isSuccess = false,
}) => {
  const errorRecordsIcon =
    errorRecords > 0 || !isSuccess ? (
      <StatusIcon icon={RecordStatusIcon.FAILED} iconColor={!isSuccess ? IconColor.GREY : IconColor.RED} />
    ) : (
      <StatusIcon icon={RecordStatusIcon.SUCCESS} iconColor={IconColor.GREEN} />
    );

  const skippedRecordsIcon =
    skippedRecords > 0 || !isSuccess ? (
      <StatusIcon icon={RecordStatusIcon.WARNING} iconColor={!isSuccess ? IconColor.GREY : IconColor.YELLOW} />
    ) : (
      <StatusIcon icon={RecordStatusIcon.SUCCESS} iconColor={IconColor.GREEN} />
    );

  const recordsIcon = isSuccess ? (
    <StatusIcon icon={RecordStatusIcon.SUCCESS} iconColor={IconColor.GREEN} />
  ) : (
    <StatusIcon icon={RecordStatusIcon.ERROR} iconColor={!isSuccess ? IconColor.GREY : IconColor.RED} />
  );

  return (
    <>
      <AssetItem text="records processed" icon={recordsIcon} amountOfRecords={totalRecords} />
      <AssetItem text="new records created" icon={recordsIcon} amountOfRecords={newRecords} />
      <AssetItem text="records updated" icon={recordsIcon} amountOfRecords={updatedRecords} />
      <AssetItem text="errors found" icon={errorRecordsIcon} amountOfRecords={errorRecords} />
      <AssetItem text="skipped records" icon={skippedRecordsIcon} amountOfRecords={skippedRecords} />
    </>
  );
};

export default AssetDetails;
