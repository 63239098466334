import getDealTypes from 'components/common/Deal/CampaignHeader/CampaignDrawer/Details/SelectDealType/getDealTypes/getDealTypes';
import { CodeNameModel } from 'components/common/types';
import { DealType, DealTypeValueLabel } from 'components/common/types/Deal.types';
import { FeatureFlags } from 'components/common/types/Features.types';
import { InputShape, InputSize, InputTheme } from 'components/common/types/Input.types';
import { Store } from 'components/common/types/Store.types';
import { PermissionsEnum } from 'consts/permissions';
import { ShadowStyle } from 'consts/shadow';
import { useDealFormReadOnly } from 'customHooks/useDealFormReadOnly';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import BaseSelect from 'lib/BaseSelect';
import { Padding } from 'lib/BaseSelect/BaseSelect.types';
import { Label } from 'lib/Label';
import Auth from 'modules/Auth';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeDealType } from 'store/dealManagement/actions';
import { changeDealCurrentLineData } from 'store/dealManagement/reducer';
import { getIsReseller } from 'store/publisher/selectors';
import { getDefaultSweep } from 'utils/deal';

export const SelectDealType: React.FC = () => {
  const dispatch = useDispatch();

  const hasNonGuaranteedExtendedTargetEnabled = useHasFeatureAccess(FeatureFlags.NON_GUARANTEED_EXTENDED_TARGET);

  const isReseller = useSelector(getIsReseller);
  const isNewDeal = useSelector((state: Store) => state.dealManagement.isNewDeal);
  const dealType = useSelector((state: Store) => state.dealManagement.commonDeal.dealType);
  const publisherType = useSelector((state: Store) => state.publisher.configuration.type);
  const readOnly = useDealFormReadOnly();

  const dealTypesList = getDealTypes(dealType, isNewDeal, publisherType);

  const handleSelectDealType = ({ selectedDealType }: { selectedDealType: DealType }): void => {
    dispatch(changeDealType(selectedDealType, hasNonGuaranteedExtendedTargetEnabled));
    dispatch(
      changeDealCurrentLineData({
        sweep: getDefaultSweep(selectedDealType, hasNonGuaranteedExtendedTargetEnabled),
      }),
    );
  };

  useEffect(() => {
    if (isReseller) {
      dispatch(changeDealType(DealType.NON_GUARANTEED_FLOOR_PRICE, hasNonGuaranteedExtendedTargetEnabled));
    }
  }, [isReseller]);

  return Auth.hasPermission(PermissionsEnum.DEAL_TYPE_VIEW) ? (
    <>
      <Label id="deal-type" theme={InputTheme.FLAT_GRAY} label="Deal type" isRequired />
      <BaseSelect
        onSelect={(_, value: CodeNameModel) => handleSelectDealType({ selectedDealType: value.code as DealType })}
        name="Deal type"
        dataTestId="select-deal-type"
        theme={InputTheme.FLAT_GRAY}
        size={InputSize.EXTRA_SMALL}
        padding={Padding.NONE}
        shadow={ShadowStyle.MEDIUM}
        shape={InputShape.ROUNDED}
        options={dealTypesList}
        selectedValue={{
          code: dealType,
          name: DealTypeValueLabel[dealType],
        }}
        classNameWrapper="bg-neutral-50 border !border-solid border-neutral-300"
        isDisabled={readOnly}
      />
    </>
  ) : null;
};
