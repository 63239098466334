import { ReactElement, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SVG from 'react-inlinesvg';
import { useNavigate } from 'react-router-dom';

import thumbsUpSvg from 'assets/icons/thumbs_up.svg';
import thumbsDownSvg from 'assets/icons/thumbs_down.svg';
import hourglassSvg from 'assets/icons/hourglass.svg';
import checkmarkSvg from 'assets/icons/checkmark.svg';
import crossSvg from 'assets/icons/close.svg';
import binSvg from 'assets/icons/bin.svg';
import arrowRightSvg from 'assets/icons/arrow_right.svg';

import { NOTIFICATION_TIMEOUT } from 'consts/notifications';
import { editCreativesDeleteSelected, editCreativesStatusChange } from 'modules/api/bulkEditCreatives';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { usePageFilters } from 'customHooks/usePageFilters';
import { updateCreativesStatus } from 'store/pages/contentManagement/creatives/reducer';
import { notifyError, notifySuccess } from 'store/notification/reducer';
import { Store } from 'components/common/types/Store.types';
import {
  getAllSelectedCreativesPendingStatus,
  getAllSelectedCreativesPendingAndOrRejected,
} from 'store/pages/contentManagement/creatives/selectors';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';
import Modal, { ModalSize } from 'components/patterns/Modal';
import Input from 'lib/Input';
import SingleContent from 'components/common/layout/SingleContent';
import { CreativeStatus } from 'components/common/types/Creative.types';

import { getIsReseller } from 'store/publisher/selectors';
import cx from 'classnames';
import { ActionsPanelProps } from './ActionsPanel.types';
import { checkResponseResultErrors, getCreativeMarketId } from '../utils';

const ActionsPanel: React.FC<ActionsPanelProps> = ({
  formMetadata,
  isFormTouched,
  onSaveButtonClick,
  onClearButtonClick,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const DEFAULT_REJECTION_REASON = 'Batch Rejection.';

  const [isLoading, setIsLoading] = useState(false);
  const [rejectReason, setRejectReason] = useState(DEFAULT_REJECTION_REASON);
  const [isRejectModal, setIsRejectModal] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const areAllItemsPending = useSelector(getAllSelectedCreativesPendingStatus);
  const areAllItemsPendingAndOrRejected = useSelector(getAllSelectedCreativesPendingAndOrRejected);
  const isReseller = useSelector(getIsReseller);
  const selectedCreatives = useSelector((state: Store) => state.pages.contentManagement.creatives.selectedCreatives);
  const cancelFunctions = useCancelRequest();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { changeFilters } = usePageFilters('content-management/creatives');
  const hasDealId = formMetadata.dealId.length > 0;

  const onChangeCreativesStatus = async (statusCode: CreativeStatus, rejectionReason?: string): Promise<void> => {
    setIsLoading(true);

    try {
      checkResponseResultErrors(
        await editCreativesStatusChange(cancelFunctions, {
          status: statusCode,
          ids: selectedCreatives.map(getCreativeMarketId),
          rejectionReason,
        }),
      );
      dispatch(updateCreativesStatus(statusCode));

      if (statusCode === CreativeStatus.REJECTED) {
        setIsRejectModal(false);
        setRejectReason(DEFAULT_REJECTION_REASON);
      }
    } catch (err) {
      dispatch(notifyError({ message: err.message, timeout: NOTIFICATION_TIMEOUT.LONG }));
    }

    setIsLoading(false);
  };

  const onDeleteCreatives = async (): Promise<void> => {
    setIsLoading(true);

    try {
      checkResponseResultErrors(
        await editCreativesDeleteSelected(cancelFunctions, {
          ids: selectedCreatives.map(getCreativeMarketId),
        }),
      );

      navigate('/content-management/creatives');
      changeFilters({ deleted: true });
      dispatch(notifySuccess({ message: 'Creatives deleted Successfully' }));
    } catch (err) {
      dispatch(notifyError({ message: err.message, timeout: NOTIFICATION_TIMEOUT.LONG }));
    }

    setIsLoading(false);
  };

  const onShowRejectModal = (): void => setIsRejectModal(true);
  const onHideRejectModal = (): void => {
    setIsRejectModal(false);
    setRejectReason(DEFAULT_REJECTION_REASON);
  };

  const onHandleRejectReason = (userInput: string): void => setRejectReason(userInput);

  const areAllRequiredFormMetadataAssigned = useMemo(() => {
    const requiredFormMetaData = {
      advertiser: formMetadata.advertiser,
      brand: formMetadata.brand,
    };

    return !!formMetadata && Object.values(requiredFormMetaData).every(Boolean);
  }, [formMetadata]);

  const areAllSelectedCreativesWithRequiredMetadata = selectedCreatives.every(
    ({ advertiser, brand }) => advertiser && brand,
  );

  const isSaveButtonDisabled = isLoading || (formMetadata?.advertiser && !formMetadata?.brand);

  const RejectButton = (): ReactElement => (
    <Button
      dataTestId="reject-button"
      btnShape={ButtonShape.NORMAL}
      btnType={ButtonType.DANGER}
      onClick={onShowRejectModal}
      isDisabled={isLoading}
      label="Reject"
    >
      <SVG src={thumbsDownSvg} />
      <span>Reject</span>
    </Button>
  );

  const PendingButton = (): ReactElement => (
    <Button
      dataTestId="pending-button"
      btnShape={ButtonShape.NORMAL}
      btnType={ButtonType.WARNING}
      onClick={() => onChangeCreativesStatus(CreativeStatus.PENDING)}
      isDisabled={isLoading}
      label="Pending"
    >
      <SVG src={hourglassSvg} />
      <span>Pending</span>
    </Button>
  );

  const DeleteButton = (): ReactElement => (
    <Button
      onClick={() => setShowDeleteDialog(true)}
      btnShape={ButtonShape.NORMAL}
      btnType={ButtonType.PRIMARY}
      label="Delete"
    >
      <SVG src={binSvg} />
      Delete
    </Button>
  );

  const ApproveButton = (): ReactElement => (
    <Button
      dataTestId="approve-button"
      btnShape={ButtonShape.NORMAL}
      btnType={ButtonType.SUCCESS}
      isDisabled={(!areAllRequiredFormMetadataAssigned && !areAllSelectedCreativesWithRequiredMetadata) || isLoading}
      onClick={() => onChangeCreativesStatus(CreativeStatus.APPROVED)}
      label="Approve"
    >
      <SVG src={thumbsUpSvg} />
      <span>Approve</span>
    </Button>
  );

  const SendForApprovalButton = (): ReactElement => (
    <Button
      onClick={() => onChangeCreativesStatus(CreativeStatus.SENT_FOR_APPROVAL)}
      btnShape={ButtonShape.NORMAL}
      btnType={ButtonType.PRIMARY}
      dataTestId="creative-status-send-for-approval-btn"
      isDisabled={!hasDealId}
    >
      <SVG src={arrowRightSvg} />
      Send for approval
    </Button>
  );

  const ResellerActionButtons = (): ReactElement => (
    <>
      {areAllItemsPending && (
        <>
          <SendForApprovalButton />
          <RejectButton />
        </>
      )}
    </>
  );

  const PublisherActionButtons = (): ReactElement => (
    <>
      {areAllItemsPending && <RejectButton />}
      {!areAllItemsPending && <PendingButton />}
      {areAllItemsPending && <ApproveButton />}
    </>
  );

  return (
    <div
      className={cx('border-t border-neutral-900-opacity-10 bg-neutral-50 absolute w-full left-0 bottom-1.5', {
        'h-16': !isReseller,
        'h-44': isReseller,
      })}
    >
      <SingleContent
        content={
          <>
            <div className={cx('flex gap-x-3 h-full', { 'flex-col w-full gap-y-2': isReseller })}>
              {!isFormTouched ? (
                <>
                  {isReseller ? <ResellerActionButtons /> : <PublisherActionButtons />}
                  {areAllItemsPendingAndOrRejected && <DeleteButton />}
                </>
              ) : (
                <>
                  <Button
                    dataTestId="clear-button"
                    btnShape={ButtonShape.NORMAL}
                    btnType={ButtonType.WHITE}
                    onClick={onClearButtonClick}
                    label="Clear"
                  >
                    <SVG src={crossSvg} />
                    <span>Clear</span>
                  </Button>
                  <Button
                    dataTestId="save-button"
                    btnShape={ButtonShape.NORMAL}
                    btnType={ButtonType.SECONDARY}
                    isDisabled={isSaveButtonDisabled}
                    onClick={onSaveButtonClick}
                    label="Save"
                  >
                    <SVG src={checkmarkSvg} />
                    <span>Save</span>
                  </Button>
                </>
              )}
            </div>

            <Modal
              dataTestId="rejection-modal"
              isOpen={isRejectModal}
              icon={<SVG src={crossSvg} className="text-pinkRed-500" />}
              title="Reject creative?"
              size={ModalSize.SMALL}
              showCloseButton
              onClose={onHideRejectModal}
            >
              <Input placeholder="Reject reason..." value={rejectReason} onChange={onHandleRejectReason} />
              <div className="flex justify-end mt-4 space-x-4">
                <Button
                  dataTestId="hide-reject-modal-button"
                  btnShape={ButtonShape.NORMAL}
                  btnType={ButtonType.PRIMARY}
                  onClick={onHideRejectModal}
                >
                  Cancel
                </Button>
                <Button
                  dataTestId="reject-modal-button"
                  btnShape={ButtonShape.NORMAL}
                  btnType={ButtonType.DANGER}
                  onClick={() => onChangeCreativesStatus(CreativeStatus.REJECTED, rejectReason)}
                  isDisabled={rejectReason.length < 3}
                >
                  Reject
                </Button>
              </div>
            </Modal>

            <Modal
              dataTestId="delete-creatives-modal"
              isOpen={showDeleteDialog}
              icon={<SVG src={binSvg} className="text-pinkRed-500" />}
              title="Delete Creatives?"
              actionButtons={
                <>
                  <Button
                    btnType={ButtonType.PRIMARY}
                    btnShape={ButtonShape.NORMAL}
                    onClick={() => setShowDeleteDialog(false)}
                    label="Cancel"
                  >
                    Cancel
                  </Button>
                  <Button
                    btnType={ButtonType.DANGER}
                    btnShape={ButtonShape.NORMAL}
                    onClick={onDeleteCreatives}
                    label="Delete"
                  >
                    Delete
                  </Button>
                </>
              }
            >
              <p className="ml-8 body-base text-neutral-950-opacity-60">
                Are you sure you want to delete these creatives?
              </p>
            </Modal>
          </>
        }
      />
    </div>
  );
};

export default ActionsPanel;
