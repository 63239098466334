import { getNetworkPriceDefinition } from 'modules/api/referenceData';
import AsyncSelect from 'components/patterns/AsyncSelect';
import { CodeNameModel } from 'components/common/types';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { FilterNetworkPriceDefinitionProps } from './FilterNetworkPriceDefinition.types';

const DEFAULT_PLACEHOLDER = 'Select price definition name';

const FilterNetworkPriceDefinition: React.FC<FilterNetworkPriceDefinitionProps> = ({
  changeFilters,
  selectedNetworkPriceDefinition,
  placeholder,
}) => {
  const cancelFunctions = useCancelRequest();

  const loadNetworkPriceDefinitions = async (_name: string, query: string): Promise<CodeNameModel[]> => {
    try {
      return query.length > 1 ? await getNetworkPriceDefinition(cancelFunctions, query) : [];
    } catch (error) {
      return [];
    }
  };

  return (
    <AsyncSelect
      dataTestId="network-price-definition-name"
      name="networkPriceDefinition"
      textKey="name"
      loadData={loadNetworkPriceDefinitions}
      onSelect={(_name: string, value: CodeNameModel) => changeFilters({ networkPriceDefinition: value })}
      placeholder={placeholder || DEFAULT_PLACEHOLDER}
      selectedValue={selectedNetworkPriceDefinition}
    />
  );
};

export default FilterNetworkPriceDefinition;
