import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Store } from 'components/common/types/Store.types';
import { isEqual } from 'lodash';
import { formatIndexOptimisationWithEmptyDisplayName } from 'utils/formatIndexOptimisation';

export const useCheckProgrammaticLineLevelChange = (): boolean => {
  const backupLines = useSelector((state: Store) => state.dealManagement.backupFormData.lines);
  const commonDeal = useSelector((state: Store) => state.dealManagement.commonDeal);
  const currentLine = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine);
  const isNewDeal = useSelector((state: Store) => state.dealManagement.isNewDeal);

  const currentBackupLine = backupLines.find((line) => line.id === commonDeal.currentLine.id) ?? backupLines[0] ?? {};

  const {
    availability: backupAvailability,
    name: backupName,
    startDate: backupStartDate,
    endDate: backupEndDate,
    environments: backupEnvironments,
    impressions: backupImpressions,
    budget: backupBudget,
    frames: backupFrames,
    selectedDays: backupSelectedDays,
    sot: backupSot,
    sweep: backupSweep,
    venueTaxonomies: backupVenueTaxonomies,
    indexOptimisation: backupIndexOptimisation,
    proximity: backupProximity,
    segment: backupSegment,
    productFormats: backupProductFormats,
    routeFrameCodes: backupRouteFrameCodes,
    countries: backupCountries,
    streets: backupStreets,
    cities: backupCities,
    counties: backupCounties,
    postCodes: backupPostCodes,
    tags: backupTags,
  } = currentBackupLine;

  const {
    availability,
    name,
    startDate,
    endDate,
    environments,
    impressions,
    budget,
    frames,
    selectedDays,
    sot,
    sweep,
    venueTaxonomies,
    indexOptimisation,
    proximity: { openStreetMapPoi, plannerPoi },
    segment: { selectedSegments },
    productFormats,
    routeFrameCodes,
    countries,
    streets,
    cities,
    counties,
    postCodes,
    tags,
  } = currentLine;

  return useMemo(
    () =>
      !isNewDeal &&
      !isEqual(
        {
          assets: backupAvailability?.assets,
          startDate: backupStartDate,
          endDate: backupEndDate,
          name: backupName,
          environments: backupEnvironments,
          impressions: backupImpressions,
          budget: backupBudget,
          frames: backupFrames,
          selectedDays: backupSelectedDays,
          sot: backupSot,
          sweep: backupSweep,
          venueTaxonomies: backupVenueTaxonomies,
          indexOptimisation: formatIndexOptimisationWithEmptyDisplayName(backupIndexOptimisation),
          plannerPoi: backupProximity?.plannerPoi,
          openStreetMapPoi: backupProximity?.openStreetMapPoi,
          selectedSegments: backupSegment?.selectedSegments,
          productFormats: backupProductFormats,
          routeFrameCodes: backupRouteFrameCodes,
          countries: backupCountries,
          streets: backupStreets,
          cities: backupCities,
          counties: backupCounties,
          postCodes: backupPostCodes,
          tags: backupTags,
        },
        {
          assets: availability.assets,
          startDate,
          endDate,
          name,
          environments,
          impressions,
          budget,
          frames,
          selectedDays,
          sot,
          sweep,
          venueTaxonomies,
          indexOptimisation: formatIndexOptimisationWithEmptyDisplayName(indexOptimisation),
          plannerPoi,
          openStreetMapPoi,
          selectedSegments,
          productFormats,
          routeFrameCodes,
          countries,
          streets,
          cities,
          counties,
          postCodes,
          tags,
        },
      ),
    [backupLines, currentLine.id, commonDeal, isNewDeal],
  );
};
