import { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import SVG from 'react-inlinesvg';
import { Store } from 'components/common/types/Store.types';
import Button, { Color, Size } from 'lib/Button';
import chevronLeft from 'assets/icons/chevron_left.svg';

import { PlannerSecondaryPanelProps } from './PlannerSecondaryPanel.types';

const PlannerSecondaryPanel: React.FC<PropsWithChildren<PlannerSecondaryPanelProps>> = ({
  children,
  customPosition,
  isLineLevel = true,
  title,
  isDisabled = false,
  cancelButtonLabel = 'Cancel',
  confirmButtonLabel = 'Confirm',
  onSubmit,
  onCancel,
  onBack,
}) => {
  const dealLineName = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.name);
  const dealName = useSelector((state: Store) => state.dealManagement.commonDeal.dealName);

  return (
    <div
      data-test-id="planner-secondary-panel"
      className="fixed !my-0 w-[387px] h-2/3 max-h-[754px] flex flex-col justify-between items-center rounded-md border border-gray-300 bg-neutral-50 z-50"
      style={{
        top: customPosition?.top ? `${customPosition.top}px` : '112px',
        left: customPosition?.left ? `${customPosition.left}px` : '460px',
      }}
    >
      <div
        data-test-id="planner-secondary-panel-header"
        className="flex w-full justify-between items-center border-b border-gray-200 py-3 px-5"
      >
        <div
          data-test-id="planner-secondary-panel-title"
          className="flex items-center justify-between gap-x-1 body-base font-semibold"
        >
          {onBack ? (
            <button type="button" data-test-id="planner-secondary-panel-back" onClick={onBack}>
              <SVG src={chevronLeft} />
            </button>
          ) : null}
          {title}
        </div>
        <p
          data-test-id="planner-secondary-panel-value"
          className="w-32 body-base text-essential-tertiary whitespace-nowrap overflow-hidden text-ellipsis text-right"
        >
          {isLineLevel ? dealLineName : dealName}
        </p>
      </div>
      <div data-test-id="planner-secondary-panel-content" className="flex-1 w-full py-3 px-3 overflow-y-auto">
        {children}
      </div>
      <div
        data-test-id="planner-secondary-panel-actions"
        className="flex justify-between w-full border-t border-gray-200 p-2"
      >
        <Button label={cancelButtonLabel} size={Size.MEDIUM} color={Color.TERTIARY} onClick={onCancel} />
        <Button
          label={confirmButtonLabel}
          size={Size.MEDIUM}
          color={Color.PRIMARY}
          onClick={onSubmit}
          isDisabled={isDisabled}
        />
      </div>
    </div>
  );
};

export default PlannerSecondaryPanel;
