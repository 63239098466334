import * as lookups from 'modules/api/lookups';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { CodeNameModel } from 'components/common/types';
import AsyncSelect from 'components/patterns/AsyncSelect';
import { FilterMarketProps } from './FilterMarket.types';

const FilterMarket: React.FC<FilterMarketProps> = ({ changeFilters, market }) => {
  const cancelFunctions = useCancelRequest();

  const onLoadLookupData = async (dropdownName: string, query: string): Promise<[]> => {
    try {
      if (query.length < 2) {
        return [];
      }

      return await lookups.getMarketLookupSuggestions(cancelFunctions, query);
    } catch (error) {
      return [];
    }
  };

  return (
    <AsyncSelect
      dataTestId="market-search"
      name="market"
      loadData={onLoadLookupData}
      onSelect={(_: string, value: CodeNameModel) =>
        changeFilters({
          market: value,
        })
      }
      placeholder="Search market"
      selectedValue={market}
    />
  );
};

export default FilterMarket;
