import * as lookups from 'modules/api/lookups';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { CodeNameModel } from 'components/common/types';
import AsyncSelect from 'components/patterns/AsyncSelect';
import { FilterMultiDsps } from './FilterMultiDsp.types';

const FilterMultiDsp: React.FC<FilterMultiDsps> = ({ changeFilters, dsps, isDisabled }) => {
  const cancelFunctions = useCancelRequest();

  const onLoadLookupData = async (dropdownName: string, query: string): Promise<[]> => {
    try {
      if (query.length < 2) {
        return [];
      }

      return await lookups.getDspLookupSuggestions(cancelFunctions, query);
    } catch (error) {
      return [];
    }
  };

  return (
    <AsyncSelect
      dataTestId="dsps-filter"
      name="dsps"
      loadData={onLoadLookupData}
      selectedValue={dsps}
      placeholder="DSP"
      onSelect={(_: string, value: CodeNameModel[]) =>
        changeFilters({
          dsps: value,
        })
      }
      multiple
      isDisabled={isDisabled}
    />
  );
};

export default FilterMultiDsp;
