import { useState } from 'react';
import { InputShape, InputSize, InputTheme } from 'components/common/types/Input.types';
import { ShadowStyle } from 'consts/shadow';
import { Padding } from 'lib/BaseSelect/BaseSelect.types';
import { useSelector } from 'react-redux';
import { getIsReseller } from 'store/publisher/selectors';
import { InputContainer } from './utils';
import { SelectDealType } from './SelectDealType/SelectDealType';
import SelectAgency from './SelectAgency';
import SelectSpecialist from './SelectSpecialist';
import SelectSalesPerson from './SelectSalesPerson';
import SelectSalesTeam from './SelectSalesTeam';
import ExternalReference from './ExternalReference';
import OverrideDealId from './OverrideDealId';
import DealPriority from './DealPriority';
import { InputDealCpm } from './InputDealCpm/InputDealCpm';
import { SelectDsp } from './SelectDsp/SelectDsp';
import { SelectAdvertiser } from './SelectAdvertiser/SelectAdvertiser';
import { SelectBrand } from './SelectBrand/SelectBrand';
import { SelectProductCategory } from './SelectProductCategory/SelectProductCategory';

const Details: React.FC = () => {
  const isReseller = useSelector(getIsReseller);

  const [advertisers, setAdvertisers] = useState([]);
  const [brands, setBrands] = useState([]);

  return (
    <div className="border-b border-neutral-950-opacity-10 flex flex-col" data-test-id="drawer-details">
      <InputContainer>
        <SelectDealType />
      </InputContainer>

      {!isReseller && (
        <InputContainer>
          <SelectAdvertiser
            advertisers={advertisers}
            setAdvertisers={setAdvertisers}
            setBrands={setBrands}
            dataTestId="async-select-advertiser"
            placeholder=""
            theme={InputTheme.FLAT_GRAY}
            size={InputSize.EXTRA_SMALL}
            padding={Padding.NONE}
            shadow={ShadowStyle.NONE}
            shape={InputShape.ROUNDED}
            withLabel
          />

          <SelectBrand
            brands={brands}
            setAdvertisers={setAdvertisers}
            setBrands={setBrands}
            dataTestId="async-select-brand"
            placeholder=""
            theme={InputTheme.FLAT_GRAY}
            size={InputSize.EXTRA_SMALL}
            padding={Padding.NONE}
            shadow={ShadowStyle.NONE}
            shape={InputShape.ROUNDED}
            withLabel
          />

          <SelectProductCategory
            theme={InputTheme.FLAT_GRAY}
            size={InputSize.EXTRA_SMALL}
            padding={Padding.NONE}
            shadow={ShadowStyle.NONE}
            shape={InputShape.ROUNDED}
            dataTestId="async-select-product-category"
            withLabel
          />
        </InputContainer>
      )}

      <InputContainer>
        <SelectDsp />
      </InputContainer>

      <InputContainer>
        <SelectAgency />
        <SelectSpecialist />
        <ExternalReference />
        <OverrideDealId />
        <DealPriority />
        <SelectSalesPerson />
        <SelectSalesTeam />
      </InputContainer>

      <InputContainer>
        <InputDealCpm />
      </InputContainer>
    </div>
  );
};

export default Details;
