import Input from 'lib/Input';
import { FilterFrameCodeProps } from './FilterFrameCode.types';

const FilterFrameCode: React.FC<FilterFrameCodeProps> = ({ changeFilters, frameCode, placeholder }) => {
  return (
    <Input
      dataTestId="frame-code"
      name="frameCode"
      value={frameCode}
      placeholder={placeholder || 'Frame code'}
      onChange={(value: string) => {
        changeFilters({ frameCode: value });
      }}
      debounceTime={1000}
    />
  );
};

export default FilterFrameCode;
