import { useDispatch, useSelector } from 'react-redux';

import framePortraitSvg from 'assets/icons/frame-portrait.svg';
import bullhornSvg from 'assets/icons/bullhorn.svg';
import briefcaseSvg from 'assets/icons/briefcase.svg';
import wayfindingSvg from 'assets/icons/wayfinding.svg';
import tagSvg from 'assets/icons/tag.svg';
import imageSvg from 'assets/icons/image.svg';
import locationSvg from 'assets/icons/location.svg';
import proximitySvg from 'assets/icons/proximity.svg';
import targetSvg from 'assets/icons/target.svg';
import stopwatchSvg from 'assets/icons/stopwatch.svg';
import mapSvg from 'assets/icons/map.svg';
import moneySvg from 'assets/icons/money.svg';
import copyIcon from 'assets/icons/copy.svg';
import flagSvg from 'assets/icons/flag.svg';

import Auth from 'modules/Auth';
import { PermissionsEnum } from 'consts/permissions';
import { Code } from 'utils/framesUtils.types';
import { City, Country, County, PostCode, Street } from 'components/common/Deal/Location/Location.types';
import { DealStatus } from 'components/common/types/Deal.types';
import { FileList } from 'components/common/types/index';
import Card from 'components/patterns/Card';
import { CardType } from 'components/patterns/Card/Card.types';
import Input from 'lib/Input';
import { InputSize } from 'components/common/types/Input.types';
import Environment from 'components/common/Deal/Environment';
import List from 'components/common/Deal/List';
import VisualUnits from 'components/common/Deal/VisualUnits';
import Tags from 'components/common/Deal/Tags';
import Formats from 'components/common/Deal/Formats';
import Location from 'components/common/Deal/Location';
import Target from 'components/common/Deal/Target';
import AssetsMap from 'components/common/AssetsMap';
import { Store } from 'components/common/types/Store.types';
import { changeDealCurrentLineData } from 'store/dealManagement/reducer';
import { links } from 'components/common/Deal/Panel';
import AdsDealLineLevelCpm from 'components/pages/DealWithLines/common/Main/Deal/AdsDealLineLevelCpm/AdsDealLineLevelCpm';
import { getLineLevelCpmSettings } from 'components/pages/DealWithLines/common/Main/Deal/selectors';
import CpmFilter from 'components/pages/DealWithLines/common/Main/Line/CpmFilter/CpmFilter';
import { MediaOwner } from 'components/common/Deal/MediaOwner/MediaOwner';
import { FeatureFlags } from 'components/common/types/Features.types';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import { useDealFormReadOnly } from 'customHooks/useDealFormReadOnly';
import { getIsAdServer, getIsReseller } from 'store/publisher/selectors';
import Schedule from './Schedule';
import Proximity from './Proximity';
import VenueTaxonomy from './VenueTaxonomy';

// eslint-disable-next-line sonarjs/cognitive-complexity
const Line: React.FC = () => {
  const dispatch = useDispatch();

  const isAdServerMarket = useSelector(getIsAdServer);
  const isReseller = useSelector(getIsReseller);
  const { isLineLevelCpmVisible, isLineLevelCpmEditable } = useSelector(getLineLevelCpmSettings);
  const hasVenueTypeEnabled = useHasFeatureAccess(FeatureFlags.VENUE_TYPE) || isAdServerMarket;
  const isCpmCampaignLevel = useSelector((state: Store) => state.dealManagement.isCpmCampaignLevel);
  const bookingStatusCode = useSelector((state: Store) => state.dealManagement.commonDeal.bookingStatusCode);
  const lineName = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.name);
  const visualUnitCodes = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.visualUnitCodes);
  const visualUnitFiles = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.visualUnitFiles);
  const countries = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.countries);
  const streets = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.streets);
  const cities = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.cities);
  const postCodes = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.postCodes);
  const counties = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.counties);
  const availability = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.availability);
  const lineId = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.lineId);

  const readOnly = useDealFormReadOnly();
  const isPendingReservation = bookingStatusCode === DealStatus.PENDING_RESERVATION;
  const tagIsReadOnly = readOnly && !isPendingReservation;

  return (
    <div id="line-level">
      <Card
        icon={bullhornSvg}
        header="Line name"
        info="Add your deal name for a future easy searching"
        cardType={CardType.NONE}
        dataTestId="line-name-card"
        mandatory
      >
        <div className="relative">
          <Input
            dataTestId="line-name-input"
            placeholder="Type here..."
            name="dealLineName"
            value={lineName}
            size={InputSize.MEDIUM}
            onChange={(name: string) => dispatch(changeDealCurrentLineData({ name }))}
            debounceTime={1000}
            isDisabled={readOnly && !isPendingReservation}
          />
          {isAdServerMarket && lineId ? (
            <div className="absolute flex items-center top-0 px-2 py-1 right-0 max-w-64 border s border-neutral-300 bg-neutral-200 h-full overflow-hidden rounded-r-md">
              <span className="block body-sm w-full">
                Line ID: <span className="sub-header-sm">{lineId}</span>
              </span>
            </div>
          ) : null}
        </div>
      </Card>
      {Auth.hasPermission(PermissionsEnum.DEAL_RANGE_VIEW) ? (
        <Card
          icon={stopwatchSvg}
          header="Schedule"
          info="Select preferred days for your deal"
          id={links.schedule.id}
          dataTestId="line-schedule"
        >
          <Schedule />
        </Card>
      ) : null}
      {isReseller && (
        <Card
          icon={briefcaseSvg}
          header="Media owners"
          info="Select Media Owners in deal"
          id={links.mediaOwners.id}
          dataTestId="line-mediaOwners"
        >
          <MediaOwner />
        </Card>
      )}
      {Auth.hasPermission(PermissionsEnum.DEAL_ENVIRONMENT_VIEW) && !isReseller ? (
        <Card
          icon={wayfindingSvg}
          header="Environment"
          info="Select your environment locations"
          id={links.environments.id}
          dataTestId="line-environment"
          mandatory
        >
          <Environment />
        </Card>
      ) : null}
      {isReseller && (
        <Card
          icon={moneySvg}
          header="Frame CPM"
          info="Add a max frame CPM pricing"
          id={links.environments.id}
          dataTestId="line-environment"
          mandatory
        >
          <CpmFilter isDisabled={readOnly && !isPendingReservation} />
        </Card>
      )}
      {isLineLevelCpmVisible ? (
        <Card
          icon={moneySvg}
          header="CPM pricing"
          info="Add a unique CPM for this deal line"
          id={links.cpm.id}
          mandatory={!isCpmCampaignLevel}
        >
          <AdsDealLineLevelCpm isDisabled={(readOnly && !isPendingReservation) || !isLineLevelCpmEditable} />
        </Card>
      ) : null}
      {Auth.hasPermission(PermissionsEnum.DEAL_FORMATS_VIEW) && !isReseller ? (
        <Card
          icon={imageSvg}
          header="Formats"
          info="Select frames by format"
          id={links.formats.id}
          dataTestId="line-formats"
        >
          <Formats />
        </Card>
      ) : null}
      {Auth.hasPermission(PermissionsEnum.DEAL_FRAMES_LIST_VIEW) ? (
        <>
          {hasVenueTypeEnabled && (
            <Card
              icon={flagSvg}
              header="Venue taxonomy"
              info="Refine your available frames by the venue type"
              id={links.listOfFrames.id}
              dataTestId="line-venue-taxonomy"
            >
              <VenueTaxonomy readOnly={readOnly && !isPendingReservation} />
            </Card>
          )}
          <Card
            icon={framePortraitSvg}
            header="Frames"
            info="Choose frame(s) for this deal to run on"
            id={links.listOfFrames.id}
            dataTestId="line-frames"
          >
            <List />
          </Card>
        </>
      ) : null}
      {useHasFeatureAccess(FeatureFlags.VISUAL_UNITS) && isAdServerMarket ? (
        <Card
          icon={copyIcon}
          header="Visual units"
          id={links.visualUnits.id}
          info="Select visual unit codes individually or via upload to run across these groups of frames"
          dataTestId="line-visual-units"
        >
          <VisualUnits
            readOnly={readOnly && !isPendingReservation}
            visualUnitCodes={visualUnitCodes}
            onChangeVisualUnitCodes={(codes: Code[]) => dispatch(changeDealCurrentLineData({ visualUnitCodes: codes }))}
            visualUnitFiles={visualUnitFiles}
            onChangeVisualUnitFiles={(files: FileList[]) =>
              dispatch(changeDealCurrentLineData({ visualUnitFiles: files }))
            }
          />
        </Card>
      ) : null}
      {Auth.hasPermission(PermissionsEnum.DEAL_LOCATION_VIEW) && (
        <Card
          icon={locationSvg}
          header="Location"
          info="Have control over areas locations"
          id={links.location.id}
          dataTestId="line-location"
        >
          <Location
            onChangeCountries={(newCountries: Country[]) =>
              dispatch(changeDealCurrentLineData({ countries: newCountries }))
            }
            countries={countries}
            streets={streets}
            onChangeStreets={(newStreets: Street[]) => dispatch(changeDealCurrentLineData({ streets: newStreets }))}
            cities={cities}
            onChangeCities={(newCities: City[]) => dispatch(changeDealCurrentLineData({ cities: newCities }))}
            postCodes={postCodes}
            onChangePostCodes={(newPostCodes: PostCode[]) =>
              dispatch(changeDealCurrentLineData({ postCodes: newPostCodes }))
            }
            counties={counties}
            onChangeCounties={(newCounties: County[]) => dispatch(changeDealCurrentLineData({ counties: newCounties }))}
          />
        </Card>
      )}
      {!isAdServerMarket && Auth.hasPermission(PermissionsEnum.DEAL_PROXIMITY_VIEW) ? (
        <Card
          icon={proximitySvg}
          header="Proximity"
          info="Have control over areas locations"
          id={links.proximity.id}
          dataTestId="line-proximity"
        >
          <Proximity />
        </Card>
      ) : null}
      <Card
        icon={targetSvg}
        header="Target"
        info="Please refer to your deal summary for your booked solution"
        id={links.target.id}
        dataTestId="line-target"
      >
        <Target />
      </Card>
      {Auth.hasPermission(PermissionsEnum.DEAL_TAGS_VIEW) ? (
        <Card icon={tagSvg} header="Tags" info="Insert deal tags" id={links.tags.id} dataTestId="line-tags">
          <Tags readOnly={tagIsReadOnly} />
        </Card>
      ) : null}
      {useHasFeatureAccess(FeatureFlags.GOOGLEMAPS) ? (
        <Card icon={mapSvg} header="Map" info="Total frames by location" id={links.map.id} dataTestId="line-assets-map">
          <div className="h-125">
            <AssetsMap assets={availability.assets} />
          </div>
        </Card>
      ) : null}
    </div>
  );
};

export default Line;
