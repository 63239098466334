export enum PermissionsEnum {
  ADMINISTRATION_PAGE_ACCESS = 'administration:page_access',
  ADMINISTRATION_ASSIGN_MARKET = 'administration:assign_market',
  ADMINISTRATION_CREATIVE_DELETE = 'administration:creative:delete',
  ADMINISTRATION_CREATIVE_RESTORE = 'administration:creative:restore',
  CONTENT_APPROVE = 'content:approve',
  CONTENT_CREATIVE_CATEGORIES_EDIT = 'content:creative_categories:edit',
  CONTENT_CREATIVE_CATEGORIES_VIEW = 'content:creative_categories:view',
  CONTENT_CREATIVE_ID_VIEW = 'content:creative_id:view',
  CONTENT_DSP_VIEW = 'content:dsp:view',
  CONTENT_FLAG = 'content:flag',
  CONTENT_PAGE_ACCESS = 'content:page_access',
  CONTENT_REVIEW = 'content:review',
  CONTENT_REVERT_STATUS = 'content:revert_status',
  CONTENT_SEARCH = 'content:search',
  CONTENT_UPLOAD = 'content:upload',
  CONTENT_VIDEO_CONVERSION_STATUS_VIEW = 'content:video_conversion_status:view',
  CONTENT_WHITELIST_BACKLIST = 'content:whitelist_backlist',
  CONTENT_WHITELIST_BACKLIST_EXPORT = 'content:whitelist_backlist_export',
  CONTENT_MOTION_TOGGLE = 'content:motion_toggle',
  DASHBOARD_TIMEZONE_SELECT = 'dashboard:timezone:select',
  DEAL_ADVERTISER_VIEW = 'deal:advertiser:view',
  DEAL_AGENCY_VIEW = 'deal:agency:view',
  DEAL_AVAILABILITY_MAP_VIEW = 'deal:availability_map:view',
  DEAL_BRAND_VIEW = 'deal:brand:view',
  DEAL_BUDGET_VIEW = 'deal:budget:view',
  DEAL_CONFIRM = 'deal:confirm',
  DEAL_REJECT = 'deal:reject',
  DEAL_CREATE = 'deal:create',
  DEAL_ENVIRONMENT_VIEW = 'deal:environment:view',
  DEAL_FORMATS_VIEW = 'deal:formats:view',
  DEAL_FRAMES_LIST_VIEW = 'deal:frames_list:view',
  DEAL_IMPRESSIONS_VIEW = 'deal:impressions:view',
  DEAL_LEVEL_PERFORMANCE_PAGE_ACCESS = 'deal_level_performance:page_access',
  DEAL_LOCATION_VIEW = 'deal:location:view',
  DEAL_LOCATION_COUNTRY_VIEW = 'deal:location:country:view',
  DEAL_LOCATION_CITY_VIEW = 'deal:location:city:view',
  DEAL_LOCATION_POSTCODE_VIEW = 'deal:location:postcode:view',
  DEAL_LOCATION_STREET_VIEW = 'deal:location:street:view',
  DEAL_LOCATION_COUNTY_VIEW = 'deal:location:county:view',
  DEAL_PATTERNS_VIEW = 'deal:patterns:view',
  DEAL_PATTERNS_TIME_VIEW = 'deal:patterns:time:view',
  DEAL_PRODUCT_CATEGORY_VIEW = 'deal:product_category:view',
  DEAL_PROPOSE = 'deal:propose',
  DEAL_PROXIMITY_VIEW = 'deal:proximity:view',
  DEAL_RANGE_VIEW = 'deal:range:view',
  DEAL_SPECIALIST_VIEW = 'deal:specialist:view',
  DEAL_TAGS_VIEW = 'deal:tags:view',
  DEAL_TYPE_VIEW = 'deal:type:view',
  DEAL_TYPE_PRICE_VIEW = 'deal:type:price:view',
  DEAL_TYPE_PRICEIMPRESSION_VIEW = 'deal:type:priceimpression:view',
  DEAL_TYPE_NON_GUARANTEED_VIEW = 'deal:type:non_guaranteed:view',
  DEAL_MANAGEMENT_AUTOMATION_REFERENCE_VIEW = 'deal_management:automation_reference:view',
  DEAL_MANAGEMENT_REVENUE_VIEW = 'deal_management:revenue:view',
  DEAL_MANAGEMENT_PAGE_ACCESS = 'deal_management:page_access',
  DEAL_SAVE = 'deal:save',
  DIAGNOSTICS_PAGE_ACCESS = 'diagnostics:page_access',
  ROLES_CREATE = 'roles:create',
  ROLES_EDIT = 'roles:edit',
  ROLES_DELETE = 'roles:delete',
  SETUP_DOWNLOAD = 'setup:download',
  SETUP_PAGE_ACCESS = 'setup:page_access',
  SETUP_UPLOAD = 'setup:upload',
  USERS_CREATE = 'users:create',
  USERS_EDIT = 'users:edit',
  USERS_DELETE = 'users:delete',
  USERS_PAGE_ACCESS = 'users:page_access',
  USERS_ROLES = 'users:roles',
  ENVIRONMENT_SELECTION_PAGE_ACCESS = 'environment-selection:page_access',
  FEATURE_FLAG_TIMEZONE_UTC = 'feature_flag:timezone:utc',
  FEATURE_FLAG_DEAL_PROPOSAL = 'feature_flag:deal:proposal',
  INSIGHTS_YIELD_VIEW = 'insights:yield:view',
}
