import { CodeNameType } from 'components/common/types/AssetList.types';
import AsyncSelect from 'components/patterns/AsyncSelect';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { getTags } from 'modules/api/inventory';
import { formatFilterOptionsName } from 'utils/formatters';
import { FilterTagProps } from './FilterTag.types';

const FilterTag: React.FC<FilterTagProps> = ({ changeFilters, tag }) => {
  const cancelFunctions = useCancelRequest();

  const fetchFilterOptions = async (query: string): Promise<CodeNameType[]> => {
    if (query.length >= 3) {
      try {
        return await getTags(cancelFunctions, query);
      } catch (error) {
        return [];
      }
    }
    return [];
  };

  return (
    <AsyncSelect
      dataTestId="tag-input"
      name="tag"
      loadData={async (_name: string, query: string) => formatFilterOptionsName(await fetchFilterOptions(query))}
      onSelect={(_name: string, value: CodeNameType) => {
        changeFilters({ tag: value });
      }}
      placeholder="Tag"
      selectedValue={tag}
    />
  );
};

export default FilterTag;
