import { dealTypesList } from 'consts/dealTypesList';
import { usePageFilters } from 'customHooks/usePageFilters';
import {
  FilterAdvertiser,
  FilterBrand,
  FilterDealId,
  FilterDealType,
  FilterDateRange,
  FilterTradingModel,
  FilterTradingTime,
  FilterSpecialist,
  FilterAgency,
  FilterDsp,
} from 'components/common/FilterArea/Filters';
import FilterArea from 'components/common/FilterArea';
import { DealDashboardFilters } from './Filters.types';

const Filters: React.FC = () => {
  const {
    changeFilters,
    filters: { deal, dealType, startDate, endDate, tradingModel, time, dsp, brand, advertiser, agency, specialist },
  } = usePageFilters<DealDashboardFilters>();

  return (
    <FilterArea>
      <div className="grid grid-cols-3 gap-x-4 mr-4">
        <div className="flex flex-col gap-y-4">
          <FilterDealId changeFilters={changeFilters} deal={deal} />
          <FilterBrand changeFilters={changeFilters} brand={brand} />
        </div>
        <div className="flex flex-col gap-y-4">
          <FilterDsp selectedValue={dsp} onChange={(name, value) => changeFilters({ [name]: value })} />
          <FilterSpecialist specialist={specialist} changeFilters={changeFilters} />
        </div>
        <div className="flex flex-col gap-y-4">
          <FilterDealType dealType={dealType} changeFilters={changeFilters} dealTypesList={dealTypesList} />
        </div>
      </div>
      <div className="flex flex-row gap-x-4 justify-between">
        <div className="flex flex-col gap-y-4">
          <div className="flex flex-row gap-x-4 justify-start">
            <FilterAgency changeFilters={changeFilters} agency={agency} />
            <FilterAdvertiser changeFilters={changeFilters} selectedAdvertiser={advertiser} />
          </div>
          <FilterDateRange changeFilters={changeFilters} selectedStartDate={startDate} selectedEndDate={endDate} />
        </div>

        <div className="flex flex-col justify-end items-end gap-y-4">
          <FilterTradingModel selectedTradingModel={tradingModel} changeFilters={changeFilters} />
          <FilterTradingTime changeFilters={changeFilters} selectedTime={time} />
        </div>
      </div>
    </FilterArea>
  );
};

export default Filters;
