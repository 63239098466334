import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import SVG from 'react-inlinesvg';
import cx from 'classnames';
import { isArray, isNumber } from 'lodash';
import excludeSvg from 'assets/icons/exclude.svg';
import chevronUpSvg from 'assets/icons/chevron-up-thin.svg';
import chevronDownSvg from 'assets/icons/chevron-down-thin.svg';
import Chip from 'lib/Chip';
import { Color, TextPriority } from 'lib/Chip/Chip.types';
import {
  ButtonThemes,
  SectionButtonProps,
  SectionButtonRounded,
  SectionButtonShadow,
  SectionButtonTheme,
} from './SectionButton.types';
import SectionButtonMenu from './SectionButtonMenu';
import SectionButtonChipList from './SectionButtonChipList';

const SectionButton: React.FC<PropsWithChildren<SectionButtonProps>> = ({
  theme,
  chipColor = Color.PRIMARY,
  chipTextPriority = TextPriority.LABEL,
  dataTestId,
  icon,
  label,
  children,
  isActive,
  isDisabled,
  isRequired,
  selected,
  onClick,
  onClear,
  onDuplicate,
  areChipsAutoCollapsed = false,
  rounded = SectionButtonRounded.DEFAULT,
  shadow,
  dataPendoId,
  hasIndicator = false,
  clearButtonLabel,
  enableAccordionToggle = false,
  extendedChildren,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const [areChipsCollapsed, setAreChipsCollapsed] = useState(areChipsAutoCollapsed);
  const [isContentHidden, setIsContentHidden] = useState(false);

  const chipsLength = useMemo((): number | { included: number; excluded: number } => {
    if (!selected) return 0;

    if (isArray(selected)) return selected.length;

    let nonCategorisedCount = 0;
    const includeExcludeCount = { included: 0, excluded: 0 };

    Object.keys(selected).forEach((category) => {
      const chips = selected[category];

      if (isArray(chips)) nonCategorisedCount += chips.length;
      else {
        const { included, excluded } = chips;

        if (included) includeExcludeCount.included += included.length;
        if (excluded) includeExcludeCount.excluded += excluded.length;
      }
    });

    return nonCategorisedCount !== 0 ? nonCategorisedCount : includeExcludeCount;
  }, [selected]);

  useEffect(() => {
    setAreChipsCollapsed(areChipsAutoCollapsed);
  }, [areChipsAutoCollapsed]);

  const buttonContent = useMemo(() => {
    if (isContentHidden) return null;
    if (isNumber(chipsLength) && areChipsCollapsed) return <Chip label={chipsLength.toString()} color={chipColor} />;

    if (!isNumber(chipsLength) && areChipsCollapsed)
      return (
        <div className="flex align-middle items-end gap-1">
          {!!chipsLength.included && <Chip label={`${chipsLength.included}`} color={chipColor} />}
          {!!chipsLength.excluded && <Chip label={`${chipsLength.excluded}`} color={Color.GREY} icon={excludeSvg} />}
        </div>
      );

    if (selected)
      return (
        <SectionButtonChipList selected={selected} hasIndicator={hasIndicator} chipTextPriority={chipTextPriority} />
      );

    return children;
  }, [selected, chipsLength, areChipsCollapsed, children]);

  const hideContentButton = (): JSX.Element => {
    const onHideContentClick = (
      e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>,
    ): void => {
      e.stopPropagation();
      setIsContentHidden(!isContentHidden);
    };

    return (
      <div role="presentation" onClick={onHideContentClick} className="flex items-center w-6 h-6">
        <SVG className="fill-current w-4 h-4 text-neutral-900" src={isContentHidden ? chevronDownSvg : chevronUpSvg} />
      </div>
    );
  };

  const shadowStyle =
    shadow || (theme === SectionButtonTheme.SOLID ? SectionButtonShadow.DEFAULT : SectionButtonShadow.NONE);

  return (
    <div
      className={cx('flex flex-col p-2 border max-w-[319px]', rounded, shadowStyle, {
        'ring-1 border-primary-600 ring-primary-600 bg-primary-50': isActive,
        [ButtonThemes[theme].inactive]: !isActive,
        [ButtonThemes[theme].buttonContainer]: true,
        'bg-neutral-200 border-neutral-500': isDisabled,
      })}
    >
      <button
        data-test-id={dataTestId}
        data-pendo-id={dataPendoId || null}
        type="button"
        className="relative flex w-full gap-1 disabled:cursor-not-allowed"
        disabled={isDisabled}
        onClick={() => onClick?.()}
      >
        <div className="flex flex-row items-center gap-1 py-0.5">
          {icon ? (
            <SVG
              className={cx('fill-current w-4 h-4', {
                'text-neutral-500': isDisabled,
                [ButtonThemes[theme].iconColor]: !isDisabled,
              })}
              src={icon}
            />
          ) : null}
          <span
            className={cx('sub-header-base whitespace-nowrap', {
              'text-neutral-500': isDisabled,
              [ButtonThemes[theme].textColor]: !isDisabled,
            })}
          >
            {label}
          </span>
        </div>
        <div className="flex flex-1 justify-end">{buttonContent}</div>
        {!selected && isRequired ? (
          <div className="flex h-full self-center justify-end">
            <span className="text-primary">Required</span>
          </div>
        ) : null}
        {onClear ? (
          <SectionButtonMenu
            onClear={onClear}
            clearButtonLabel={clearButtonLabel}
            onToggle={() => setAreChipsCollapsed(!areChipsCollapsed)}
            onDuplicate={onDuplicate}
            isToggleDisabled={
              (isNumber(chipsLength) && chipsLength <= 1) ||
              (!isNumber(chipsLength) && chipsLength.included <= 1 && chipsLength.excluded <= 1)
            }
            isCollapsed={areChipsCollapsed}
          />
        ) : null}
        {enableAccordionToggle ? hideContentButton() : null}
      </button>
      {!isContentHidden ? extendedChildren : null}
    </div>
  );
};

export default SectionButton;
