import cx from 'classnames';
import { clamp } from 'lodash';
import { BarBackgroundColor, ProgressBarProps } from './ProgressBar.types';

export const ProgressBar: React.FC<ProgressBarProps> = ({
  barColor,
  barBackgroundColor = BarBackgroundColor.LIGHT_PRIMARY_PURPLE,
  id,
  completed = 0,
  max = 100,
  indicatorPosition,
  barLegend,
}) => {
  const progress = (completed * 100) / max;
  const indicatorPlacement = indicatorPosition ? (indicatorPosition * 100) / max : 0;

  return (
    <div
      className={cx({
        'flex flex-col ': barLegend,
      })}
    >
      <div
        className={cx('h-1 w-full text-neutral-950-opacity-30 rounded relative', barBackgroundColor)}
        data-test-id={`progress-bar-${id}`}
      >
        <div
          className={cx('h-full rounded text-right', barColor)}
          data-test-id={`progress-${id}`}
          style={{
            width: `${clamp(progress, 0, 100)}%`,
          }}
        />
        {!!indicatorPosition && (
          <div
            className="absolute w-0.5 h-3 bg-primary-900 -bottom-1"
            data-test-id={`indicator-${id}`}
            style={{ left: `${clamp(indicatorPlacement, 0, 100)}%` }}
          />
        )}
      </div>
      {!!barLegend && (
        <div className="flex items-center gap-5 pt-2">
          <div className="flex justify-center items-center gap-1.5">
            <div className={cx('w-2 h-2 rounded-full', barColor)} />
            <span className="body-sm text-essential-tertiary">{barLegend.barColorDescription}</span>
          </div>
          {!!barLegend.indicatorDescription && (
            <div className="flex justify-center items-center gap-1">
              <div className="w-0.5 h-3 bg-primary-900" />
              <span className="body-sm text-essential-tertiary">{barLegend.indicatorDescription}</span>
            </div>
          )}
          <div className="flex justify-center items-center gap-1">
            <div className={cx('w-2 h-2 rounded-full', barBackgroundColor)} />
            <span className="body-sm text-essential-tertiary">{barLegend.barBackgroundDescription}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProgressBar;
