import { useParams } from 'react-router-dom';

import { useDealManagement } from 'store/dealManagement/useDealManagement';
import { useCpmCampaignLevel } from 'components/pages/DealWithLines/useCpmCampaignLevel';

import { PageWrapper } from 'components/common/layout/PageWrapper/PageWrapper';
import { getIsAdServer } from 'store/publisher/selectors';
import { useSelector } from 'react-redux';
import DealLines from './DealLines';
import AdsDealLines from './AdsDealLines';

const DealWithLines = () => {
  const { internalId } = useParams();
  const isAdServerMarket = useSelector(getIsAdServer);

  useDealManagement({ isNewDeal: !internalId });
  useCpmCampaignLevel();

  return <PageWrapper>{isAdServerMarket ? <AdsDealLines /> : <DealLines />}</PageWrapper>;
};

export default DealWithLines;
