import { useCreativeBidStream } from 'components/common/Deal/CreativeBidStream/useCreativeBidStream';
import { InputTheme } from 'components/common/types/Input.types';
import ToggleSwitch, { ToggleTheme } from 'components/patterns/ToggleSwitch';
import { Label } from 'lib/Label';

const BidsSubmissionSwitch: React.FC = () => {
  const {
    handleToggle,
    isToggleDisabled,
    isCreativeSubmissionInBidStreamEnabled,
    isSelectedDspSupportingCreativeSubmissionInBidStream,
  } = useCreativeBidStream();

  if (!isSelectedDspSupportingCreativeSubmissionInBidStream) return null;

  return (
    <div className="flex justify-between items-center h-8">
      <Label theme={InputTheme.FLAT_GRAY} label="Allow creative submission" />
      <ToggleSwitch
        onChange={handleToggle}
        isChecked={isCreativeSubmissionInBidStreamEnabled}
        isDisabled={isToggleDisabled}
        dataTestId="bids-submission-toggle"
        theme={ToggleTheme.SMALL_LIGHT}
      />
    </div>
  );
};

export default BidsSubmissionSwitch;
