import { FilterDateRange } from 'components/common/FilterArea/Filters';
import FilterAssetType from 'components/pages/InventoryManagement/AssetList/AssetContent/Filters/FilterAssetType';
import FilterBusinessArea from 'components/pages/InventoryManagement/AssetList/AssetContent/Filters/AdvancedFilters/FilterBusinessArea';
import FilterChannel from 'components/pages/InventoryManagement/AssetList/AssetContent/Filters/AdvancedFilters/FilterChannel';
import FilterNetwork from 'components/pages/InventoryManagement/AssetList/AssetContent/Filters/AdvancedFilters/FilterNetwork';
import FilterTag from 'components/pages/InventoryManagement/AssetList/AssetContent/Filters/AdvancedFilters/FilterTag';
import FilterVisualUnitCodeAsync from 'components/pages/InventoryManagement/AssetList/AssetContent/Filters/AdvancedFilters/FilterVisualUnitCodeAsync';
import { AssetListFilters } from 'components/common/types/AssetList.types';
import { usePageFilters } from 'customHooks/usePageFilters';

const AdvancedFilters: React.FC = () => {
  const {
    filters: { channel, businessArea, visualUnit, tag, network, assetType, startDate, endDate },
    changeFilters,
  } = usePageFilters<AssetListFilters>();

  return (
    <>
      <div className="grid grid-cols-2 gap-4 mr-4 mt-4">
        <FilterChannel changeFilters={changeFilters} channel={channel} />
        <FilterBusinessArea changeFilters={changeFilters} businessArea={businessArea} />
      </div>
      <div className="grid grid-cols-2 gap-4 mt-4">
        <FilterVisualUnitCodeAsync changeFilters={changeFilters} visualUnit={visualUnit} />
        <FilterAssetType changeFilters={changeFilters} assetType={assetType} />
      </div>
      <div className="grid grid-cols-2 gap-4 mr-4 mt-4">
        <FilterTag changeFilters={changeFilters} tag={tag} />
        <FilterNetwork changeFilters={changeFilters} network={network} />
      </div>
      <div className="mt-4">
        <FilterDateRange
          changeFilters={changeFilters}
          selectedEndDate={endDate}
          selectedStartDate={startDate}
          isDisabled={!tag && !network}
          placeholderEndDate="End date"
          placeholderStartDate="Start date"
        />
      </div>
    </>
  );
};

export default AdvancedFilters;
