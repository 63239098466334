import React from 'react';
import cx from 'classnames';
import Button, { Color, Size } from 'lib/Button';
import { DealStatus } from 'components/common/types/Deal.types';
import allocationReportSVG from 'assets/icons/allocation-report.svg';
import { ReportTypeButtonsProps } from './ReportTypeButtons.types';
import { ReportType } from '../AllocationReport.types';

export const ReportTypeButtons: React.FC<ReportTypeButtonsProps> = ({
  activeReportType,
  handleFetchAllocationReport,
  isSelectedLineWithNewProposal,
  selectedLine,
}) => {
  const isNewLine = !selectedLine.lineId;

  const showNewProposalButton = isNewLine || isSelectedLineWithNewProposal;
  const showProposalButton =
    !isNewLine &&
    !!selectedLine.lineStatus &&
    [DealStatus.PENDING_APPROVAL, DealStatus.CANCELLED].includes(selectedLine.lineStatus);
  const showAllocationButton =
    !isNewLine &&
    !!selectedLine.lineStatus &&
    [DealStatus.APPROVED, DealStatus.LIVE, DealStatus.TERMINATED, DealStatus.ENDED].includes(selectedLine.lineStatus);
  const allowButtonClick = showNewProposalButton && (showProposalButton || showAllocationButton);

  const reportTypeButtons = [
    {
      id: 'new-proposal',
      isActive: activeReportType === ReportType.PROPOSAL,
      isOnClickEnabled: allowButtonClick && activeReportType !== ReportType.PROPOSAL,
      isVisible: showNewProposalButton,
      label: 'New Proposal',
      onClick: () => handleFetchAllocationReport(ReportType.PROPOSAL),
    },
    {
      id: 'proposal',
      isActive: activeReportType === ReportType.FINAL,
      isOnClickEnabled: allowButtonClick && activeReportType !== ReportType.FINAL,
      isVisible: showProposalButton,
      label: 'Proposal',
      onClick: () => handleFetchAllocationReport(ReportType.FINAL),
    },
    {
      id: 'allocation',
      isActive: activeReportType === ReportType.FINAL,
      isOnClickEnabled: allowButtonClick && activeReportType !== ReportType.FINAL,
      isVisible: showAllocationButton,
      label: 'Allocation',
      onClick: () => handleFetchAllocationReport(ReportType.FINAL),
    },
  ];

  return (
    <div className="flex gap-2">
      {reportTypeButtons.map(
        ({ id, isActive, isOnClickEnabled, isVisible, label, onClick }) =>
          isVisible && (
            <React.Fragment key={id}>
              <Button
                onClick={isOnClickEnabled ? onClick : undefined}
                color={isActive ? Color.TERTIARY : Color.TRANSPARENT}
                size={Size.SMALL}
                label={label}
                classNames={cx('border', {
                  'border-primary-600': isActive,
                  'cursor-default': !isOnClickEnabled,
                })}
                ddActionName={`Allocation report / ${label} chip`}
                svg={allocationReportSVG}
              />
            </React.Fragment>
          ),
      )}
    </div>
  );
};
