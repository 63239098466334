import cx from 'classnames';
import SVG from 'react-inlinesvg';
import { ButtonSize, IconButtonProps, IconButtonSize, IconButtonTheme, Theme } from './IconButton.types';

const IconButton: React.FC<IconButtonProps> = ({
  icon,
  name,
  isDisabled = false,
  handleOnClick,
  theme = Theme.PLAIN,
  buttonSize = ButtonSize.SMALL,
  dataTestId,
}) => {
  return (
    <button
      type="button"
      aria-label={name}
      className={cx(`flex justify-center items-center border ${IconButtonSize[buttonSize].buttonStyle}`, {
        'bg-neutral-200': isDisabled,
        [IconButtonTheme[theme].buttonStyle]: !isDisabled,
      })}
      onClick={handleOnClick}
      disabled={isDisabled}
      data-test-id={dataTestId}
    >
      <SVG
        src={icon}
        className={cx(IconButtonSize[buttonSize].iconStyle, {
          'text-neutral-400': isDisabled,
          [IconButtonTheme[theme].iconColor]: !isDisabled,
        })}
      />
    </button>
  );
};

export default IconButton;
