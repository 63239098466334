import PropTypes from 'prop-types';

import { DOUBLE_DASH } from 'consts/placeholders';
import Timeline, { TimelineItem } from 'components/patterns/Timeline';
import { ProhibitionModel } from 'components/common/prop-types/inventory';

import ProhibitionInfo from './ProhibitionInfo';
import { Button as ReadMoreButton } from '../ReadMoreSections';

const Prohibitions = ({ prohibitions, toggleReadMore, maxRecordsToShow }) => {
  return (
    <div>
      <h2 className="mb-8 sub-header-base">Prohibitions</h2>
      <div className="pl-2" data-test-id="prohibition-container">
        <Timeline>
          {prohibitions.length ? (
            <ProhibitionInfo prohibitionGroup={prohibitions.slice(0, maxRecordsToShow)} />
          ) : (
            <TimelineItem title={<p className="body-base">{DOUBLE_DASH}</p>} />
          )}
        </Timeline>
        {prohibitions.length > maxRecordsToShow ? <ReadMoreButton onClick={toggleReadMore} /> : null}
      </div>
    </div>
  );
};

Prohibitions.propTypes = {
  prohibitions: PropTypes.arrayOf(ProhibitionModel),
  toggleReadMore: PropTypes.func,
  maxRecordsToShow: PropTypes.number.isRequired,
};

Prohibitions.defaultProps = {
  prohibitions: [],
  toggleReadMore: () => null,
};

export default Prohibitions;
