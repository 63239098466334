import { Store } from 'components/common/types/Store.types';
import { useDispatch, useSelector } from 'react-redux';
import { changePlannerSecondaryPanelType, PlannerSecondaryPanelType } from 'store/dealManagement/reducer';

type UseOnChangePanelVisibilityReturnType = (nextPanelType: PlannerSecondaryPanelType) => ReturnType<VoidFunction>;

export const useOnChangePanelVisibility = (): UseOnChangePanelVisibilityReturnType => {
  const dispatch = useDispatch();
  const currentPanelType = useSelector((state: Store) => state.dealManagement.plannerSecondaryPanelType);

  return (nextPanelType: PlannerSecondaryPanelType) => {
    if (!nextPanelType || currentPanelType === nextPanelType) {
      return dispatch(changePlannerSecondaryPanelType(PlannerSecondaryPanelType.NONE));
    }

    return dispatch(changePlannerSecondaryPanelType(nextPanelType));
  };
};
