import { PropsWithChildren, useCallback, useState } from 'react';
import SVG from 'react-inlinesvg';
import cx from 'classnames';
import plusSvg from 'assets/icons/planner_plus.svg';
import minusSvg from 'assets/icons/planner_minus.svg';
import { AccordionProps, BorderStyle } from './Accordion.types';

const Accordion: React.FC<PropsWithChildren<AccordionProps>> = ({
  children,
  dataTestId,
  label,
  onOpen,
  onClose,
  border = BorderStyle.NONE,
  isDisabled = false,
  isBorderHiddenWhenOpen = false,
  isOpenOnInit = false,
  icon,
  labelClassNames,
}) => {
  const [isOpen, setIsOpen] = useState(isOpenOnInit);

  const handleClick = useCallback(() => {
    if (isOpen) {
      onClose?.();
    } else {
      onOpen?.();
    }

    setIsOpen(!isOpen);
  }, [isOpen]);

  const borderStyle = isBorderHiddenWhenOpen && isOpen ? BorderStyle.NONE : border;

  return (
    <div className={`${borderStyle}`} data-test-id={dataTestId}>
      <button
        type="button"
        onClick={handleClick}
        disabled={isDisabled}
        className="w-full py-4 flex justify-between items-center sub-header-base"
      >
        <div className={cx('flex gap-1 items-center', labelClassNames)}>
          {!!icon && <SVG className="w-4 h-4" src={icon} />}
          {label}
        </div>
        <SVG className="w-4 h-4 text-neutral-800 font-light" src={isOpen ? minusSvg : plusSvg} />
      </button>
      {isOpen && children}
    </div>
  );
};

export default Accordion;
