import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import { FeatureFlags } from 'components/common/types/Features.types';
import { changeCampaignType } from 'store/dealManagement/reducer';
import { Store } from 'components/common/types/Store.types';
import { CampaignType } from 'components/pages/Planner/PlannerSections/types';
import { UseCampaignTypeSwitch } from './useCampaignTypeSwitch.types';
import useCampaignType from '../../hooks/useCampaignType';

const PLANNER_URLS = {
  [CampaignType.DIRECT]: '/planner/direct-sales',
  [CampaignType.PROGRAMMATIC]: '/planner/programmatic',
  [CampaignType.DIRECT_MTB_EXPORT]: '/planner/programmatic',
};

export const useCampaignTypeSwitch = (): UseCampaignTypeSwitch => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isNewDeal = useSelector((state: Store) => state.dealManagement.isNewDeal);
  const campaignType = useSelector((state: Store) => state.dealManagement.campaignType);
  const { isMtbCampaignType } = useCampaignType();
  const hasMTBExportEnabled = useHasFeatureAccess(FeatureFlags.MTB_EXPORT);
  const hasDirectSalesPlannerEnabled = useHasFeatureAccess(FeatureFlags.DIRECT_SALES_PLANNER);

  useEffect(() => {
    if (!isNewDeal || isMtbCampaignType) return;

    if (hasDirectSalesPlannerEnabled && pathname.includes(PLANNER_URLS[CampaignType.DIRECT])) {
      dispatch(changeCampaignType(CampaignType.DIRECT));
      return;
    }

    dispatch(changeCampaignType(CampaignType.PROGRAMMATIC));
  }, [pathname, isNewDeal]);

  const onChange = (value: CampaignType): void => {
    navigate(PLANNER_URLS[value]);
    dispatch(changeCampaignType(value));
  };

  const options = [
    { text: 'Programmatic', value: CampaignType.PROGRAMMATIC, isActive: true },
    { text: 'MTB Export', value: CampaignType.DIRECT_MTB_EXPORT, isActive: hasMTBExportEnabled },
    { text: 'Direct', value: CampaignType.DIRECT, isActive: hasDirectSalesPlannerEnabled },
  ].filter((option) => option.isActive);

  return {
    campaignType,
    isNewDeal,
    onChange,
    options,
  };
};
