import cx from 'classnames';
import React, { PropsWithChildren, useCallback, useState } from 'react';
import SVG from 'react-inlinesvg';

import chevronDownSvg from 'assets/icons/chevron_down.svg';
import filterSvg from 'assets/icons/filter.svg';
import Button, { Color } from 'lib/Button';
import Card from 'lib/Card';
import { CardTheme } from 'lib/Card/Card.types';

interface FilterAreaProps {
  dataTestId?: string;
  advancedFilters?: JSX.Element;
  hideIcon?: boolean;
  cardTheme?: CardTheme;
  customWrapperStyles?: string;
}

const FilterArea: React.FC<PropsWithChildren<FilterAreaProps>> = ({
  children,
  advancedFilters,
  dataTestId,
  hideIcon,
  cardTheme = CardTheme.SECONDARY,
  customWrapperStyles,
}): React.ReactElement => {
  const [isAdvancedFiltersOpen, setIsAdvancedFiltersOpen] = useState(false);

  const toggleAdvancedFilters = useCallback(() => {
    setIsAdvancedFiltersOpen((prevState) => !prevState);
  }, []);

  return (
    <Card theme={cardTheme}>
      <div data-test-id={dataTestId} className="flex p-4">
        {!hideIcon && (
          <div className="self-center m-3 mr-6">
            <SVG src={filterSvg} className="text-neutral-400" />
          </div>
        )}
        <div className={customWrapperStyles ?? 'grid grid-cols-2 flex-1'}>
          {children}
          {isAdvancedFiltersOpen && advancedFilters}
        </div>
        {!!advancedFilters && (
          <div>
            <Button
              dataTestId="advanced-filter-button"
              color={Color.TRANSPARENT}
              classNames="m-0.5 ml-1 rounded-md"
              svg={chevronDownSvg}
              svgClassnames={cx('transform text-neutral-950-opacity-70', { 'rotate-180': isAdvancedFiltersOpen })}
              onClick={toggleAdvancedFilters}
            />
          </div>
        )}
      </div>
    </Card>
  );
};

export default FilterArea;
