import { useState } from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
import { DealStatus } from 'components/common/types/Deal.types';
import PlannerSecondaryPanel from 'components/pages/Planner/PlannerSecondaryPanel';
import { hidePlannerSecondaryPanel } from 'store/dealManagement/reducer';
import { StatusOption } from './CampaignBookingStatus.types';

interface CampaignBookingStatusPanelProps {
  onConfirm: (status: DealStatus) => void;
  statusOptions: StatusOption[];
}

export const CampaignBookingStatusPanel: React.FC<CampaignBookingStatusPanelProps> = ({ onConfirm, statusOptions }) => {
  const dispatch = useDispatch();

  const [selectedStatus, setSelectedStatus] = useState<StatusOption | undefined>();

  const handleCancel = (): void => {
    dispatch(hidePlannerSecondaryPanel());
  };

  const handleSubmit = (): void => {
    if (selectedStatus?.code) {
      onConfirm(selectedStatus.code as DealStatus);
      dispatch(hidePlannerSecondaryPanel());
    }
  };

  const handleSelectStatus = (selectedStatusOption: StatusOption) => () => {
    setSelectedStatus(selectedStatusOption);
  };

  return (
    <PlannerSecondaryPanel
      confirmButtonLabel="Update status"
      customPosition={{ left: '416', top: '54' }}
      isDisabled={!selectedStatus?.code}
      isLineLevel={false}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
      title="Campaign Status"
    >
      <div className="flex flex-col gap-0.5" data-test-id="status-options">
        {statusOptions.map(
          (option) =>
            option.isVisible && (
              <button
                className={cx('p-[8px] body-base text-left', {
                  'bg-primary-100': selectedStatus?.code === option.code,
                  'cursor-not-allowed text-neutral-600': option.isDisabled,
                })}
                disabled={option.isDisabled}
                key={option.code}
                onClick={handleSelectStatus(option)}
                type="button"
              >
                {option.name}
              </button>
            ),
        )}
      </div>
    </PlannerSecondaryPanel>
  );
};
