import { useEffect, useMemo, useState } from 'react';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import BaseSelect from 'lib/BaseSelect';
import { getAssetTypes } from 'modules/api/inventory';
import { findOption, transformOptions } from 'lib/BaseSelect/utils';
import { OptionType } from 'lib/BaseSelect/BaseSelect.types';
import { AssetTypes, CodeType } from 'components/common/types/AssetList.types';
import { CodeNameModel } from 'components/common/types';
import { FilterAssetTypeProps } from './FilterAssetType.types';

const FilterAssetType: React.FC<FilterAssetTypeProps> = ({ assetType, changeFilters }) => {
  const cancelFunctions = useCancelRequest();
  const [assetTypeOptions, setAssetTypeOptions] = useState<OptionType[]>([]);

  const selectedAssetType = useMemo(
    () => findOption(assetTypeOptions, 'code', assetType?.code),
    [assetTypeOptions, assetType],
  );

  const handleSelectAssetType = (_name: string, value: CodeNameModel): void => {
    changeFilters({ assetType: (value?.code ? { code: value.code } : null) as CodeType<AssetTypes> });
  };

  useEffect(() => {
    const fetchAssetTypes = async (): Promise<void> => {
      try {
        const response = await getAssetTypes(cancelFunctions);
        const assetTypes = transformOptions(response, { codeKey: 'code', nameKey: 'code' });

        setAssetTypeOptions(assetTypes);
      } catch (error) {
        setAssetTypeOptions([]);
      }
    };

    fetchAssetTypes();
  }, []);

  return (
    <BaseSelect
      dataTestId="asset-types"
      name="assetType"
      options={assetTypeOptions}
      selectedValue={selectedAssetType}
      onSelect={handleSelectAssetType}
      placeholder="Select asset type"
      isClearable
    />
  );
};

export default FilterAssetType;
