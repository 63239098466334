import cx from 'classnames';
import IconButton from 'lib/IconButton';
import React, { PropsWithChildren } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import Button, { Color, Size as SizeButton } from 'lib/Button';
import sidebarSimpleRightIcon from 'assets/icons/sidebar-simple-right.svg';
// import locationsIcon from 'assets/icons/locations.svg';
import { Line } from 'components/common/types/Deal.types';
import { Store } from 'components/common/types/Store.types';
import { usePlannerHeaderLineContainer } from 'components/pages/Planner/hooks/usePlannerHeaderLineContainer';
import {
  changeCurrentLine,
  changeDealData,
  createNewLineWithUniqueName,
  removeTemporaryDealId,
} from 'store/dealManagement/reducer';
import { CampaignNameAndStatus } from './CampaignDrawer/CampaignNameAndStatus/CampaignNameAndStatus';
import { DealLines } from './DealLines';

interface Props {
  isAddNewLineDisabled?: boolean;
  isPlannerHeader?: boolean;
  customCampaignDrawerToggle?: () => void; // TODO: Temporary, remove after both ADS-6831 and ADS-6897 are implemented
}

const CampaignHeader: React.FC<PropsWithChildren<Props>> = ({
  isAddNewLineDisabled = false,
  isPlannerHeader = false,
  customCampaignDrawerToggle,
  children, // TODO: Temporary, remove after both ADS-6831 and ADS-6897 are implemented
}) => {
  const dispatch = useDispatch();
  const currentDealSummary = useSelector((state: Store) => state.dealManagement.commonDeal.summary);
  const isCampaignDrawerOpen = useSelector((state: Store) => state.dealManagement.isCampaignDrawerOpen);
  const { headerContainerRef, displayDealLinesDropdown, displayedLines, allLinesSaved, hasReachedMaxLines } =
    usePlannerHeaderLineContainer();

  const onNewLineClick = (): void => {
    dispatch(createNewLineWithUniqueName());
  };

  const onLineSelect = (line: Line): void => {
    dispatch(changeCurrentLine(line.id));
    dispatch(removeTemporaryDealId());
    dispatch(changeDealData({ summary: currentDealSummary }));
  };

  return (
    <header
      className="bg-neutral-50 border-t border-neutral-950-opacity-10 flex items-center sticky top-0 bg-white z-10"
      data-test-id="unified-header"
    >
      <div
        className={cx('border-l flex items-center border-neutral-950-opacity-10 h-full', {
          'border-r': isCampaignDrawerOpen,
          'border-b pl-[1px]': !isCampaignDrawerOpen,
        })}
      >
        <CampaignNameAndStatus
          customCampaignDrawerToggle={customCampaignDrawerToggle}
          isPlannerHeader={isPlannerHeader}
        />
      </div>
      <div
        data-test-id="unified-header-lines-list"
        className="whitespace-nowrap flex flex-1 align-items-center space-x-2 py-2 px-[16px] border-b border-neutral-950-opacity-10"
        ref={headerContainerRef}
      >
        <DealLines
          displayedLines={displayedLines}
          displayDealLinesDropdown={displayDealLinesDropdown}
          isAddNewLineDisabled={!allLinesSaved || hasReachedMaxLines || isAddNewLineDisabled || isPlannerHeader} // TODO: Adjust once the logic for adding lines is ready (ADS-6894)
          isSelectLineDisabled={!allLinesSaved}
          onLineSelect={onLineSelect}
          onNewLineClick={onNewLineClick}
          addNewLineLabel="New deal line"
        />
      </div>
      <div className="flex items-center h-full border-b border-neutral-950-opacity-10">
        <div className="flex flex-shrink-0 gap-1 px-[8px] justify-end items-center border-l border-neutral-950-opacity-10 w-[350px]">
          {children}
          {/* Hidden until switching view functionality is enabled */}
          {/* <Button color={Color.BLACK_AND_WHITE} size={SizeButton.SMALL} svg={locationsIcon} label="Open in planner" /> */}
          <IconButton icon={sidebarSimpleRightIcon} name="Toggle right sidebar" />
        </div>
      </div>
    </header>
  );
};

export default CampaignHeader;
