import { useDispatch, useSelector } from 'react-redux';
import differenceBy from 'lodash/differenceBy';
import Session from 'modules/Session';
import { addProductFormat, deleteProductFormat, getProductFormats } from 'modules/api/content';
import { notifyError } from 'store/notification/reducer';
import { addProductFormats, deleteProductFormats } from 'store/creative/reducer';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { CodeNameModel } from 'components/common/types';
import { Store } from 'components/common/types/Store.types';
import AsyncSelect from 'components/patterns/AsyncSelect';
import Chips from 'components/patterns/Chips';

type ProductFormatsProps = {
  onTaggingRulesChange: () => Promise<void>;
  isDisabled?: boolean;
};

const ProductFormats: React.FC<ProductFormatsProps> = ({ isDisabled, onTaggingRulesChange }) => {
  const dispatch = useDispatch();
  const cancelFunctions = useCancelRequest();

  const marketId = useSelector((state: Store) => state.creative.marketId);
  const productFormat = useSelector((state: Store) => state.creative.productFormat);

  const onLoadLookupData = async (_dropdownName: string, query: string): Promise<CodeNameModel[]> => {
    try {
      const productFormatOptions: CodeNameModel[] = await getProductFormats(
        cancelFunctions,
        Session.getEnvironmentId(),
        query,
      );
      return differenceBy(productFormatOptions, productFormat, 'id');
    } catch (error) {
      return [];
    }
  };

  const onProductFormatChange = async (newValue: CodeNameModel[]): Promise<void> => {
    try {
      await addProductFormat(cancelFunctions, marketId, newValue[0].id);
      dispatch(addProductFormats(newValue[0]));
      onTaggingRulesChange();
    } catch (error) {
      dispatch(notifyError({ message: error.message }));
    }
  };

  const removeProductFormat = async (item: CodeNameModel): Promise<void> => {
    try {
      await deleteProductFormat(cancelFunctions, marketId, item.id);
      dispatch(deleteProductFormats(item.id ?? ''));
      onTaggingRulesChange();
    } catch (error) {
      dispatch(notifyError({ message: error.message }));
    }
  };

  return (
    <div>
      <div className={`${productFormat ? 'mb-3' : ''}`} data-test-id="product-format">
        <AsyncSelect
          dataTestId="product-format-input"
          name="productFormat"
          loadData={onLoadLookupData}
          selectedValue={null}
          placeholder="Select a product format"
          onSelect={(_dropdownName: string, newValue: CodeNameModel[]) => onProductFormatChange(newValue)}
          multiple
          isClearable={false}
          isMultiValueVisible={false}
          isDisabled={isDisabled}
        />
      </div>
      <div>
        {productFormat.length > 0 && (
          <Chips
            dataList={productFormat}
            idKey="code"
            labelKey="name"
            isClearAllVisible={false}
            onRemove={removeProductFormat}
            isDisabled={isDisabled}
            dataTestId="product-format-chips"
          />
        )}
      </div>
    </div>
  );
};

export default ProductFormats;
