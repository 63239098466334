import Input from 'lib/Input';
import { CodeNameModel } from 'components/common/types';
import FilterNetworkPriceDefinition from 'components/common/FilterArea/Filters/FilterNetworkPriceDefinition/FilterNetworkPriceDefinition';
import FilterNetworkGroup from 'components/common/FilterArea/Filters/FilterNetworkGroup/FilterNetworkGroup';
import FilterAllOrInUseToggle from 'components/common/FilterArea/Filters/FilterAllOrInUseToggle';
import FilterArea from 'components/common/FilterArea';
import { usePageFilters } from 'customHooks/usePageFilters';

export type NetworksFilters = {
  networkCode?: string;
  networkName?: string;
  networkGroupName?: CodeNameModel;
  networkPriceDefinition?: CodeNameModel;
  inUse?: boolean | '';
};

const Filters: React.FC = () => {
  const {
    changeFilters,
    filters: { networkPriceDefinition, networkGroupName, networkName, networkCode, inUse },
  } = usePageFilters<NetworksFilters>();

  return (
    <FilterArea customWrapperStyles="flex flex-1 gap-4 self-center [&>*]:flex-1">
      <Input
        dataTestId="inventory-network-name"
        name="networkName"
        value={networkName}
        placeholder="Network name"
        onChange={(userInput: string) => changeFilters({ networkName: userInput })}
        debounceTime={300}
      />
      <Input
        dataTestId="inventory-network-code"
        name="networkCode"
        value={networkCode}
        placeholder="Network code"
        onChange={(userInput: string) => changeFilters({ networkCode: userInput })}
        debounceTime={300}
      />
      <div>
        <FilterNetworkGroup
          placeholder="Network group"
          changeFilters={changeFilters}
          selectedNetworkGroupName={networkGroupName}
        />
      </div>
      <div>
        <FilterNetworkPriceDefinition
          placeholder="Price definition"
          changeFilters={changeFilters}
          selectedNetworkPriceDefinition={networkPriceDefinition}
        />
      </div>
      <div className="flex 2xl:justify-end">
        <FilterAllOrInUseToggle
          changeFilters={changeFilters}
          value={inUse}
          dataTestId="inventory-network-in-use-select"
        />
      </div>
    </FilterArea>
  );
};
export default Filters;
