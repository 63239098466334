import { CodeNameType } from 'components/common/types/AssetList.types';
import AsyncSelect from 'components/patterns/AsyncSelect';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { getChannels } from 'modules/api/inventory';
import { formatFilterOptionsName } from 'utils/formatters';
import { FilterChannelProps } from './FilterChannel.types';

const FilterChannel: React.FC<FilterChannelProps> = ({ changeFilters, channel }) => {
  const cancelFunctions = useCancelRequest();

  const fetchFilterOptions = async (query: string): Promise<CodeNameType[]> => {
    if (query.length >= 3) {
      try {
        return await getChannels(cancelFunctions, query);
      } catch (error) {
        return [];
      }
    }
    return [];
  };

  return (
    <AsyncSelect
      dataTestId="channel-input"
      name="channel"
      loadData={async (_name: string, query: string) => formatFilterOptionsName(await fetchFilterOptions(query))}
      onSelect={(_name: string, value: CodeNameType) => changeFilters({ channel: value })}
      placeholder="Select channel"
      selectedValue={channel}
    />
  );
};

export default FilterChannel;
