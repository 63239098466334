import Chips from 'components/patterns/Chips';
import MultiSelect from 'components/patterns/MultiSelect';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeDealCurrentLineData } from 'store/dealManagement/reducer';
import usePrevious from 'customHooks/usePrevious';
import { notifyError } from 'store/notification/reducer';
import { NOTIFICATION_TIMEOUT } from 'consts/notifications';
import { Store } from 'components/common/types/Store.types';
import { MediaOwnerOption } from './MediaOwner.types';

export const MediaOwner: FunctionComponent<{ label?: string }> = ({ label }) => {
  const dispatch = useDispatch();
  const { availableMediaOwners } = useSelector((state: Store) => state.reseller);
  const { dsp } = useSelector((state: Store) => state.dealManagement.programmatic);
  const { dsps } = useSelector((state: Store) => state.publisher);
  const { mediaOwners } = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine);
  const [transformedAvailableMediaOwners, setTransformedAvailableMediaOwners] = useState<MediaOwnerOption[]>([]);
  const [mediaOwnersSelected, setMediaOwnersSelected] = useState<MediaOwnerOption[]>([]);
  const previousDsp = usePrevious(dsp);

  const onAddMediaOwner = (selection: MediaOwnerOption[]): void => {
    dispatch(changeDealCurrentLineData({ mediaOwners: [...selection.map(({ name }) => name)] }));
  };

  const onRemoveAllMediaOwners = (): void => {
    dispatch(changeDealCurrentLineData({ mediaOwners: [] }));
  };

  const onRemoveMediaOwner = (selection: MediaOwnerOption): void => {
    const newMediaOwners = mediaOwnersSelected.filter(({ code }) => code !== selection.code).map(({ name }) => name);
    dispatch(changeDealCurrentLineData({ mediaOwners: newMediaOwners }));
  };

  const handleDspChange = (): void => {
    if (Object.keys(dsps)?.length && (!mediaOwners?.length || (previousDsp && dsp?.code !== previousDsp.code))) {
      const unavailableMediaOwners = mediaOwners?.filter((prevMo: string) => {
        return !dsps[dsp?.code as keyof typeof dsps]?.mediaOwners.includes(prevMo);
      });

      if (dsp && unavailableMediaOwners?.length) {
        dispatch(
          notifyError({
            message: `${unavailableMediaOwners.join(' and ')} are not available for the current DSP selection.`,
            timeout: NOTIFICATION_TIMEOUT.LONG,
          }),
        );
      }

      dispatch(changeDealCurrentLineData({ mediaOwners: dsps[dsp?.code as keyof typeof dsps]?.mediaOwners }));
    }
  };

  useEffect(() => {
    setMediaOwnersSelected(mediaOwners?.map((mo: string) => ({ code: mo, name: mo })) || []);
  }, [mediaOwners]);

  useEffect(() => {
    setTransformedAvailableMediaOwners(
      availableMediaOwners.map((mo: string) => {
        const disabled = !dsp ? false : !dsps[dsp.code].mediaOwners.includes(mo);
        return { code: mo, name: mo, disabled };
      }),
    );
  }, [availableMediaOwners, dsp]);

  useEffect(() => handleDspChange(), [dsp]);

  return (
    <div data-test-id="media-owner">
      {label && <div className="sub-header-base text-neutral-950-opacity-60 mb-2.5">{label}</div>}
      <div className="flex flex-row">
        <div className="mr-3">
          <MultiSelect
            dataTestId="media-owner-input"
            items={transformedAvailableMediaOwners}
            selectedItems={mediaOwnersSelected}
            onSelect={onAddMediaOwner}
            onClearAll={onRemoveAllMediaOwners}
            isDisabled={false}
          />
        </div>
        {mediaOwnersSelected?.length > 0 && (
          <Chips
            dataList={mediaOwnersSelected}
            dataTestId="selected-media-owner"
            idKey="code"
            labelKey="name"
            onRemove={onRemoveMediaOwner}
            isClearAllVisible={false}
          />
        )}
      </div>
    </div>
  );
};
