import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDealFormReadOnly } from 'customHooks/useDealFormReadOnly';
import { changeDealCurrentLineData, changeDealData, changeIsCpmCampaignLevel } from 'store/dealManagement/reducer';
import { getIsReseller } from 'store/publisher/selectors';
import { Store } from 'components/common/types/Store.types';

interface UseLineCPMReturn {
  readOnly: boolean;
  onCpmCampaignLevelChange: () => void;
  onCpmChange: (newCpm: number) => void;
}

export const useLineCPM = (): UseLineCPMReturn => {
  const dispatch = useDispatch();

  const isCpmCampaignLevel = useSelector((state: Store) => state.dealManagement.isCpmCampaignLevel);
  const isReseller = useSelector(getIsReseller);
  const readOnly = useDealFormReadOnly();

  const onCpmCampaignLevelChange = useCallback(() => {
    const isNewCpmCampaignLevelEnabled = !isCpmCampaignLevel;

    if (!isNewCpmCampaignLevelEnabled) {
      dispatch(changeDealData({ cpm: 0 }));

      const line = document.getElementById('line-level');
      if (line) line.scrollIntoView({ behavior: 'smooth' });
    }

    dispatch(changeIsCpmCampaignLevel(isNewCpmCampaignLevelEnabled));
  }, [isCpmCampaignLevel]);

  const onCpmChange = useCallback(
    (newCpm: number) => {
      dispatch(changeDealData({ cpm: newCpm }));

      if (isCpmCampaignLevel && !isReseller) {
        dispatch(changeDealCurrentLineData({ cpm: newCpm }));
      }
    },
    [isCpmCampaignLevel],
  );

  return {
    readOnly,
    onCpmCampaignLevelChange,
    onCpmChange,
  };
};
