import { DealType } from 'components/common/types/Deal.types';

export const getSweepVisibility = (dealTypeCode, hasNonGuaranteedExtendedTargetEnabled) =>
  dealTypeCode === DealType.GUARANTEED || hasNonGuaranteedExtendedTargetEnabled;

const getProximityPostCode = ({ postcode }) => {
  const { distance, fileName, fileId, postcode: postCodes, unit, include } = postcode;

  if ((distance || distance === 0) && (fileName || postCodes?.length)) {
    return {
      tags: postCodes?.length ? postCodes : [],
      files: fileName ? [{ name: fileName, fileId }] : [],
      distance: String(distance),
      unit,
      include,
      isFileUploadActive: fileName?.length > 0,
    };
  }

  return {};
};

const getProximityPoints = ({ points }) => {
  const { selected, fileName, fileId, distance, unit, include } = points;

  if ((distance || distance === 0) && (fileName || selected?.length)) {
    return {
      latitude: selected?.length ? String(selected[0].latitude) : '',
      longitude: selected?.length ? String(selected[0].longitude) : '',
      files: fileName ? [{ name: fileName, fileId }] : [],
      distance: String(distance),
      unit,
      include,
      isFileUploadActive: fileName?.length > 0,
    };
  }

  return {};
};

const getProximityPOI = ({ poi }) => {
  const { dataSourceCode, attributeCode, selectedCodes, distance, unit, include } = poi;
  if (
    dataSourceCode &&
    dataSourceCode !== 0 &&
    attributeCode &&
    attributeCode !== 0 &&
    selectedCodes?.length &&
    (distance || distance === 0)
  ) {
    return {
      tags: selectedCodes,
      distance: String(distance),
      unit,
      include,
      dataSourceCode,
      attributeCode,
    };
  }

  return {};
};

export const getPoiPoints = (poiPoints) => {
  return poiPoints
    .filter(({ properties }) => !!properties)
    .map(({ geometry, id, include, name, properties, radius }) => ({
      geometry,
      id,
      include,
      name,
      properties: properties.map(
        ({ address, categoryName, categoryType, dataProvider, latitude, longtitude, mediaOwner, postalCode }) => ({
          address: address || '',
          categoryName,
          categoryType,
          dataProvider,
          latitude,
          longtitude,
          mediaOwner,
          poiId: id,
          poiName: name,
          postalCode,
          visibility: {
            include,
            radius,
          },
        }),
      )[0],
      radius,
    }));
};

export const getPlannerPoi = (plannerPoi) => {
  return plannerPoi.map(({ categoryName, categoryType, include, poiPoints, radius }) => ({
    categoryName,
    categoryType,
    code: categoryType,
    name: categoryName,
    poiPoints: getPoiPoints(poiPoints),
    visibility: {
      include,
      radius,
    },
  }));
};

export const getOpenStreetMapPoi = (openStreetMapPoi) => {
  return openStreetMapPoi.map(({ name, include, poiPoints, radius }) => ({
    name,
    poiPoints: getPoiPoints(poiPoints),
    visibility: {
      include,
      radius,
    },
  }));
};

export const getProximity = (proximity) => ({
  proximity: {
    postCode: proximity?.postcode ? getProximityPostCode(proximity) : {},
    points: proximity?.points ? getProximityPoints(proximity) : {},
    poi: proximity?.poi ? getProximityPOI(proximity) : {},
    plannerPoi: proximity?.plannerPoi ? getPlannerPoi(proximity.plannerPoi) : [],
    openStreetMapPoi: proximity?.openStreetMapPoi ? getOpenStreetMapPoi(proximity.openStreetMapPoi) : [],
  },
});

export const getTags = (tags) => {
  if (!tags) return {};
  return { tags: tags.map(({ tagCode, tagName, include }) => ({ code: tagCode, name: tagName, include })) };
};

export const getLocationData = (countries, cities, streets, counties, postCodes) => ({
  ...(countries ? { countries } : {}),
  ...(cities ? { cities } : {}),
  ...(streets ? { streets } : {}),
  ...(counties ? { counties } : {}),
  ...(postCodes ? { postCodes } : {}),
});

export const getListFrames = (includeFrames = [], excludeFrames = []) => {
  const routeFrameCodes = [];
  const listFiles = [];

  const addRouteFrameCodes = (frames, include) => {
    frames?.forEach((code) => {
      routeFrameCodes.push({
        code: code.assetId || code,
        ...(code.mediaOwner ? { mediaOwner: code.mediaOwner } : {}),
        include,
      });
    });
  };

  const addListFiles = (fileId, name, include, dynamoDbFileId) => {
    if (!name) return;
    listFiles.push({
      fileId,
      name,
      include,
      dynamoDbFileId,
    });
  };

  includeFrames?.forEach(({ frames = [], fileId, fileName, dynamoDbFileId }) => {
    addRouteFrameCodes(frames, true);
    addListFiles(fileId, fileName, true, dynamoDbFileId);
  });

  excludeFrames?.forEach(({ frames = [], fileId, fileName, dynamoDbFileId }) => {
    addRouteFrameCodes(frames, false);
    addListFiles(fileId, fileName, false, dynamoDbFileId);
  });

  return { routeFrameCodes, listFiles };
};

export const getVisualUnitFrames = (includeVisualUnits = [], excludeVisualUnits = []) => {
  const visualUnitCodes = [];
  const visualUnitFiles = [];

  const addRouteFrameCodes = (frames, include) => {
    frames?.forEach((code) => {
      visualUnitCodes.push({
        code: code.assetId || code,
        ...(code.mediaOwner ? { mediaOwner: code.mediaOwner } : {}),
        include,
      });
    });
  };

  const addListFiles = (fileId, name, include, dynamoDbFileId) => {
    if (!name) return;
    visualUnitFiles.push({
      fileId,
      name,
      include,
      dynamoDbFileId,
    });
  };

  includeVisualUnits?.forEach(({ frames = [], fileId, fileName, dynamoDbFileId }) => {
    addRouteFrameCodes(frames, true);
    addListFiles(fileId, fileName, true, dynamoDbFileId);
  });

  excludeVisualUnits?.forEach(({ frames = [], fileId, fileName, dynamoDbFileId }) => {
    addRouteFrameCodes(frames, false);
    addListFiles(fileId, fileName, false, dynamoDbFileId);
  });

  return { visualUnitCodes, visualUnitFiles };
};

export const getVenueTaxonomies = (venueTaxonomies) => (venueTaxonomies ? { venueTaxonomies } : {});

export const sortLines = (lines) =>
  lines
    .sort((a, b) => a.createdDate.getTime() - b.createdDate.getTime())
    .map((line, index) => ({
      ...line,
      sortOrderNumber: (index + 1).toString().padStart(2, 0),
    }));
