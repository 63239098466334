import { useSelector } from 'react-redux';
import { Store } from 'components/common/types/Store.types';
import { getDealAllocationData } from 'store/dealManagement/selectors';
import { LevelType } from 'consts/thoughtspot';
import { DeliveryCard } from '../Cards/DeliveryCard/DeliveryCard';
import { ProposalCard } from '../Cards/ProposalCard/ProposalCard';
import AllocationCard from '../Cards/AllocationCard';
import { getDealSummaryCardVisibility } from '../Cards/selectors';

const DealLevelCards: React.FC = () => {
  const dealAllocation = useSelector(getDealAllocationData);
  const { isProposalCardVisible, isDeliveryCardVisible } = useSelector(getDealSummaryCardVisibility);

  const allocatedFrames = useSelector(
    (state: Store) => state.dealManagement.commonDeal.summary.availability.allocatedFrames,
  );
  const allocatedImpressions = useSelector(
    (state: Store) => state.dealManagement.commonDeal.summary.availability.allocatedImpressions,
  );
  const availableFrames = useSelector(
    (state: Store) => state.dealManagement.commonDeal.summary.availability.availableFrames,
  );
  const availableImpressions = useSelector(
    (state: Store) => state.dealManagement.commonDeal.summary.availability.availableImpressions,
  );
  const totalCost = useSelector((state: Store) => state.dealManagement.commonDeal.summary.availability.totalCost);
  const dealSummaryAssets = useSelector(
    (state: Store) => state.dealManagement.commonDeal.summary?.availability?.assets,
  );
  const deliveredImpressions = useSelector(
    (state: Store) => state.dealManagement.commonDeal.summary.deliveredImpressions,
  );
  const campaignImpressionMetrics = useSelector((state: Store) => state.dealManagement.commonDeal.impressionMetrics);

  return (
    <div className="flex flex-col gap-3 mb-[12px]">
      <AllocationCard
        allocatedFrames={dealAllocation.allocatedFrames}
        allocatedImpressions={dealAllocation.allocatedImpressions}
        defaultAllocationReportLevelType={LevelType.DEAL}
        totalCost={dealAllocation.totalCost}
      />
      {isDeliveryCardVisible && (
        <DeliveryCard
          expectedImpressionsElapsed={campaignImpressionMetrics?.expectedImpressionsElapsed}
          totalAllocatedImpressions={campaignImpressionMetrics?.totalAllocatedImpressions}
          soldImpressions={deliveredImpressions}
          osi={campaignImpressionMetrics?.osi}
        />
      )}
      <ProposalCard
        allocatedFrames={allocatedFrames}
        allocatedImpressions={allocatedImpressions}
        availableFrames={availableFrames}
        availableImpressions={availableImpressions}
        assets={dealSummaryAssets}
        defaultAllocationReportLevelType={LevelType.DEAL}
        totalCost={totalCost}
        isAvailabilityHidden
        isVisible={isProposalCardVisible}
      />
    </div>
  );
};

export default DealLevelCards;
