import MultipleSwitchBox from 'components/patterns/MultipleSwitchBox';
import { DealAction } from 'components/common/types/Deal.types';
import { DealActionFilters, FilterDealActionProps } from './FilterDealAction.types';

const actionNeededOptions = [
  { label: 'All', value: DealAction.NONE },
  { label: 'Action needed', value: DealAction.ACTIVE },
];

const FilterDealAction = <T extends DealActionFilters>({
  changeFilters,
  selectedDealAction,
}: FilterDealActionProps<T>): React.ReactElement => {
  return (
    <div className="max-w-fit whitespace-nowrap">
      <MultipleSwitchBox
        source={actionNeededOptions}
        textKey="label"
        valueKey="value"
        onChange={(value: DealAction) => changeFilters({ dismountedSearch: value } as Partial<T>)}
        selectedValue={selectedDealAction}
      />
    </div>
  );
};

export default FilterDealAction;
