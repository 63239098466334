import { CancelFunctions } from 'components/common/types';
import { HEADERS_CONTENT_TYPE, HTTP_METHODS, URLS } from './constants';
import createRequest from './request';
import Session from '../Session';

type MarketIdParams = { marketId: string };
type UpdateCreativeMovementParams = { creativeId: string; movement: string };
type RejectCreativeParams = { marketId: string; rejectReason: string };

export const updateCreativeMovement = async (
  cancelFunctions: CancelFunctions,
  { creativeId, movement }: UpdateCreativeMovementParams,
): Promise<unknown> => {
  const { send, cancel } = createRequest({
    url: URLS().UPDATE_CREATIVE_MOVEMENT(creativeId),
    method: HTTP_METHODS.PATCH,
    headers: {
      'Content-Type': HEADERS_CONTENT_TYPE.APPLICATION_JSON,
    },
    data: JSON.stringify({
      movement,
    }),
  });

  if (cancelFunctions.UPDATE_CREATIVE_MOVEMENT) cancelFunctions.UPDATE_CREATIVE_MOVEMENT();
  cancelFunctions.UPDATE_CREATIVE_MOVEMENT = cancel;

  return send();
};

export const pendingApproveCreative = async (
  cancelFunctions: CancelFunctions,
  { marketId }: MarketIdParams,
): Promise<unknown> => {
  const { send, cancel } = createRequest({
    url: URLS().PENDING_APPROVE_CREATIVE(marketId),
    method: HTTP_METHODS.POST,
    headers: {
      'Content-Type': HEADERS_CONTENT_TYPE.APPLICATION_JSON,
    },
    data: JSON.stringify({
      auth0Identifier: Session.getEnvironmentId(),
    }),
  });

  if (cancelFunctions.PENDING_APPROVE_CREATIVE) cancelFunctions.PENDING_APPROVE_CREATIVE();
  cancelFunctions.PENDING_APPROVE_CREATIVE = cancel;

  return send();
};

export const rejectCreative = async (
  cancelFunctions: CancelFunctions,
  { marketId, rejectReason }: RejectCreativeParams,
): Promise<unknown> => {
  const { send, cancel } = createRequest({
    url: URLS().REJECT_CREATIVE(marketId),
    method: HTTP_METHODS.POST,
    headers: {
      'Content-Type': HEADERS_CONTENT_TYPE.APPLICATION_JSON,
    },
    data: JSON.stringify({
      auth0Identifier: Session.getEnvironmentId(),
      reason: rejectReason,
    }),
  });

  if (cancelFunctions.REJECT_CREATIVE) cancelFunctions.REJECT_CREATIVE();
  cancelFunctions.REJECT_CREATIVE = cancel;

  return send();
};

export const approveCreative = async (
  cancelFunctions: CancelFunctions,
  { marketId }: MarketIdParams,
): Promise<unknown> => {
  const { send, cancel } = createRequest({
    url: URLS().APPROVE_CREATIVE(marketId),
    method: HTTP_METHODS.POST,
    headers: {
      'Content-Type': HEADERS_CONTENT_TYPE.APPLICATION_JSON,
    },
    data: JSON.stringify({
      auth0Identifier: Session.getEnvironmentId(),
    }),
  });

  if (cancelFunctions.APPROVE_CREATIVE) cancelFunctions.APPROVE_CREATIVE();
  cancelFunctions.APPROVE_CREATIVE = cancel;

  return send();
};

export const flagCreative = async (
  cancelFunctions: CancelFunctions,
  { marketId }: MarketIdParams,
): Promise<unknown> => {
  const { send, cancel } = createRequest({
    url: URLS().FLAG_CREATIVE(marketId),
    method: HTTP_METHODS.POST,
  });

  if (cancelFunctions.FLAG_CREATIVE) cancelFunctions.FLAG_CREATIVE();
  cancelFunctions.FLAG_CREATIVE = cancel;

  return send();
};

export const sendCreativeForApproval = async (
  cancelFunctions: CancelFunctions,
  { marketId }: MarketIdParams,
): Promise<unknown> => {
  const { send, cancel } = createRequest({
    url: URLS().SEND_CREATIVE_FOR_APPROVAL(marketId),
    method: HTTP_METHODS.POST,
  });

  if (cancelFunctions.SEND_CREATIVE_FOR_APPROVAL) cancelFunctions.SEND_CREATIVE_FOR_APPROVAL();
  cancelFunctions.SEND_CREATIVE_FOR_APPROVAL = cancel;

  return send();
};
