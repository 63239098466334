import { useCallback, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import { InputSize, InputTheme, InputType } from 'components/common/types/Input.types';
import { BaseInput } from 'lib/BaseInput';
import { InputProps } from './Input.types';

const Input = ({
  id,
  dataTestId,
  label,
  name,
  type,
  value,
  placeholder,
  theme,
  isDisabled,
  isRequired,
  size,
  infoMessage,
  errorMessage,
  startIconSvg,
  endIconSvg,
  endComponent,
  renderEndComponent,
  debounceTime,
  maxLength,
  onChange,
  onBlur,
  textSymbol,
  shape,
  iconSize,
  className,
  dataPendoId,
}: InputProps): React.ReactElement => {
  const [inputValue, setInputValue] = useState(value);

  const onChangeDebounced = useMemo(() => (onChange ? debounce(onChange, debounceTime) : undefined), [onChange]);

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const userInput = event.target.value;
      const inputName = event.target.name;

      setInputValue(userInput);
      onChangeDebounced?.(userInput, inputName);
    },
    [onChangeDebounced],
  );

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    return () => {
      onChangeDebounced?.cancel?.();
    };
  }, []);

  const handleOnBlur = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const userInput = event.target.value;
    const inputName = event.target.name;

    onBlur?.(userInput, inputName);
  };

  return (
    <BaseInput
      dataTestId={dataTestId}
      id={id}
      label={label}
      name={name}
      placeholder={placeholder}
      size={size}
      type={type}
      value={inputValue}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      theme={theme}
      startIconSvg={startIconSvg}
      endIconSvg={endIconSvg}
      isDisabled={isDisabled}
      isRequired={isRequired}
      errorMessage={errorMessage}
      infoMessage={infoMessage}
      endComponent={endComponent}
      maxLength={maxLength}
      renderEndComponent={renderEndComponent}
      textSymbol={textSymbol}
      shape={shape}
      iconSize={iconSize}
      className={className}
      dataPendoId={dataPendoId}
    />
  );
};

Input.defaultProps = {
  type: InputType.TEXT,
  size: InputSize.MEDIUM,
  theme: InputTheme.LIGHT,
  isRequired: false,
  isDisabled: false,
  label: '',
  value: '',
  max: 0,
  maxLength: undefined,
  placeholder: '',
  onBlur: () => null,
  onChange: undefined,
};

export default Input;
