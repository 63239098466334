import Input from 'lib/Input';
import { FilterRouteFrameCodeProps } from './FilterRouteFrameCode.types';

const FilterRouteFrameCode: React.FC<FilterRouteFrameCodeProps> = ({ changeFilters, routeFrameCode }) => {
  return (
    <Input
      data-test-id="route-frame"
      name="routeFrameCode"
      value={routeFrameCode}
      placeholder="Route frame code"
      onChange={(value: string) => {
        changeFilters({ routeFrameCode: value });
      }}
    />
  );
};

export default FilterRouteFrameCode;
