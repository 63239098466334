import classNames from 'classnames';
import SVG from 'react-inlinesvg';
import { ButtonGroupProps, Height, Padding, Theme, Width } from './ButtonGroup.types';

const cx = classNames;

const ButtonGroup = ({
  onChange,
  options,
  selectedValue,
  dataTestId,
  icon,
  padding = Padding.LOOSE,
  theme = Theme.DARK,
  isDisabled = false,
  width = Width.MIN,
  height = Height.LARGE,
}: ButtonGroupProps): React.ReactElement => {
  return (
    <div
      className={cx('flex-1 inline-flex items-center p-0.5 gap-0.5 rounded-lg', {
        'bg-primary': theme === Theme.DARK,
        'bg-neutral-100': theme === Theme.LIGHT,
        'w-full': width === Width.MAX,
        'w-auto': width === Width.MIN,
      })}
      data-test-id={dataTestId}
    >
      {icon && (
        <SVG
          src={icon}
          className={cx('w-4 h-4 ml-2.5 mr-2', {
            'text-neutral-100-opacity-40': theme === Theme.DARK && isDisabled,
            'text-surfaceLight-disabled': theme === Theme.LIGHT && isDisabled,
            'text-neutral-100': theme === Theme.DARK,
            'text-neutral-900': theme === Theme.LIGHT,
          })}
        />
      )}
      {options.map((option) => {
        const isSelected = option.value === selectedValue;

        return (
          <button
            type="button"
            key={option.value}
            onClick={() => onChange(option.value)}
            className={cx(
              'flex-1 rounded-md body-base disabled:cursor-not-allowed',
              {
                'px-4': padding === Padding.LOOSE,
              },
              {
                'px-1': padding === Padding.TIGHT,
              },
              {
                'py-2.5': height === Height.LARGE,
              },
              {
                'py-1': height === Height.SMALL,
              },
              {
                'body-base': !isSelected,
              },
              {
                'sub-header-base': isSelected,
              },
              {
                'disabled:text-neutral-100-opacity-40': isDisabled && theme === Theme.DARK,
              },
              {
                'hover:opacity-70 text-neutral-100': !isDisabled && !isSelected && theme === Theme.DARK,
              },
              {
                'bg-neutral-100-opacity-50 text-neutral-100 disabled:text-neutral-100-opacity-40':
                  isSelected && theme === Theme.DARK,
              },
              {
                'disabled:text-surfaceLight-disabled': isDisabled && theme === Theme.LIGHT,
              },
              {
                'text-neutral-900 border border-transparent hover:opacity-70':
                  !isDisabled && !isSelected && theme === Theme.LIGHT,
              },
              {
                'bg-neutral-50 text-neutral-950 border border-neutral-300': isSelected && theme === Theme.LIGHT,
              },
            )}
            disabled={isDisabled || option.isDisabled}
          >
            {option.text}
          </button>
        );
      })}
    </div>
  );
};

export default ButtonGroup;
