import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import Auth from 'modules/Auth';
import Session from 'modules/Session';
import cx from 'classnames';
import { URLS } from 'modules/api/constants';
import Tooltip, { TooltipDirection } from 'components/patterns/Tooltip';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';
import exitSvg from 'assets/icons/exit.svg';
import { FeatureFlags } from 'components/common/types/Features.types';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import Logo from './Logo/Logo';
import MenuItem from './MenuItem/MenuItem';
import { MENU_ITEMS_CONFIG, PLANNER_MENU_ITEM } from './constants';
import { getActiveMenuId } from './utils';
import { ItemType } from './MenuItem/MenuItem.types';
import { SidebarMenuProps } from './SidebarMenu.types';

const SidebarMenu: React.FC<SidebarMenuProps> = ({ isRequiredMarketData = false }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeMenuId, setActiveMenuId] = useState<number>(1);

  useEffect(() => {
    const activeId = getActiveMenuId(MENU_ITEMS_CONFIG, location.pathname);

    if (activeId) {
      setActiveMenuId(activeId);
    }
  }, [location]);

  const onLogoutClick = (): void => {
    Session.clear();
    Auth.logout();
  };

  const openPlanner = (): void | Window | null => window.open(URLS().PLANNER(), '_blank');

  const navigateHandler = (item: ItemType): void => {
    navigate(item.navigationPath);
  };

  return (
    <div
      className={cx('h-full fixed left-0 top-0 z-10 transition-all ease-in-out duration-300 bg-primary-950', {
        width: 'var(--sidebar-width)',
      })}
    >
      <Logo />
      <nav className="mt-10">
        <ul data-test-id="menu-list" className="list-none p-0 m-0">
          {MENU_ITEMS_CONFIG.map((item) => {
            return item.isVisible() && isRequiredMarketData ? (
              <MenuItem
                key={item.id}
                item={item}
                onNavigate={() => navigateHandler(item)}
                isActive={item.id === activeMenuId}
              />
            ) : null;
          })}
        </ul>
      </nav>

      <div className="absolute w-full bottom-4 space-y-4 flex flex-col justify-center items-center">
        {useHasFeatureAccess(FeatureFlags.PLANNER) ? (
          <nav>
            <ul data-test-id="planner-item">
              <MenuItem
                item={PLANNER_MENU_ITEM}
                onNavigate={openPlanner}
                itemStyle="bg-pinkRed hover:bg-pinkRed-400"
                isActive={false}
              />
            </ul>
          </nav>
        ) : null}
        <Tooltip tooltip="Logout" direction={TooltipDirection.RIGHT}>
          <Button
            dataTestId="logout"
            onClick={onLogoutClick}
            btnShape={ButtonShape.CIRCLE}
            btnType={ButtonType.TRANSPARENT}
          >
            <span className="transform rotate-180">
              <SVG src={exitSvg} className="fill-current text-neutral-50" />
            </span>
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};

export default SidebarMenu;
