import Input from 'lib/Input';
import FilterAllOrInUseToggle from 'components/common/FilterArea/Filters/FilterAllOrInUseToggle';
import FilterNetworkGroup from 'components/common/FilterArea/Filters/FilterNetworkGroup/FilterNetworkGroup';
import FilterArea from 'components/common/FilterArea';
import { CodeNameModel } from 'components/common/types';
import { usePageFilters } from 'customHooks/usePageFilters';

export type NetworkGroupFilters = {
  networkGroupCode: string;
  networkGroupName: CodeNameModel;
  inUse: boolean | '';
};

const Filters: React.FC = () => {
  const {
    changeFilters,
    filters: { networkGroupName, networkGroupCode, inUse },
  } = usePageFilters<NetworkGroupFilters>();

  return (
    <FilterArea customWrapperStyles="flex flex-1 gap-4 self-center [&>*]:flex-1">
      <FilterNetworkGroup changeFilters={changeFilters} selectedNetworkGroupName={networkGroupName} />
      <Input
        name="networkGroupCode"
        value={networkGroupCode}
        placeholder="Type network group code"
        onChange={(userInput: string) => changeFilters({ networkGroupCode: userInput })}
        debounceTime={300}
      />
      <div className="flex justify-end w-min whitespace-nowrap">
        <FilterAllOrInUseToggle changeFilters={changeFilters} value={inUse} dataTestId="in-use-select" />
      </div>
    </FilterArea>
  );
};
export default Filters;
