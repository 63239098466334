import { Action, RuntimeFilterOp } from '@thoughtspot/visual-embed-sdk';

export const filterByMarket = (
  marketId: string,
): {
  columnName: string;
  operator: RuntimeFilterOp;
  values: string[];
} => {
  return {
    columnName: 'Market',
    operator: RuntimeFilterOp.EQ,
    values: [marketId],
  };
};

export const labsReportVisibleActions = [
  Action.CreateMonitor,
  Action.PersonalisedViewsDropdown,
  Action.ManageMonitor,
  Action.EnableContextualChangeAnalysis,
];

export const hideMarketFilterStyle = {
  style: {
    customCSS: {
      rules_UNSTABLE: {
        "[data-tooltip-content*='Column name: Market']": {
          display: 'none',
        },
      },
    },
  },
};
