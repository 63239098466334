import { useSelector } from 'react-redux';
import { Store } from 'components/common/types/Store.types';
import CampaignId from './CampaignId';
import CampaignName from './CampaignName';
import CampaignBookingStatus from './CampaignBookingStatus';

const Overview: React.FC = () => {
  const dealName = useSelector((state: Store) => state.dealManagement.commonDeal.dealName);
  const dealId = useSelector((state: Store) => state.dealManagement.commonDeal.dealId);

  return (
    <div className="grid grid-cols-1 pt-[3px] pr-[16px] pb-[8px] pl-[14px]" data-test-id="drawer-overview">
      <div className="pl-[2px]">
        <CampaignName campaignName={dealName} />
      </div>
      <div className="pl-[8px] pt-[2px]">
        <CampaignId campaignId={dealId} />
      </div>
      <div className="pl-[8px] pt-[6px]">
        <CampaignBookingStatus />
      </div>
    </div>
  );
};

export default Overview;
