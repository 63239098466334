import Filters from 'components/common/FilterArea';
import FilterContentDealId from 'components/common/FilterArea/Filters/FilterContentDealId';
import FilterVisualUnitCode from 'components/common/FilterArea/Filters/FilterVisualUnitCode';
import { FeatureFlags } from 'components/common/types/Features.types';
import { PermissionsEnum } from 'consts/permissions';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import { CardTheme } from 'lib/Card/Card.types';
import Auth from 'modules/Auth';
import FilterFrameCode from 'components/common/FilterArea/Filters/FilterFrameCode';
import { useDispatch, useSelector } from 'react-redux';
import { onFilterChange } from 'store/pages/contentManagement/creativeSetDetails/reducer';
import { RootState } from 'store';
import FilterEnvironment from './Filters/FilterEnvironment';
import FilterFrameOrdering from './Filters/FilterFrameOrdering';
import FilterLocation from './Filters/FilterLocation';
import FilterWithLabel from './FilterWithLabel';

const FilterArea: React.FC = () => {
  const dispatch = useDispatch();
  const frameCode = useSelector(
    (state: RootState) => state.pages.contentManagement.creativeSetDetails.filters.frameCode,
  );

  if (!Auth.hasPermission(PermissionsEnum.CONTENT_SEARCH)) return null;

  return (
    <Filters cardTheme={CardTheme.DEFAULT_NOT_ROUNDED} hideIcon customWrapperStyles="flex-1" dataTestId="filter-area">
      <div className="grid grid-cols-6 gap-4 flex-1">
        <FilterWithLabel label="Deal ID">
          <FilterContentDealId placeholder="Search" />
        </FilterWithLabel>
        <FilterWithLabel label="Environment">
          <FilterEnvironment placeholder="Search" />
        </FilterWithLabel>
        <FilterWithLabel label="Visual unit code">
          <FilterVisualUnitCode placeholder="Search" />
        </FilterWithLabel>
        <FilterWithLabel label="Frame code">
          <FilterFrameCode
            changeFilters={(value) => dispatch(onFilterChange(value))}
            frameCode={frameCode}
            placeholder="Search"
          />
        </FilterWithLabel>
        <FilterWithLabel label="Location">
          <FilterLocation placeholder="Search" />
        </FilterWithLabel>
        {useHasFeatureAccess(FeatureFlags.VISUAL_UNITS_ORDERING) ? (
          <FilterWithLabel label="Ordering">
            <FilterFrameOrdering />
          </FilterWithLabel>
        ) : null}
      </div>
    </Filters>
  );
};

export default FilterArea;
