import { PropsWithChildren } from 'react';

type Props = {
  minWidth?: string;
};

export const PageWrapper: React.FC<PropsWithChildren<Props>> = ({ children, minWidth }) => {
  return <div className={`min-w-${minWidth} h-full overflow-auto`}>{children}</div>;
};

PageWrapper.defaultProps = {
  minWidth: '300',
};
