import { useCancelRequest } from 'customHooks/useCancelRequest';
import { CreativeStatus } from 'components/common/types/Creative.types';
import { PermissionsEnum } from 'consts/permissions';
import Auth from 'modules/Auth';
import { differenceWith, isEqual } from 'lodash';
import { addCreativeCategory, removeCreativeCategory } from 'modules/api/content';
import { setAdditionalProductCategories } from 'store/creative/reducer';
import { notifyError } from 'store/notification/reducer';
import Chips from 'components/patterns/Chips';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'components/common/types/Store.types';
import BaseSelect from 'lib/BaseSelect';
import {
  AdditionalProductCategoriesProps,
  RemoveProductCategoryHandler,
  SelectProductCategoriesHandler,
} from './AdditionalProductCategories.types';

const AdditionalProductCategories: React.FC<AdditionalProductCategoriesProps> = ({
  creativeStatus,
  isDisabled = false,
  marketId,
  onTaggingRulesChange,
}) => {
  const dispatch = useDispatch();
  const additionalProductCategories = useSelector((store: Store) => store.creative.additionalProductCategories);
  const additionalProductCategoriesOptions = useSelector(
    (store: Store) => store.creative.additionalProductCategoriesOptions,
  );

  const cancelFunctions = useCancelRequest();
  const hasEditPermission = Auth.hasPermission(PermissionsEnum.CONTENT_CREATIVE_CATEGORIES_EDIT);

  const isProductCategoryDisabled =
    creativeStatus === CreativeStatus.REJECTED ||
    creativeStatus === CreativeStatus.APPROVED ||
    !hasEditPermission ||
    additionalProductCategoriesOptions.length === 0 ||
    isDisabled;

  const handleSelectProductCategories: SelectProductCategoriesHandler = async (_dropdownName, values) => {
    const categoryIdToRemove = differenceWith(additionalProductCategories, values, isEqual)[0]?.id;
    const categoryIdToAdd = differenceWith(values, additionalProductCategories, isEqual)[0]?.id;

    try {
      if (categoryIdToRemove) {
        await removeCreativeCategory(cancelFunctions, marketId, categoryIdToRemove);
      } else if (categoryIdToAdd) {
        await addCreativeCategory(cancelFunctions, marketId, categoryIdToAdd);
      }

      onTaggingRulesChange();
      dispatch(setAdditionalProductCategories(values));
    } catch (error) {
      dispatch(notifyError({ message: error.message }));
    }
  };

  const handleRemoveProductCategory: RemoveProductCategoryHandler = (selectedProductCategory) => {
    const updatedSelectedCategories = additionalProductCategories.filter(
      (category) => selectedProductCategory.id !== category.id,
    );

    dispatch(setAdditionalProductCategories(updatedSelectedCategories));
    handleSelectProductCategories('', updatedSelectedCategories);
  };

  return (
    <div className="row">
      <div className="col-12 col-md-4" data-test-id="additional-product-categories">
        <BaseSelect
          dataTestId="additional-product-categories-input"
          name="categories"
          placeholder="Select a category"
          isMultiple
          isDisabled={isProductCategoryDisabled}
          onSelect={handleSelectProductCategories}
          selectedValue={additionalProductCategories}
          options={additionalProductCategoriesOptions}
          closeMenuOnSelect={false}
          isSelectionVisible={false}
          withPortal={false}
          isSearchable
        />
      </div>
      <div className="col-12 col-md-8">
        <Chips
          dataTestId="additional-product-categories-chips"
          dataList={additionalProductCategories}
          idKey="id"
          labelKey="name"
          isClearAllVisible={false}
          onRemove={handleRemoveProductCategory}
          isDisabled={isProductCategoryDisabled}
        />
      </div>
    </div>
  );
};

export default AdditionalProductCategories;
