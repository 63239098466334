import { DealStatus } from 'components/common/types/Deal.types';
import { Store } from 'components/common/types/Store.types';
import { useDispatch, useSelector } from 'react-redux';
import { changeProgrammaticDealInfo } from 'store/dealManagement/actions';

interface UseDspSync {
  dspSeatId: string;
  isWithDspSync: boolean;
  isDisabled: boolean;
  handleSwitchToggle: () => void;
  updateDspSeat: (seatId?: string) => void;
}

export const useDspSync = (): UseDspSync => {
  const dispatch = useDispatch();
  const isNewDeal = useSelector((state: Store) => state.dealManagement.isNewDeal);
  const bookingStatusCode = useSelector((state: Store) => state.dealManagement.commonDeal.bookingStatusCode);
  const { dspSeatId, syncWithDsp: isWithDspSync } = useSelector((state: Store) => state.dealManagement.programmatic);

  const isDisabled = !(isNewDeal || [DealStatus.PENDING_APPROVAL].includes(bookingStatusCode));

  const handleSwitchToggle = (): void => {
    dispatch(changeProgrammaticDealInfo({ syncWithDsp: !isWithDspSync }));
  };
  const updateDspSeat = (seatId?: string): void => {
    dispatch(changeProgrammaticDealInfo({ dspSeatId: seatId }));
  };

  return {
    dspSeatId,
    isWithDspSync,
    isDisabled,
    handleSwitchToggle,
    updateDspSeat,
  };
};
