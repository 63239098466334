import notepadSvg from 'assets/icons/notepad.svg';
import { Color } from 'lib/Button';
import DropdownMenu from 'lib/DropdownMenu';

interface DealFormDropdownProps {
  openPlanner: () => void;
  openDealForm: () => void;
}

const DealFormDropdown: React.FC<DealFormDropdownProps> = ({ openPlanner, openDealForm }) => {
  return (
    <DropdownMenu
      dropdownMenuOptions={[
        {
          id: 'deal-form',
          onClick: openDealForm,
          label: 'Deal form',
          dataPendoId: '/deals | Create deal form',
        },
        {
          id: 'planner-native',
          onClick: openPlanner,
          label: 'Planner',
          dataPendoId: '/deals | Create planner deal',
        },
      ]}
      icon={notepadSvg}
      label="Create deal"
      color={Color.PRIMARY}
    />
  );
};

export default DealFormDropdown;
