import { components, PlaceholderProps } from 'react-select';
import SVG from 'react-inlinesvg';
import treeStructureSvg from 'assets/icons/tree-structure.svg';
import BaseSelect from 'lib/BaseSelect';
import Chip from 'lib/Chip';
import { Color, Size } from 'lib/Chip/Chip.types';
import { rowGroupColumns } from '../consts';
import { UseAllocationReport } from '../useAllocationReport';
import { RowGroupColumn } from '../AllocationReport.types';

type DropdownGroupByProps = Pick<
  ReturnType<UseAllocationReport>,
  'handleToggleRowGroupColumn' | 'selectedRowGroupColumns'
>;

export const DropdownGroupBy: React.FC<DropdownGroupByProps> = ({
  handleToggleRowGroupColumn,
  selectedRowGroupColumns,
}) => {
  const selectedGroups = selectedRowGroupColumns.filter(({ isSelected }) => isSelected);

  return (
    <div className="w-48">
      <BaseSelect
        dataTestId="group-by"
        name="Group by"
        options={rowGroupColumns}
        selectedValue={selectedGroups}
        isMultiple
        onSelect={(_, value: RowGroupColumn[]) => handleToggleRowGroupColumn(value)}
        closeMenuOnSelect={false}
        isSelectionVisible={false}
        customComponents={{
          Placeholder: ({ children, ...props }: PlaceholderProps) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <components.Placeholder {...props}>
              <div className="flex items-center flex-1">
                <SVG src={treeStructureSvg} className="text-neutral-600" />
                <div className="ml-2 mr-auto body-base text-neutral-950">Group by</div>
                {selectedGroups.length > 0 && (
                  <Chip
                    dataTestId="selected-groups-chip"
                    label={`${selectedGroups.length}`}
                    color={Color.PRIMARY}
                    size={Size.SMALL}
                  />
                )}
              </div>
              <div className="h-0 overflow-hidden">{children}</div>
            </components.Placeholder>
          ),
        }}
        withPortal={false}
      />
    </div>
  );
};
