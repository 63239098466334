import classNames from 'classnames/bind';
import { CreativeSetPanel } from 'components/common/types/Creative.types';

type PanelsRendererProps = {
  panels: CreativeSetPanel[];
};

const cx = classNames.bind(null);

const PanelsRenderer: React.FC<PanelsRendererProps> = ({ panels }) => {
  return (
    <div className="flex space-x-1" data-test-id="frames">
      {panels?.map(({ id, width, height, count }) => (
        <div
          key={id}
          data-test-id="frame"
          className={cx(
            'text-center border rounded-sm border-neutral-950-opacity-10 bg-neutral-950-opacity-5 text-neutral-600 tabular-nums max-h-7 h-7 flex items-center',
            {
              'px-5': width > height,
              'px-2': width < height,
            },
          )}
        >
          {count}
        </div>
      ))}
    </div>
  );
};

export default PanelsRenderer;
