import { usePageFilters } from 'customHooks/usePageFilters';
import {
  FilterDealMultiId,
  FilterMultiDsp,
  FilterMultiCreativeId,
  FilterMarket,
  FilterMarketStatus,
} from 'components/common/FilterArea/Filters';
import FilterArea from 'components/common/FilterArea';
import { CreativeAdministrationPageFilters } from './Filters.types';

const Filters: React.FC = () => {
  const {
    filters: { dsps, dealIds, creativeIds, marketStatus, market },
    changeFilters,
  } = usePageFilters<CreativeAdministrationPageFilters>();

  return (
    <FilterArea customWrapperStyles="flex flex-1 h-9 gap-4 self-center">
      <FilterMarketStatus changeFilters={changeFilters} value={marketStatus} />
      <FilterMultiCreativeId
        changeFilters={(newFilter) =>
          changeFilters({
            creativeIds: newFilter.creativeIds,
          })
        }
        creativeIds={creativeIds}
      />
      <FilterMultiDsp changeFilters={changeFilters} dsps={dsps} />
      <FilterMarket market={market} changeFilters={changeFilters} />
      <FilterDealMultiId changeFilters={changeFilters} dealIds={dealIds} isDisabled={!market} />
    </FilterArea>
  );
};
export default Filters;
