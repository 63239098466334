import { CodeNameModel } from 'components/common/types';
import { FrameOrdering, FrameOrderingLabels } from 'components/common/types/FrameOrdering.types';
import BaseSelect from 'lib/BaseSelect';
import { findOption } from 'lib/BaseSelect/utils';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { onFilterChange } from 'store/pages/contentManagement/creativeSetDetails/reducer';

const frameOrderingList: CodeNameModel[] = [
  {
    name: FrameOrderingLabels.ORDERED,
    code: FrameOrdering.ORDERED,
  },
  {
    name: FrameOrderingLabels.UNORDERED,
    code: FrameOrdering.UNORDERED,
  },
];

const FilterFrameOrdering: React.FC = () => {
  const dispatch = useDispatch();
  const framesOrder = useSelector(
    (state: RootState) => state.pages.contentManagement.creativeSetDetails.filters.framesOrder,
  );

  return (
    <BaseSelect
      dataTestId="frame-ordering-select"
      name="frame-ordering"
      options={frameOrderingList}
      selectedValue={findOption(frameOrderingList, 'code', framesOrder)}
      onSelect={(_, value: CodeNameModel) => {
        dispatch(onFilterChange({ framesOrder: value.code as FrameOrdering }));
      }}
    />
  );
};

export default FilterFrameOrdering;
