import { useRef } from 'react';

import Line from 'components/pages/DealWithLines/common/Main/Line';
import ControllerBar from './ControllerBar';

const Main = () => {
  const dealRef = useRef(null);
  return (
    <>
      <Line />
      <ControllerBar dealRef={dealRef} />
    </>
  );
};

export default Main;
