import MultipleSwitchBox from 'components/patterns/MultipleSwitchBox';
import { FilterMarketStatusProps } from './FilterMarketStatus.types';

const marketStatuses = [
  { label: 'All', value: '' },
  { label: 'Missing market', value: 'false' },
  { label: 'Market assigned', value: 'true' },
];

const FilterMarketStatus: React.FC<FilterMarketStatusProps> = ({ changeFilters, value }) => {
  return (
    <div className="flex whitespace-nowrap">
      <MultipleSwitchBox
        source={marketStatuses}
        selectedValue={value || ''}
        textKey="label"
        valueKey="value"
        onChange={(marketStatus) => changeFilters({ marketStatus })}
      />
    </div>
  );
};

export default FilterMarketStatus;
