import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FeatureFlags } from 'components/common/types/Features.types';
import { getDeal } from 'modules/api/adsDeal';
import { CommonDeal, FrontEndType, Programmatic } from 'components/common/types/Deal.types';
import { BackupFormData, duplicateDeal } from 'store/dealManagement/reducer';
import { transformDealLineDetails } from 'components/pages/DealWithLines/AdsDealLines/transformDealDetails';
import { notifySuccess } from 'store/notification/reducer';
import { useHasFeatureAccess } from './useHasFeatureAccess';
import { useCancelRequest } from './useCancelRequest';

type DuplicateDealHandler = (dealId: string) => Promise<void>;

export interface UseDuplicateDealReturn {
  handleDuplicateDeal: DuplicateDealHandler;
}

export const useDuplicateDeal = (): UseDuplicateDealReturn => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cancelFunctions = useCancelRequest();
  const hasNonGuaranteedExtendedTargetEnabled = useHasFeatureAccess(FeatureFlags.NON_GUARANTEED_EXTENDED_TARGET);
  const hasAdsDealLevelCPMEnabled = useHasFeatureAccess(FeatureFlags.ADS_DEAL_LEVEL_CPM);

  const handleDuplicateDeal: DuplicateDealHandler = async (dealId) => {
    try {
      const dealDetails = await getDeal(dealId, cancelFunctions);
      const duplicatedDealDetails = {
        ...dealDetails,
        dealLines: [dealDetails.dealLines[0]],
      };
      dispatch(
        duplicateDeal({
          ...(transformDealLineDetails({
            ...duplicatedDealDetails,
            hasNonGuaranteedExtendedTargetEnabled,
          }) as {
            backupFormData: BackupFormData;
            commonDeal: CommonDeal;
            programmatic: Programmatic;
          }),
          hasAdsDealLevelCPMEnabled,
        }),
      );

      if (dealDetails.dealLines[0].frontEndType === FrontEndType.PLANNER) {
        navigate('/planner/programmatic');
      } else {
        navigate('/deal');
      }

      dispatch(
        notifySuccess({
          message: `${dealDetails.name} campaign has been duplicated`,
        }),
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error duplicating deal:', error);
    }
  };

  return {
    handleDuplicateDeal,
  };
};
