import { useSelector } from 'react-redux';
import { Store } from 'components/common/types/Store.types';
import EditableChip from 'lib/EditableChip';
import { PlannerSecondaryPanelType } from 'store/dealManagement/reducer';
import { useOnChangePanelVisibility } from 'components/pages/Planner/hooks/useOnChangePanelVisibility';
import { useDealAndLineActions } from 'components/pages/DealWithLines/AdsDealLines/hooks/useDealAndLineActions';
import { CampaignBookingStatusPanel } from './CampaignBookingStatusPanel';
import { bookingStatusStyles, defaultBookingStatusStyle } from '../../ChipBookingStatus/consts';
import { getCampaignBookingStatusOptions } from './utils';

const CampaignBookingStatus: React.FC = () => {
  const bookingStatus = useSelector((state: Store) => state.dealManagement.commonDeal.bookingStatusCode);
  const plannerSecondaryPanelType = useSelector((state: Store) => state.dealManagement.plannerSecondaryPanelType);

  const { saveDeal } = useDealAndLineActions();
  const onChangePanelVisibility = useOnChangePanelVisibility();

  const isStatusPanelOpen = plannerSecondaryPanelType === PlannerSecondaryPanelType.PROGRAMMATIC_STATUS;
  const styles = bookingStatusStyles[bookingStatus] ?? defaultBookingStatusStyle;

  const statusOptions = getCampaignBookingStatusOptions();
  const isDisabled = statusOptions.every(({ isVisible }) => !isVisible);

  return (
    <>
      <EditableChip
        buttonLabel="Update status"
        chipLabel={styles.label}
        chipColor={styles.color}
        dataTestId="campaign-booking-status"
        isActive={isStatusPanelOpen}
        isDisabled={isDisabled}
        onClick={() => onChangePanelVisibility(PlannerSecondaryPanelType.PROGRAMMATIC_STATUS)}
      />
      {isStatusPanelOpen && <CampaignBookingStatusPanel onConfirm={saveDeal} statusOptions={statusOptions} />}
    </>
  );
};

export default CampaignBookingStatus;
