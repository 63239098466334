import { useRef, useState, useEffect, MutableRefObject } from 'react';
import { useNavigate } from 'react-router-dom';
import { GridApi, ICellRendererParams, IDatasource } from 'ag-grid-community';
import SVG from 'react-inlinesvg';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { fetchCreativeSetTable } from 'modules/api/creativeSets';
import { CREATIVE_STATUS_BADGE } from 'consts/creative';
import { usePageFilters } from 'customHooks/usePageFilters';
import { CreativeSetsFilters } from 'store/pages/contentManagement/creativeSets/reducer';
import withLoader from 'components/hocs/withLoader';
import Table, { TableHeight, TableRowHeight } from 'components/patterns/Table';
import StatusRenderer from 'components/common/StatusRenderer';
import FrameDetailsRenderer from 'components/common/FrameDetailsRenderer';
import FramesRenderer from 'components/common/FramesRenderer';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';
import emptyIcon from 'assets/icons/empty.svg';
import { CreativeSetPanel } from 'components/common/types/Creative.types';
import { transformCreativeSetsData } from 'components/pages/ContentManagement/transformRequestData';
import EditRenderer from './EditRenderer';
import CreativesRenderer from './CreativesRenderer';

const pageSize = 20;

const columnDefs = [
  {
    headerName: '',
    field: '',
    maxWidth: 90,
    minWidth: 50,
    headerTooltip: '',
    cellRenderer: EditRenderer,
    resizable: false,
    pinned: 'left',
  },
  {
    headerName: 'Status',
    field: 'status',
    maxWidth: 90,
    minWidth: 90,
    headerTooltip: 'Status',
    cellRenderer: StatusRenderer,
    cellRendererParams: { statuses: CREATIVE_STATUS_BADGE },
    colId: 'status',
    pinned: 'left',
  },
  {
    headerName: 'Creatives',
    field: 'creatives',
    minWidth: 190,
    headerTooltip: 'Creatives',
    cellRenderer: CreativesRenderer,
    colId: 'creatives',
    pinned: 'left',
  },
  {
    headerName: 'No. VU',
    field: 'visualUnitIds',
    maxWidth: 90,
    minWidth: 90,
    headerTooltip: 'No. VU',
    cellRenderer: FrameDetailsRenderer,
    colId: 'visualUnitIds',
    pinned: 'left',
  },
  {
    headerName: 'No. frames',
    field: 'panels',
    minWidth: 150,
    headerTooltip: 'No. frames',
    cellRenderer: FramesRenderer,
    colId: 'panels',
    pinned: 'left',
  },
  {
    headerName: 'Size (WxH)',
    field: 'sizes',
    minWidth: 150,
    headerTooltip: 'Size',
    cellRenderer: (params: ICellRendererParams) =>
      params.data?.panels
        ? params.data.panels.map((item: CreativeSetPanel) => `${item.width}x${item.height}`).join(', ')
        : '',
  },
  {
    headerName: 'DSP',
    field: 'dsp',
    minWidth: 150,
    headerTooltip: 'DSP',
    cellRenderer: (params: ICellRendererParams) => params.data?.dsp.name || '',
    colId: 'dsp',
  },
  {
    headerName: 'Advertiser',
    field: 'advertiser',
    minWidth: 150,
    headerTooltip: 'Advertiser',
    cellRenderer: (params: ICellRendererParams) => params.data?.advertiser.name || '',
    colId: 'advertiser',
  },
  {
    headerName: 'Brand',
    field: 'brand',
    minWidth: 150,
    headerTooltip: 'Brand',
    cellRenderer: (params: ICellRendererParams) => params.data?.brand.name || '',
    colId: 'brand',
  },
  {
    headerName: 'Creative set code',
    field: 'code',
    minWidth: 150,
    headerTooltip: 'Creative set code',
    colId: 'code',
    resizable: false,
  },
];

// eslint-disable-next-line sonarjs/cognitive-complexity
const CreativeSetTable: React.FC = () => {
  const navigate = useNavigate();
  const tableApiRef: MutableRefObject<GridApi | undefined> = useRef();
  const { filters } = usePageFilters<CreativeSetsFilters>();
  const [isEmptyRows, setIsEmptyRows] = useState(true);
  const cancelFunctions = useCancelRequest();

  const { creativeSetCode, dsp, advertiser, brand, visualUnitCode } = filters;

  const getDataSource = (): IDatasource => ({
    getRows: async (params) => {
      const { content, totalElements } = await fetchCreativeSetTable(cancelFunctions, {
        ...(creativeSetCode ? { creativeSetCode } : {}),
        ...(dsp ? { dspCode: dsp.code } : {}),
        ...(advertiser ? { advertiserCode: advertiser.code } : {}),
        ...(brand ? { brandCode: brand.code } : {}),
        ...(visualUnitCode ? { visualUnitCode } : {}),
        page: params.startRow / pageSize,
        size: pageSize,
      });
      const transformedContent = transformCreativeSetsData(content);

      if (totalElements === 0) {
        setIsEmptyRows(true);
        params.successCallback(transformedContent, totalElements);
        return;
      }

      setIsEmptyRows(false);
      params.successCallback(transformedContent, totalElements);
    },
  });

  const getTableApi = (tableApi: GridApi): void => {
    tableApiRef.current = tableApi;
    tableApi.setDatasource(getDataSource());
  };

  useEffect(() => {
    tableApiRef.current?.setDatasource(getDataSource());
  }, [filters]);

  return (
    <>
      <div className={isEmptyRows ? 'hidden' : 'block'}>
        <Table
          columnDefs={columnDefs}
          getTableApi={getTableApi}
          extraGridOptions={{
            pagination: true,
            paginationPageSize: pageSize,
            cacheBlockSize: pageSize,
            enableCellTextSelection: true,
            domLayout: 'autoHeight',
            rowModelType: 'infinite',
            rowHeight: TableRowHeight.NORMAL,
            rowSelection: 'single',
          }}
          tableSize={{ tableHeight: TableHeight.AUTO }}
          dataTestId="creative-set-table"
        />
      </div>
      {isEmptyRows && (
        <div className="flex flex-col justify-center items-center min-h-screen bg-neutral-50 border rounded-lg border-neutral-100-opacity-10 text-neutral-950-opacity-60">
          <SVG src={emptyIcon} />
          <p className="header-lg mt-11 mb-4">No creative sets found</p>
          <p className="mb-4 text-center body-base">
            You have not created any set yet. Please, start your first <br /> creative set to assign to your visual
            units
          </p>
          <Button
            onClick={() => navigate('/content-management/creative-set-details')}
            btnShape={ButtonShape.NORMAL}
            btnType={ButtonType.TERTIARY}
          >
            New create set
          </Button>
        </div>
      )}
    </>
  );
};

export default withLoader(CreativeSetTable);
