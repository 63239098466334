import React from 'react';
import cx from 'classnames';
import SVG from 'react-inlinesvg';
import { useSelector } from 'react-redux';
import cpuIcon from 'assets/icons/cpu.svg';
import compassIcon from 'assets/icons/compass.svg';
import { CampaignType } from 'components/pages/Planner/PlannerSections/types';
import { Store } from 'components/common/types/Store.types';

const tradingTypeStyles: Partial<Record<CampaignType, { icon: string; bgColor: string; iconColor: string }>> = {
  [CampaignType.PROGRAMMATIC]: {
    icon: cpuIcon,
    bgColor: 'bg-purple-200',
    iconColor: 'text-purple-600',
  },
  [CampaignType.DIRECT]: {
    icon: compassIcon,
    bgColor: 'bg-orange-300',
    iconColor: 'text-orange-600',
  },
} as const;

const defaultTradingTypeStyle = tradingTypeStyles[CampaignType.PROGRAMMATIC]!;

export const IconTradingType: React.FC = () => {
  const tradingType = useSelector((state: Store) => state.dealManagement.campaignType);

  const styles = tradingTypeStyles[tradingType] ?? defaultTradingTypeStyle;

  return (
    <div
      className={cx('h-6 w-6 flex shrink-0 justify-center items-center rounded', styles.bgColor)}
      data-test-id={`${tradingType}-trading-type`}
    >
      <SVG src={styles.icon} className={cx('h-4 w-4', styles.iconColor)} name="Trading type" />
    </div>
  );
};
