import { DirectSalesLineObjective } from 'components/common/types/DirectSalesCampaign.types';
import { TargetObjectiveId } from 'consts/targetCriteria';

export const getNewObjectiveId = (objectives: DirectSalesLineObjective[]): number => {
  if (!objectives.length) return 1;
  const currentMaxId = Math.max(...objectives.map((objective) => objective.id));

  return currentMaxId + 1;
};

export const getNewTargetId = (lineId: string, targetType: TargetObjectiveId, objectiveId: number = 0): number => {
  const lineIndex = Number(lineId.split('-')[2]);

  return (lineIndex * 1000 + objectiveId) * 1000 + targetType;
};

export const getObjectiveTargetIds = (
  targetId: number,
): { objectiveId: number; targetType: keyof TargetObjectiveId; targetIndex: number } => {
  const targetIdString = targetId.toString();
  const targetIndex = Number(targetIdString.slice(targetIdString.length - 3));
  const objectiveId = Number(targetIdString.slice(targetIdString.length - 5, targetIdString.length - 3));
  const targetType = Object.keys(TargetObjectiveId)[
    Object.values(TargetObjectiveId).indexOf(targetIndex)
  ] as keyof TargetObjectiveId;

  return { objectiveId, targetType, targetIndex };
};

export const getUniqueObjectiveName = (objectives: DirectSalesLineObjective[], idx: number): string => {
  const existingObjectivesNames = objectives.map(({ name }) => name.toLowerCase());
  const newObjectiveName = `Objective ${idx}`;

  if (!existingObjectivesNames.includes(newObjectiveName.toLowerCase())) return newObjectiveName;

  return getUniqueObjectiveName(objectives, idx + 1);
};
