import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import SVG from 'react-inlinesvg';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';
import closeSvg from 'assets/icons/close.svg';
import './Modal.pcss';

export const ModalSize = {
  SMALL: 'w-9/12 md:w-6/12 lg:w-125',
  MEDIUM: 'w-10/12 md:w-9/12 lg:w-200',
  LARGE: 'w-11/12 xl:w-300',
};

const Modal = ({
  isOpen,
  title,
  size,
  onClose,
  children,
  dataTestId,
  icon,
  actionButtons,
  showCloseButton,
  sidebar,
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={showCloseButton}
      shouldCloseOnEsc={showCloseButton}
      onRequestClose={onClose}
      overlayClassName="bg-modalOverlay-dark"
      className={`bg-neutral-50 absolute transform -translate-y-2/4 -translate-x-2/4 top-1/2 left-1/2 rounded-md ${size}`}
    >
      <div data-test-id={dataTestId} className="flex max-h-popup">
        {showCloseButton ? (
          <div className="absolute -right-3 -top-3">
            <Button
              onClick={onClose}
              btnShape={ButtonShape.CIRCLE}
              btnType={ButtonType.CLOSE}
              dataTestId="modal-close-button"
            >
              <SVG src={closeSvg} />
            </Button>
          </div>
        ) : null}
        <div className="flex flex-col flex-1 space-y-5 my-8">
          {icon || title ? (
            <div className="flex items-center space-x-3 px-8">
              <div>{icon}</div>
              <div className="sub-header-lg text-neutral-900">{title}</div>
            </div>
          ) : null}
          <div className="overflow-auto max-h-popupContent leading-normal px-8 flex-1">{children}</div>
          {actionButtons ? <div className="flex justify-end space-x-3 px-8">{actionButtons}</div> : null}
        </div>
        {sidebar ? <div className="overflow-auto w-9/20 border-l border-neutral-100-opacity-10">{sidebar}</div> : null}
      </div>
    </ReactModal>
  );
};

Modal.propTypes = {
  dataTestId: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node,
  title: PropTypes.node,
  icon: PropTypes.node,
  actionButtons: PropTypes.node,
  size: PropTypes.string,
  showCloseButton: PropTypes.bool,
  onClose: PropTypes.func,
  sidebar: PropTypes.node,
};

Modal.defaultProps = {
  dataTestId: '',
  children: null,
  title: null,
  icon: null,
  actionButtons: null,
  size: ModalSize.SMALL,
  showCloseButton: false,
  onClose: () => undefined,
  sidebar: null,
};

export default Modal;
