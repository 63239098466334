import SVG from 'react-inlinesvg';
import cx from 'classnames';
import ButtonProps, { Align, Color, ButtonTheme, TextSize, Size, TextOverflow } from './Button.types';

const Button = ({
  ariaLabel,
  classNames,
  color = Color.PRIMARY,
  dataTestId,
  label,
  onClick,
  isDisabled,
  size = Size.MEDIUM,
  svg,
  svgClassnames,
  ddActionName,
  textSize = TextSize.MEDIUM,
  textOverflow = TextOverflow.HIDDEN,
  align = Align.CENTER,
  dataPendoId,
}: ButtonProps): React.ReactElement => {
  return (
    <button
      data-test-id={dataTestId}
      type="button"
      onClick={onClick}
      disabled={isDisabled}
      aria-label={ariaLabel}
      data-dd-action-name={ddActionName ?? label}
      data-pendo-id={dataPendoId}
      className={cx(
        'flex items-center gap-1 rounded-md leading-none align-middle disabled:cursor-not-allowed disabled:bg-neutral-300 disabled:text-neutral-400',
        ButtonTheme.theme[color],
        ButtonTheme.size[size],
        ButtonTheme.textSize[textSize],
        ButtonTheme.align[align],
        classNames,
      )}
    >
      {svg ? <SVG src={svg} className={cx('fill-current inline-block w-4 h-4', svgClassnames)} /> : null}
      {label && (
        <span className={cx('whitespace-nowrap text-ellipsis', ButtonTheme.textOverflow[textOverflow])}>{label}</span>
      )}
    </button>
  );
};

export default Button;
