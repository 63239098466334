import createRequest from 'modules/api/request';
import { URLS, HTTP_METHODS, HEADERS_CONTENT_TYPE } from 'modules/api/constants';
import { FrontEndType } from 'components/common/types/Deal.types';
import { isCreatedAfterSummaryPanelChanges } from 'components/pages/DealWithLines/AdsDealLines/transformDealDetails';
import handleRequest from './common';

export const getDealConfig = (marketId, cancelFunctions) => {
  const reqOptions = {
    url: URLS().FETCH_DEAL_CONFIG(marketId),
    method: HTTP_METHODS.GET,
  };
  return handleRequest(reqOptions, cancelFunctions, 'FETCH_DEAL_CONFIG');
};

export const getDeal = async (id, cancelFunctions) => {
  const reqOptions = {
    url: URLS().FETCH_DEAL(id),
    method: HTTP_METHODS.GET,
    showLoader: true,
  };

  let dealResponse = await handleRequest(reqOptions, cancelFunctions, 'FETCH_DEAL');

  if (isCreatedAfterSummaryPanelChanges({ dealType: dealResponse.dealTypeCode, lines: dealResponse.dealLines })) {
    dealResponse = {
      ...dealResponse,
      totalImpressions: dealResponse.campaignImpressions.aggregatedImpressions,
      dealLines: dealResponse.dealLines.map((dealLine) => ({
        ...dealLine,
        totalImpressions: dealLine.lineImpressions.aggregatedImpressions,
      })),
    };
  }

  return dealResponse;
};

export const createDeal = (cancelFunctions, data, frontEndType = FrontEndType.STANDARD) => {
  const reqOptions = {
    url: URLS().CREATE_DEAL(),
    method: HTTP_METHODS.POST,
    data,
    headers: {
      'Content-Type': HEADERS_CONTENT_TYPE.MULTIPART_FORM_DATA,
      'ui-type': frontEndType,
    },
    notificationTimeout: 0,
  };

  return handleRequest(reqOptions, cancelFunctions, 'CREATE_DEAL');
};

export const approveDeal = (cancelFunctions, { dealId }) => {
  const reqOptions = {
    url: URLS().APPROVE_DEAL(dealId),
    method: HTTP_METHODS.PUT,
    headers: {
      'Content-Type': HEADERS_CONTENT_TYPE.MULTIPART_FORM_DATA,
    },
    notificationTimeout: 0,
  };

  return handleRequest(reqOptions, cancelFunctions, 'APPROVE_DEAL');
};

export const rejectDeal = (cancelFunctions, { dealId }) => {
  const reqOptions = {
    url: URLS().REJECT_DEAL(dealId),
    method: HTTP_METHODS.PUT,
    headers: {
      'Content-Type': HEADERS_CONTENT_TYPE.MULTIPART_FORM_DATA,
    },
    notificationTimeout: 0,
  };

  return handleRequest(reqOptions, cancelFunctions, 'REJECT_DEAL');
};

export const cancelDeal = (cancelFunctions, { dealId }) => {
  const reqOptions = {
    url: URLS().CANCEL_DEAL(dealId),
    method: HTTP_METHODS.PUT,
    headers: {
      'Content-Type': HEADERS_CONTENT_TYPE.MULTIPART_FORM_DATA,
    },
    notificationTimeout: 0,
  };

  return handleRequest(reqOptions, cancelFunctions, 'CANCEL_DEAL');
};

export const reserveDeal = (cancelFunctions, { dealId }) => {
  const reqOptions = {
    url: URLS().EDIT_DEAL(dealId),
    method: HTTP_METHODS.PATCH,
    data: { action: 'RESERVE' },
  };

  return handleRequest(reqOptions, cancelFunctions, 'RESERVE_DEAL');
};

export const createLine = (cancelFunctions, data, { dealId }) => {
  const reqOptions = {
    url: URLS().CREATE_LINE(dealId),
    method: HTTP_METHODS.POST,
    data,
    headers: {
      'Content-Type': HEADERS_CONTENT_TYPE.MULTIPART_FORM_DATA,
    },
    notificationTimeout: 0,
  };

  return handleRequest(reqOptions, cancelFunctions, 'CREATE_LINE');
};

export const updateLine = (cancelFunctions, data, { dealId, lineId }) => {
  const reqOptions = {
    url: URLS().UPDATE_LINE(dealId, lineId),
    method: HTTP_METHODS.POST,
    data,
    headers: {
      'Content-Type': HEADERS_CONTENT_TYPE.MULTIPART_FORM_DATA,
    },
    notificationTimeout: 0,
  };
  return handleRequest(reqOptions, cancelFunctions, 'UPDATE_LINE');
};

export const checkAvailability = (data, cancelFunctions) => {
  const reqOptions = {
    url: URLS().CHECK_ADS_AVAILABILITY(),
    method: HTTP_METHODS.POST,
    data,
    showLoader: false,
    headers: {
      'Content-Type': HEADERS_CONTENT_TYPE.MULTIPART_FORM_DATA,
    },
    notificationTimeout: 0,
  };

  return handleRequest(reqOptions, cancelFunctions, 'CHECK_ADS_AVAILABILITY');
};

export const stopLine = (cancelFunctions, { dealId, lineId }) => {
  const reqOptions = {
    url: URLS().STOP_LINE(dealId, lineId),
    method: HTTP_METHODS.POST,
    headers: {
      'Content-Type': HEADERS_CONTENT_TYPE.APPLICATION_JSON,
    },
    notificationTimeout: 0,
  };
  return handleRequest(reqOptions, cancelFunctions, 'STOP_LINE');
};

export const deleteLine = (cancelFunctions, { dealId, lineId }) => {
  const reqOptions = {
    url: URLS().DELETE_LINE(dealId, lineId),
    method: HTTP_METHODS.DELETE,
    headers: {
      'Content-Type': HEADERS_CONTENT_TYPE.APPLICATION_JSON,
    },
    notificationTimeout: 0,
  };
  return handleRequest(reqOptions, cancelFunctions, 'DELETE_LINE');
};

export const getDealProcessingStatus = (dealId, cancelFunctions) => {
  const reqOptions = {
    url: URLS().FETCH_DEAL_PROCESS_STATUS(dealId),
    method: HTTP_METHODS.GET,
  };

  const { poll, cancel } = createRequest(reqOptions);

  if (cancelFunctions.FETCH_DEAL_PROCESS_STATUS) cancelFunctions.FETCH_DEAL_PROCESS_STATUS();
  cancelFunctions.FETCH_DEAL_PROCESS_STATUS = cancel;

  return poll;
};

export const checkAllocate = (cancelFunctions, data) => {
  const reqOptions = {
    url: URLS().CHECK_ALLOCATE(),
    method: HTTP_METHODS.POST,
    data,
    headers: {
      'Content-Type': HEADERS_CONTENT_TYPE.MULTIPART_FORM_DATA,
    },
    notificationTimeout: 0,
  };

  return handleRequest(reqOptions, cancelFunctions, 'CHECK_ALLOCATE');
};

export const getAllocatedDeal = async (id, cancelFunctions, dealType, lines) => {
  const reqOptions = {
    url: URLS().FETCH_ALLOCATED_DEAL(id),
    method: HTTP_METHODS.GET,
    showLoader: true,
  };

  let allocatedDealResponse = await handleRequest(reqOptions, cancelFunctions, 'FETCH_ALLOCATED_DEAL');

  if (isCreatedAfterSummaryPanelChanges({ dealType, lines }))
    allocatedDealResponse = {
      ...allocatedDealResponse,
      totalImpressions: allocatedDealResponse.summary.campaignImpressions.aggregatedImpressions,
    };

  return allocatedDealResponse;
};

export const editDeal = (cancelFunctions, dealId, data) => {
  const reqOptions = {
    url: URLS().EDIT_DEAL(dealId),
    method: HTTP_METHODS.PUT,
    data,
  };

  return handleRequest(reqOptions, cancelFunctions, 'EDIT_DEAL');
};

export const getLineImpressionMetrics = (cancelFunctions, lineId) => {
  const reqOptions = {
    url: URLS().FETCH_LINE_IMPRESSION_METRICS(lineId),
    method: HTTP_METHODS.GET,
  };

  return handleRequest(reqOptions, cancelFunctions, 'FETCH_LINE_IMPRESSION_METRICS');
};

export const getCampaignImpressionMetrics = (cancelFunctions, campaignId) => {
  const reqOptions = {
    url: URLS().FETCH_CAMPAIGN_IMPRESSION_METRICS(campaignId),
    method: HTTP_METHODS.GET,
  };

  return handleRequest(reqOptions, cancelFunctions, 'FETCH_CAMPAIGN_IMPRESSION_METRICS');
};
