import { getActionConditions } from 'components/pages/DealWithLines/common/getActionConditions';
import { useSelector } from 'react-redux';
import { Store } from 'components/common/types/Store.types';
import { getIsAdServer, getIsReseller } from 'store/publisher/selectors';
import { DealStatus } from 'components/common/types/Deal.types';
import { StatusOption } from './CampaignBookingStatus.types';

export const getCampaignBookingStatusOptions = (): StatusOption[] => {
  const dealManagementState = useSelector((state: Store) => state.dealManagement);
  const isAdServerMarket = useSelector(getIsAdServer);
  const dealConfig = useSelector((state: Store) => state.dealConfig);
  const isReseller = useSelector(getIsReseller);

  const { cancelDeal, confirmDeal, pendingApprovalDeal, rejectDeal, terminateDeal } = getActionConditions(
    dealManagementState,
    isAdServerMarket,
    dealConfig,
    isReseller,
  );

  return [
    {
      name: 'Send for approval',
      code: DealStatus.PENDING_APPROVAL,
      isDisabled: pendingApprovalDeal.disabled,
      isVisible: pendingApprovalDeal.show,
    },
    {
      name: 'Confirm',
      code: DealStatus.APPROVED,
      isDisabled: confirmDeal.disabled,
      isVisible: confirmDeal.show,
    },
    {
      name: 'Cancel',
      code: DealStatus.CANCELLED,
      isDisabled: cancelDeal.disabled,
      isVisible: cancelDeal.show,
    },
    {
      name: 'Reject',
      code: DealStatus.REJECTED,
      isDisabled: rejectDeal.disabled,
      isVisible: rejectDeal.show,
    },
    {
      name: 'Terminate',
      code: DealStatus.TERMINATED,
      isDisabled: terminateDeal.disabled,
      isVisible: terminateDeal.show,
    },
  ];
};
