import { useSelector } from 'react-redux';

import { isReadOnly } from 'utils/isReadOnly';
import { getIsNewLine } from 'store/dealManagement/selectors';
import { Store } from 'components/common/types/Store.types';

export const useDealFormReadOnly = (): boolean => {
  const bookingStatusCode = useSelector((state: Store) => state.dealManagement.commonDeal.bookingStatusCode);
  const isEditingDisabled = useSelector((state: Store) => state.dealManagement.isEditingDisabled);
  const isLineTerminated = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine?.terminated);
  const isNewLine = useSelector(getIsNewLine);

  return !isNewLine && isReadOnly(bookingStatusCode, isEditingDisabled, isLineTerminated);
};
