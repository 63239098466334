import { Dsp } from 'components/common/types/Deal.types';
import AsyncSelect from 'components/patterns/AsyncSelect';
import { FunctionComponent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getDsps } from 'store/publisher/selectors';
import { FilterDspProps } from './FilterDsp.types';

const FilterDsp: FunctionComponent<FilterDspProps> = ({
  dataTestId = 'dsp-filter',
  isDisabled = false,
  selectedValue,
  placeholder = 'Select DSP',
  onChange,
  theme,
  size,
  padding,
  shadow,
  shape,
}) => {
  const dsps = useSelector(getDsps);

  const loadDSPData = (_dropdownName?: string, qry?: string): Dsp[] => {
    return qry ? dsps.filter((dsp) => dsp.name.toLowerCase().indexOf(qry.toLowerCase()) > -1) : dsps;
  };

  useEffect(() => {
    loadDSPData();
  }, []);

  return (
    <AsyncSelect
      dataTestId={dataTestId}
      name="dsp"
      loadData={loadDSPData}
      defaultOptions={dsps}
      onSelect={onChange}
      placeholder={placeholder}
      selectedValue={selectedValue && selectedValue.code ? selectedValue : null}
      isDisabled={isDisabled}
      debounceTime={0}
      theme={theme}
      size={size}
      padding={padding}
      shadow={shadow}
      shape={shape}
    />
  );
};

export default FilterDsp;
