import FilterArea from 'components/common/FilterArea';
import FilterFrameCode from 'components/common/FilterArea/Filters/FilterFrameCode';
import FilterProductFormat from 'components/pages/InventoryManagement/AssetList/AssetContent/Filters/FilterProductFormat';
import FilterRouteFrameCode from 'components/pages/InventoryManagement/AssetList/AssetContent/Filters/FilterRouteFrameCode';
import { AssetListFilters } from 'components/common/types/AssetList.types';
import { usePageFilters } from 'customHooks/usePageFilters';
import AdvancedFilters from './AdvancedFilters/AdvancedFilters';

const Filters: React.FC = () => {
  const {
    filters: { frameCode, productFormat, routeFrameCode },
    changeFilters,
  } = usePageFilters<AssetListFilters>();

  return (
    <div className="mb-4">
      <FilterArea advancedFilters={<AdvancedFilters />}>
        <div className="grid grid-cols-2 gap-x-4 mr-4">
          <FilterRouteFrameCode changeFilters={changeFilters} routeFrameCode={routeFrameCode || ''} />
          <FilterFrameCode changeFilters={changeFilters} frameCode={frameCode || ''} />
        </div>
        <div>
          <FilterProductFormat changeFilters={changeFilters} productFormat={productFormat} />
        </div>
      </FilterArea>
    </div>
  );
};

export default Filters;
