import { DealStatus, RouteFrameCode } from 'components/common/types/Deal.types';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'components/common/types/Store.types';
import { changeDealCurrentLineData } from 'store/dealManagement/reducer';
import { CodeNameModel, FileList } from 'components/common/types';

type UseRouteFrameListProps = {
  isPendingReservation: boolean;
  handleFrameSelection: (frameCodes: RouteFrameCode[]) => void;
  clearFramesSelection: VoidFunction;
  onIncludeExcludeChange: (value: CodeNameModel) => void;
  handleFrameListUpload: (newFilesList: FileList[]) => void;
  updateIncludeExclude: (frameCodes: CodeNameModel[], value: CodeNameModel) => CodeNameModel[];
  removeFrameByCode: (formats: CodeNameModel[], code: string) => CodeNameModel[];
  updateFrameSelection: (
    frameCodes: RouteFrameCode[],
    value: CodeNameModel,
    isFrameRemovableOnClick?: boolean,
  ) => CodeNameModel[];
  removeFrameListFile: (frameFileName: string) => void;
  updateUploadedFrameList: (frameFiles: FileList[]) => void;
};

export const useRouteFrameList = (): UseRouteFrameListProps => {
  const dispatch = useDispatch();
  const bookingStatusCode = useSelector((state: Store) => state.dealManagement.commonDeal.bookingStatusCode);
  const routeFrameCodes = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.routeFrameCodes);
  const uploadedFrameLists = useSelector(
    (state: Store) => state.dealManagement.commonDeal.currentLine.uploadedFrameLists,
  );
  const isPendingReservation = bookingStatusCode === DealStatus.PENDING_RESERVATION;

  const handleFrameSelection = (frameCodes: RouteFrameCode[]): void => {
    dispatch(changeDealCurrentLineData({ routeFrameCodes: frameCodes }));
  };

  const clearFramesSelection = (): void => {
    dispatch(changeDealCurrentLineData({ routeFrameCodes: [], listFiles: [] }));
  };

  const updateIncludeExclude = (frameCodes: CodeNameModel[], value: CodeNameModel): CodeNameModel[] => {
    return frameCodes.map((frameCode) =>
      frameCode.code === value.code ? { ...frameCode, include: value.include } : frameCode,
    );
  };

  const removeFrameByCode = (frames: CodeNameModel[], code: string): CodeNameModel[] => {
    return frames.filter((frame) => frame.code !== code);
  };

  const updateFrameSelection = (
    frameCodes: RouteFrameCode[],
    value: CodeNameModel,
    isFrameRemovableOnClick: boolean = true,
  ): CodeNameModel[] => {
    const frameCodeExists = frameCodes.find(({ code }) => code === value.code);

    if (frameCodeExists) {
      if (value.include !== undefined) {
        return updateIncludeExclude(frameCodes, value);
      }

      if (isFrameRemovableOnClick) {
        return removeFrameByCode(frameCodes, value.code);
      }
      return frameCodes;
    }

    return [...frameCodes, value];
  };

  const onIncludeExcludeChange = (value: CodeNameModel): void => {
    const frameCodes = structuredClone(routeFrameCodes);

    if (!frameCodes) return;

    const updatedFrameCodes = updateFrameSelection(frameCodes, value);
    handleFrameSelection(updatedFrameCodes);
  };

  const handleFrameListUpload = (newFilesList: FileList[]): void => {
    if (newFilesList) {
      dispatch(changeDealCurrentLineData({ listFiles: newFilesList }));
    }
  };

  const removeFrameListFile = (frameFileName: string): void => {
    const updatedListOfUploadedFrames = uploadedFrameLists.filter(({ name }) => name !== frameFileName);

    dispatch(changeDealCurrentLineData({ uploadedFrameLists: updatedListOfUploadedFrames }));
  };

  const updateUploadedFrameList = (frameFiles: FileList[]): void => {
    dispatch(changeDealCurrentLineData({ uploadedFrameLists: frameFiles }));
  };

  return {
    isPendingReservation,
    handleFrameSelection,
    clearFramesSelection,
    onIncludeExcludeChange,
    handleFrameListUpload,
    updateIncludeExclude,
    removeFrameByCode,
    updateFrameSelection,
    removeFrameListFile,
    updateUploadedFrameList,
  };
};
