import { ProductFormat } from 'components/common/types/AssetList.types';
import AsyncSelect from 'components/patterns/AsyncSelect';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { getProductFormats } from 'modules/api/inventory';
import { formatFilterOptionsName } from 'utils/formatters';
import { FilterProductFormatProps } from './FilterProductFormat.types';

const FilterProductFormat: React.FC<FilterProductFormatProps> = ({ changeFilters, productFormat }) => {
  const cancelFunctions = useCancelRequest();

  const fetchProductFormats = async (query: string): Promise<ProductFormat[]> => {
    if (query.length >= 3) {
      try {
        return await getProductFormats(cancelFunctions, query);
      } catch (error) {
        return [];
      }
    }
    return [];
  };

  return (
    <AsyncSelect
      dataTestId="product-format"
      name="productFormat"
      loadData={async (_name: string, query: string) => formatFilterOptionsName(await fetchProductFormats(query))}
      onSelect={(_name: string, value: ProductFormat) => {
        changeFilters({ productFormat: value });
      }}
      placeholder="PFCode01 - Product format name"
      selectedValue={productFormat}
    />
  );
};

export default FilterProductFormat;
