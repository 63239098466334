import { useDsps } from 'customHooks/useDsps';
import { useResellerMediaOwners } from 'customHooks/useResellerMediaOwners';
import { ComponentType } from 'react';

export const withDsps = <P extends object>(WrappedComponent: ComponentType<P>) => {
  return (props: P) => {
    useResellerMediaOwners();
    useDsps();

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <WrappedComponent {...props} />;
  };
};
