import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BannerButtonSettings, BannerType } from 'lib/Banner/Banner.types';

export interface BannerState {
  type: BannerType;
  headerInfo?: string;
  contentInfo?: string;
  discardButton?: BannerButtonSettings;
  confirmButton?: BannerButtonSettings;
}

export const initialState: BannerState = {
  type: BannerType.NONE,
  headerInfo: '',
  contentInfo: '',
  confirmButton: {},
  discardButton: {},
};

export const bannerSlice = createSlice({
  name: 'banner',
  initialState,
  reducers: {
    showBanner: (_state, action: PayloadAction<BannerState>) => {
      return {
        type: action.payload.type,
        confirmButton: action.payload.confirmButton,
        discardButton: action.payload.discardButton,
        headerInfo: action.payload.headerInfo,
        contentInfo: action.payload.contentInfo,
      };
    },
    hideBanner: () => {
      return {
        ...initialState,
      };
    },
  },
});

export const { showBanner, hideBanner } = bannerSlice.actions;
export default bannerSlice.reducer;
