import { getNetworkGroups } from 'modules/api/referenceData';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import AsyncSelect from 'components/patterns/AsyncSelect';
import { CodeNameModel } from 'components/common/types';
import { FilterNetworkGroupProps } from './FilterNetworkGroup.types';

const DEFAULT_PLACEHOLDER = 'Select network group name';

const FilterNetworkGroup: React.FC<FilterNetworkGroupProps> = ({
  selectedNetworkGroupName,
  changeFilters,
  placeholder,
}) => {
  const cancelFunctions = useCancelRequest();

  const loadNetworks = async (_name: string, query: string): Promise<CodeNameModel[]> => {
    try {
      return query.length > 1 ? await getNetworkGroups(cancelFunctions, query) : [];
    } catch (error) {
      return [];
    }
  };

  return (
    <AsyncSelect
      dataTestId="network-group-input"
      name="networkGroup"
      textKey="name"
      loadData={loadNetworks}
      onSelect={(_name: string, networkGroupName: CodeNameModel) => changeFilters({ networkGroupName })}
      placeholder={placeholder || DEFAULT_PLACEHOLDER}
      selectedValue={selectedNetworkGroupName}
    />
  );
};

export default FilterNetworkGroup;
