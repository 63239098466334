export enum Theme {
  DARK = 'DARK',
  LIGHT = 'LIGHT',
}

export enum Padding {
  TIGHT = 'TIGHT',
  LOOSE = 'LOOSE',
}

export enum Width {
  MIN = 'MIN',
  MAX = 'MAX',
}

export enum Height {
  SMALL = 'SMALL',
  LARGE = 'LARGE',
}

export interface ButtonGroupProps {
  onChange: (value: string) => void;
  options: {
    text: string;
    value: string;
    isDisabled?: boolean;
  }[];
  selectedValue: string;
  padding?: Padding;
  theme?: Theme;
  dataTestId?: string;
  icon?: string;
  isDisabled?: boolean;
  width?: Width;
  height?: Height;
}
