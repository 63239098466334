import classNames from 'classnames/bind';
import EmptyPieChart from 'components/common/HeroCharts/EmptyPieChart';
import { Store } from 'components/common/types/Store.types';
import { PieChart } from 'components/patterns/HighChart';
import { formatNumber } from 'modules/I18N';
import { useSelector } from 'react-redux';
import { theme } from 'tailwind-config';
import styles from './BidsRenderer.pcss';
import { BidsRendererProps } from './BidsRenderer.types';
import { getChartData, getChartOptions, getNetValue } from './utils/utils';

const cx = classNames.bind(styles);

const BidsRenderer: React.FC<BidsRendererProps> = ({ value = {}, data = {}, onClick = () => undefined }) => {
  const localeCode = useSelector((state: Store) => state.publisher.configuration.localeCode);

  const onPieChartClick = (): void => onClick(data);

  if (!value.bidRequest && !value.bidResponse) {
    return <EmptyPieChart onChartClick={onPieChartClick} />;
  }

  const ctPieChartColor = [theme.extend.colors.green.DEFAULT, theme.extend.colors.purple['600'].DEFAULT];

  const chartData = getChartData({ bidRequest: value.bidRequest, bidResponse: value.bidResponse });
  const chartNetValue = getNetValue({
    bidRequest: value.bidRequest,
    bidResponse: value.bidResponse,
    wonImpressions: value.wonImpressions,
    localeCode,
  });
  const chartOptions = getChartOptions();

  return (
    <div className="relative flex items-center body-sm">
      <PieChart
        colors={ctPieChartColor}
        data={chartData}
        options={chartOptions}
        enableDataLabel={false}
        enableExporting={false}
        showInLegend={false}
        onClick={onPieChartClick}
        dataTestId="BidsRenderer-pieChart"
      />
      <div className={cx('chartLegend')} data-test-id="BidsRenderer-chartLegend">
        <div className="mb-1">
          <span className={cx('legends-color')} style={{ backgroundColor: ctPieChartColor[0] }} />
          {formatNumber({ value: value.bidRequest ?? 0, localeCode })}
          &nbsp;{chartData[0].name}
        </div>
        <div>
          <span className={cx('legends-color')} style={{ backgroundColor: ctPieChartColor[1] }} />
          {formatNumber({ value: value.bidResponse ?? 0, localeCode })}
          &nbsp;{chartData[1].name}
        </div>
      </div>
      <div className={cx('chartDetail')} data-test-id="BidsRenderer-chartDetail">
        <div className="mb-1">
          {formatNumber({ value: value.wonImpressions ?? 0, localeCode })}
          &nbsp;Won
        </div>
        <div>
          {chartNetValue}
          &nbsp;Traded win rate
        </div>
      </div>
    </div>
  );
};

export default BidsRenderer;
