import { ReactElement } from 'react';
import SVG from 'react-inlinesvg';
import cx from 'classnames';
import uploadSvg from 'assets/icons/upload.svg';
import { ButtonThemes, SectionButtonTheme } from 'lib/SectionButton/SectionButton.types';

const FileButton = ({
  theme,
  isDisabled,
  maxFileUploads,
}: {
  theme: SectionButtonTheme;
  isDisabled: boolean;
  maxFileUploads?: number;
}): ReactElement => {
  return (
    <button
      data-test-id="file-upload-button"
      className={cx('flex p-2 gap-1 border rounded-md w-full disabled:bg-neutral-200 disabled:cursor-not-allowed', {
        [ButtonThemes[theme].inactive]: true,
        [ButtonThemes[theme].buttonContainer]: true,
      })}
      type="button"
      disabled={isDisabled}
    >
      <SVG
        className={cx('fill-current w-4 h-4', {
          'text-neutral-500': isDisabled,
          [ButtonThemes[theme].iconColor]: !isDisabled,
        })}
        src={uploadSvg}
      />
      <p className={cx('text-primary-600', { '!text-neutral-500': isDisabled })}>
        Upload files{maxFileUploads ? ` (${maxFileUploads} maximum)` : ''}
      </p>
    </button>
  );
};

export default FileButton;
