import { Asset } from 'components/common/types/Asset.types';
import { IJsonSheet } from 'json-as-xlsx';
import { formatNumber, formatPercent, FormatTypeEnum, formatValue } from 'modules/I18N';

export type GenerateFrameListDataProps = {
  assets: Asset[];
  isReseller: boolean;
  currencyCode: string;
  localeCode: string;
};

export const generateFrameListData = ({
  assets,
  isReseller,
  currencyCode,
  localeCode,
}: GenerateFrameListDataProps): IJsonSheet[] => {
  return [
    {
      sheet: 'Frame-List',
      columns: [
        ...(isReseller
          ? [
              {
                label: 'Media owner',
                value: 'mediaOwner',
              },
            ]
          : []),
        {
          label: 'Frame ID',
          value: 'frameId',
        },
        {
          label: 'Latitude',
          value: 'latitude',
        },
        {
          label: 'Longitude',
          value: 'longitude',
        },
        {
          label: 'Width',
          value: 'width',
        },
        {
          label: 'Height',
          value: 'height',
        },
        {
          label: 'Timezone',
          value: 'timezone',
        },
        {
          label: 'Visual unit code',
          value: 'visualUnitCode',
        },
        {
          label: 'Availability',
          value: 'availability',
        },
        {
          label: 'Street',
          value: 'street',
        },
        {
          label: 'PostCode',
          value: 'postCode',
        },
        {
          label: 'Route frame code',
          value: 'routeFrameCode',
        },
        {
          label: 'SOT',
          value: 'sot',
        },
        {
          label: 'Impressions',
          value: 'impressions',
        },
        {
          label: 'Price',
          value: 'price',
        },
        {
          label: 'CPM',
          value: 'cpm',
        },
      ],
      content:
        assets?.map(({ siteType, sot: assetSot, impressions: assetImpressions, price, cpm, ...exportData }) => ({
          ...exportData,
          sot: formatPercent({ value: assetSot / 100, localeCode }).replace(/\xA0/g, ' '),
          impressions: formatValue(assetImpressions, FormatTypeEnum.ROUNDED_NUMBER, currencyCode, localeCode),
          price: formatNumber({ value: price as number, localeCode }),
          cpm: formatNumber({ value: cpm as number, localeCode }),
        })) ?? [],
    },
  ];
};

export const frameListSettings = {
  fileName: 'frames-details',
  extraLength: 3,
  writeOptions: {},
};
