import { useState } from 'react';
import cx from 'classnames';
import SVG from 'react-inlinesvg';
import { ICellRendererParams } from 'ag-grid-community';
import minusSvg from 'assets/icons/planner_minus.svg';
import plusSvg from 'assets/icons/planner_plus.svg';
import tableGroupLine from 'assets/icons/table-group-line.svg';

const RowGroupRenderer: React.FC<ICellRendererParams> = (params) => {
  const { api, node, value } = params;
  const [isRowExpanded, setIsRowExpanded] = useState(node.expanded);

  const label = node.group ? node.key : value;
  const isNested = node.level > 0;

  const toggleExpandCollapseRow = (): void => {
    api.setRowNodeExpanded(node, !isRowExpanded);
    setIsRowExpanded(!isRowExpanded);
  };

  return (
    <div
      className={cx('flex items-center', {
        'ml-2': node.level === 1,
        'ml-11': node.level === 2,
      })}
      data-test-id="row-group-renderer"
    >
      {isNested && <SVG src={tableGroupLine} className="absolute -top-6" />}
      {node.group && (
        <button
          className={cx('bg-neutral-100 p-0.5 rounded-full', {
            'ml-6': isNested,
          })}
          onClick={toggleExpandCollapseRow}
          type="button"
        >
          <SVG className="w-4 h-4 text-secondary" src={isRowExpanded ? minusSvg : plusSvg} />
        </button>
      )}
      <div
        className={cx('truncate', {
          'sub-header-base': !node.group,
          'body-base ml-3': node.group,
          'ml-6': !node.group && node.level === 1,
          'ml-7': !node.group && node.level === 2,
        })}
      >
        {label || '-'}
      </div>
    </div>
  );
};

export default RowGroupRenderer;
