import * as lookups from 'modules/api/lookups';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { CodeNameModel } from 'components/common/types';
import AsyncSelect from 'components/patterns/AsyncSelect';
import { FilterMultiCreativeIdProps } from './FilterMultiCreativeId.types';

const FilterMultiCreativeId: React.FC<FilterMultiCreativeIdProps> = ({ changeFilters, creativeIds, isDisabled }) => {
  const cancelFunctions = useCancelRequest();

  const onLoadLookupData = async (dropdownName: string, query: string): Promise<[]> => {
    try {
      if (query.length < 2) {
        return [];
      }

      return await lookups.getCreativeIdsLookupSuggestions(cancelFunctions, query);
    } catch (error) {
      return [];
    }
  };

  return (
    <AsyncSelect
      dataTestId="creativeId-filter"
      name="creativeIds"
      loadData={onLoadLookupData}
      selectedValue={creativeIds}
      placeholder="Creative ID"
      onSelect={(_: string, value: CodeNameModel[]) =>
        changeFilters({
          creativeIds: value,
        })
      }
      multiple
      isDisabled={isDisabled}
    />
  );
};

export default FilterMultiCreativeId;
